import React, { useContext, useEffect, useState } from 'react'
import classes from './ReplicaListChildren.module.scss'
import ReplicaAdd from '../ReplicaAdd/ReplicaAdd'
import axios from 'axios'
import config from '../../../../config/config'
import { PopupContext } from '../../../../context/popup/popupContext'
import ReplicaItem from '../ReplicaItem/ReplicaItem'

export default function ReplicaListChildren(props) {
    const popupContextData = useContext(PopupContext)

    const [content, setContent] = useState({
        formAdd: false,
        replica: []
    })

    const onUpdateReplicaChildren = (replicaId, nameField, valueField) => {
        const newArr = content?.replica.map((item) => {
            if (item._id === replicaId) {
                const newItem = { ...item, [nameField]: valueField }

                return newItem
            }

            return item
        })

        setContent((prev) => ({ ...prev, replica: newArr }))
    }

    const formAddToggle = () => {
        const contentCopy = { ...content }
        contentCopy.formAdd = !contentCopy.formAdd
        setContent(contentCopy)
    }

    const getReplicaChildren = async () => {
        const contentCopy = { ...content }
        const filterData = [`type=${props.type}`]

        popupContextData.setPreloader(true)

        const token = localStorage.getItem('token')
        const response = await axios.get(
            `${config.SERVER_BASE_URL}/replica/children/${props.id}?token=${token}${
                filterData.length ? '&' + filterData.join('&') : ''
            }`
        )
        const responseData = response.data

        contentCopy.formAdd = false

        if (responseData.status === 'fail') {
            alert(responseData.errorText)
        } else {
            contentCopy.replica = responseData.data
        }

        setContent(contentCopy)
        popupContextData.setPreloader(false)
    }

    useEffect(() => {
        getReplicaChildren()

        // eslint-disable-next-line
    }, [props.replicaChildrenUpdate])

    return (
        <div className={classes.ReplicaListChildren} data-open={content.formAdd ? 'Y' : null}>
            <div className={classes.Add} onClick={formAddToggle} data-open={content.formAdd ? 'Y' : null} />

            <div className={classes.ItemWrapper} data-line={content.replica.length > 0 || content.formAdd ? 'Y' : null}>
                {content.formAdd ? (
                    content.replica.length === 0 ? (
                        <ReplicaAdd
                            dataline='Y'
                            updateReplicaList={getReplicaChildren}
                            replicaType={props.type}
                            close={formAddToggle}
                            parentId={props.id}
                            company={props.company}
                            project={props.project}
                            replicaChildrenUpdateStart={props.replicaChildrenUpdateStart}
                            children={true}
                        />
                    ) : (
                        <ReplicaAdd
                            updateReplicaList={getReplicaChildren}
                            replicaType={props.type}
                            close={formAddToggle}
                            parentId={props.id}
                            company={props.company}
                            project={props.project}
                            replicaChildrenUpdateStart={props.replicaChildrenUpdateStart}
                            children={true}
                        />
                    )
                ) : null}

                {content.replica.length ? (
                    <div className={classes.List}>
                        <div className={classes.HeaderBar}>
                            <div className={classes.BarCell}>
                                <div className={classes.AddButton} />
                            </div>

                            <div className={classes.BarCell} data-width='126'>
                                Дата
                            </div>
                            <div className={classes.BarCell} data-width='201'>
                                Площадка
                            </div>
                            <div className={classes.BarCell} data-width='782'>
                                Последняя реплика
                            </div>
                            <div className={classes.BarCell} data-width='229'>
                                Статус
                            </div>
                        </div>

                        {content.replica.map((replica, index) => {
                            const date = new Intl.DateTimeFormat('ru', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            }).format(new Date(replica.date))

                            return index === content.replica.length - 1 ? (
                                <ReplicaItem
                                    dataline='Y'
                                    key={index}
                                    index={index}
                                    date={date}
                                    id={replica._id.toString()}
                                    company={replica.company}
                                    onlyView={props.onlyView}
                                    project={replica.project}
                                    category={replica.category}
                                    platform={replica.platform}
                                    url={replica.url}
                                    msg={replica.msg.msg}
                                    comment={replica.msg.comment}
                                    status={replica.status}
                                    checked={props?.arraySelectedItems?.find((item) => item.id === props.id)}
                                    screenshot={replica.screenshot}
                                    agentName={replica.agentName}
                                    fields={replica.fields ? replica.fields : []}
                                    type={replica.type ? replica.type : false}
                                    accessLevel={props.accessLevel}
                                    replicaSelect={(event) => props.replicaSelect(event, replica._id.toString(), replica.status)}
                                    updateReplicaList={getReplicaChildren}
                                    children={true}
                                    replicaChildrenSelect={props.replicaChildrenSelect}
                                    replicaChildrenUpdate={props.replicaChildrenUpdate}
                                    replicaChildrenUpdateStart={props.replicaChildrenUpdateStart}
                                    arraySelectedItems={props.arraySelectedItems}
                                    openPopupDetail={() => props.openPopupDetail(replica)}
                                    closePopupDetail={props.closePopupDetail}
                                    isOpenDetail={props.isOpenDetail}
                                    // replicaChange={props.replicaChange}
                                    replicaChange={onUpdateReplicaChildren}
                                    noteSystem={replica.noteSystem}
                                    onChangeUrlInReplica={props.onChangeUrlInReplica}
                                />
                            ) : (
                                <ReplicaItem
                                    key={index}
                                    index={index}
                                    date={date}
                                    id={replica._id.toString()}
                                    company={replica.company}
                                    project={replica.project}
                                    category={replica.category}
                                    onlyView={props.onlyView}
                                    platform={replica.platform}
                                    url={replica.url}
                                    msg={replica.msg.msg}
                                    comment={replica.msg.comment}
                                    status={replica.status}
                                    checked={props?.arraySelectedItems?.find((item) => item.id === props.id)}
                                    screenshot={replica.screenshot}
                                    agentName={replica.agentName}
                                    fields={replica.fields ? replica.fields : []}
                                    type={replica.type ? replica.type : false}
                                    accessLevel={props.accessLevel}
                                    replicaSelect={(event) => props.replicaSelect(event, replica._id.toString(), replica.status)}
                                    updateReplicaList={getReplicaChildren}
                                    children={true}
                                    replicaChildrenSelect={props.replicaChildrenSelect}
                                    replicaChildrenUpdate={props.replicaChildrenUpdate}
                                    replicaChildrenUpdateStart={props.replicaChildrenUpdateStart}
                                    arraySelectedItems={props.arraySelectedItems}
                                    openPopupDetail={() => props.openPopupDetail(replica)}
                                    closePopupDetail={props.closePopupDetail}
                                    isOpenDetail={props.isOpenDetail}
                                    // replicaChange={props.replicaChange}
                                    replicaChange={onUpdateReplicaChildren}
                                    noteSystem={replica.noteSystem}
                                />
                            )
                        })}
                    </div>
                ) : null}
            </div>
        </div>
    )
}
