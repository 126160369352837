import React from 'react'

function IconPositive({ className }) {
    return (
        <svg className={className} width={28} height={28} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 14C0 6.26865 6.26835 0 13.9994 0C21.7317 0 28 6.26865 28 14C28 21.732 21.7317 28 13.9994 28C6.26835 28 0 21.732 0 14ZM3.04348 14C3.04348 20.051 7.94926 24.9565 13.9994 24.9565C20.0513 24.9565 24.9565 20.051 24.9565 14C24.9565 7.94865 20.0513 3.04348 13.9994 3.04348C7.94926 3.04348 3.04348 7.94865 3.04348 14Z'
                fill='#119E00'
            />
            <circle cx='9.89042' cy='10.6097' r='2.02909' fill='#119E00' />
            <circle cx='18.1092' cy='10.6097' r='2.02909' fill='#119E00' />
            <path
                d='M20.3422 16.3992C19.6972 16.0044 18.8539 16.2078 18.4592 16.8527C17.6344 18.2012 15.8838 19.0723 13.9995 19.0723C12.1153 19.0723 10.3647 18.2012 9.53994 16.8527C9.1452 16.2078 8.30215 16.0044 7.65694 16.3992C7.01172 16.7939 6.80872 17.637 7.20346 18.2825C8.53528 20.4592 11.1393 21.8114 13.9995 21.8114C16.8598 21.8114 19.4641 20.4592 20.7959 18.2822C21.1907 17.637 20.9877 16.7939 20.3422 16.3992Z'
                fill='#119E00'
            />
        </svg>
    )
}

export default IconPositive
