import React, { useContext } from 'react'
import classes from './Tabs.module.scss'
import cn from 'classnames'
import { AnaliseContext } from '../../../../context/analise'

function Tabs({ tabs }) {
    const { state, actions } = useContext(AnaliseContext)

    return (
        <div className={classes.Wrap}>
            {tabs.map((item) => (
                <button
                    className={cn(classes.Btn, { [classes.Active]: item.code === state.activeTabScreen })}
                    key={item.code}
                    onClick={() => actions.onChangeState('activeTabScreen', item.code)}
                >
                    {item.name}
                </button>
            ))}
        </div>
    )
}

export default Tabs
