import React, { useContext } from 'react'
import classes from './ModalCompany.module.scss'
import cn from 'classnames'
import MobailModal from '../../../UI/MobailModal/MobailModal'
import { AnaliseContext } from '../../../../../context/analise'
import IconCheckedBig from '../../../icons/IconCheckedBig'

function ModalCompany({ controlModalChildren }) {
    const { state, actions } = useContext(AnaliseContext)

    const optionsCompanies = state.companies?.map(({ id, name }) => {
        return { value: id, label: name }
    })

    return (
        <MobailModal title='Выбор компании' controlModal={controlModalChildren} isIcon>
            <div className={classes.Wrap}>
                {optionsCompanies?.length > 0 ? (
                    optionsCompanies?.map((item, index) => (
                        <button
                            className={cn(classes.Btn, { [classes.Active]: item.value === state.filter.company?.value })}
                            key={index}
                            onClick={() => {
                                actions.onChangeFilter('company', item)
                                controlModalChildren.onCloseModal()
                            }}
                        >
                            {item.label}
                            <IconCheckedBig />
                        </button>
                    ))
                ) : (
                    <div className={classes.Empty}>Список пуст</div>
                )}
            </div>
        </MobailModal>
    )
}

export default ModalCompany
