import React, { useContext } from 'react'
import classes from './DesctopVersion.module.scss'
import MenuDesktop from '../UI/MenuDesktop/MenuDesktop'
import { mockData } from './mockData'
import Tabs from '../UI/Tabs/Tabs'
import TabScreenSearch from './TabScreenSearch/TabScreenSearch'
import TitleRow from '../UI/TitleRow/TitleRow'
import TabScreenTonality from './TabScreenTonality/TabScreenTonality'
import TabScreenRating from './TabScreenRating/TabScreenRating'
import { AnaliseContext } from '../../../context/analise'

const DesctopVersion = () => {
    const { state } = useContext(AnaliseContext)

    return (
        <div className={classes.Wrap}>
            <div className={classes.Menu}>
                <MenuDesktop />
            </div>

            <Tabs tabs={mockData.tabs} />
            <TitleRow />

            <div className={classes.Inner}>
                {state.activeTabScreen === 'search' && <TabScreenSearch />}
                {state.activeTabScreen === 'tonality' && <TabScreenTonality />}
                {state.activeTabScreen === 'rating' && <TabScreenRating />}
            </div>
        </div>
    )
}

export default DesctopVersion
