import React from 'react'
import classes from './TextField.module.scss'
import cn from 'classnames'
import IconPencil from '../../../../icons/IconPencil'
import IconCheck from '../../../../icons/IconCheck'

function TextField({ value, onChange, className, isEdit, isShowIcon = true, type = 'text', min = 0, onSavePlan }) {
    return (
        <div className={cn(classes.Wrap, className)}>
            <input className={classes.Input} type={type} value={value} onChange={onChange} min={min} />
            {/* {isShowIcon && <div className={classes.Icon}>{!isEdit ? <IconPencil /> : <IconCheck className={classes.IconCheck} />}</div>} */}

            {isShowIcon && !isEdit && (
                <div className={classes.Icon}>
                    <IconPencil />
                </div>
            )}

            {isShowIcon && isEdit && (
                <div className={classes.Icon} onClick={onSavePlan}>
                    <IconCheck className={classes.IconCheck} />
                </div>
            )}
        </div>
    )
}

export default TextField
