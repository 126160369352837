import React from 'react'

function IconCalendar() {
    return (
        <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.825 0V1.8H2.925C1.935 1.8 1.125 2.61 1.125 3.6V16.2C1.125 17.19 1.935 18 2.925 18H15.525C16.515 18 17.325 17.19 17.325 16.2V3.6C17.325 2.61 16.515 1.8 15.525 1.8H14.625V0H12.825V1.8H5.625V0H3.825ZM2.925 6.3H15.525V16.2H2.925V6.3Z'
                fill='#505D6F'
            />
        </svg>
    )
}

export default IconCalendar
