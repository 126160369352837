import classes from './CustomTilesBlock.module.scss'
import { Tile } from '../Tile/Tile'
import React, { useEffect, useState } from 'react'
import { AddCustomTile } from './AddCustomTile/AddCustomTile'

export const CustomTilesBlock = ({onSuccessCreatedFolder, isCreatedFolder, onCancelCreatedFolder, onTapUploadFiles, onChange, tiles, deleteTile, setSize, size}) => {
    const [tileData, setTileData] = useState([])

    useEffect(() => {
        const newTilesData = []

        tiles.length > 0 && tiles.forEach((el) => {
            newTilesData.push({
                id: el._id,
                name: el.name,
                description: el.description,
                documents: el.documents,
                type: el.type,
                url: el.url,
            })
        })

        setTileData(newTilesData)
    }, [tiles])

    return (
        <div className={classes.CustomTiles}>
            {(tileData && tileData.length > 0) &&
                tileData.map((el) => (
                    <Tile
                        key={el.id}
                        data={el}
                        onTapUploadFiles={() => onTapUploadFiles(el.id)}
                        onChange={onChange}
                        type={'custom'}
                        deleteTile={deleteTile}
                        setSize={setSize}
                        size={size}
                    />
                ))
            }
            {isCreatedFolder  && <AddCustomTile multipleMedia={true} isCreatedFolder={isCreatedFolder} onCancelCreatedFolder={onCancelCreatedFolder} onSuccessCreatedFolder={onSuccessCreatedFolder}  /> }
        </div>
    )
}
