import React from 'react'

function IconNegative({ className }) {
    return (
        <svg className={className} width={28} height={28} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 14C0 6.26865 6.26848 0 13.9997 0C21.7321 0 28 6.26865 28 14C28 21.732 21.7321 28 13.9997 28C6.26848 28 0 21.732 0 14ZM3.04354 14C3.04354 20.051 7.94882 24.9565 13.9997 24.9565C20.0518 24.9565 24.9565 20.051 24.9565 14C24.9565 7.94865 20.0518 3.04348 13.9997 3.04348C7.94882 3.04348 3.04354 7.94865 3.04354 14Z'
                fill='#E00C3C'
            />
            <ellipse cx='9.89241' cy='10.6097' rx='2.02913' ry='2.02909' fill='#E00C3C' />
            <ellipse cx='18.1092' cy='10.6097' rx='2.02913' ry='2.02909' fill='#E00C3C' />
            <path
                d='M14.0002 14.9805C11.1396 14.9805 8.53523 16.3327 7.20338 18.5097C6.80863 19.1549 7.01194 19.9983 7.65717 20.393C8.30271 20.7877 9.14546 20.5844 9.54021 19.9395C10.3653 18.5909 12.1157 17.7199 14.0002 17.7199C15.8842 17.7199 17.6345 18.5909 18.4596 19.9395C18.7177 20.3616 19.1679 20.5945 19.6293 20.5945C19.8727 20.5945 20.1193 20.5293 20.3427 20.393C20.9879 19.9983 21.1912 19.1552 20.7965 18.5097C19.4646 16.3327 16.8602 14.9805 14.0002 14.9805Z'
                fill='#E00C3C'
            />
        </svg>
    )
}

export default IconNegative
