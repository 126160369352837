import React from "react"
import classes from "./TopReplicSelector.module.scss"
import ButtonSelector from "../../../UI/ButtonSelector/ButtonSelector"
import ConfirmationAction from "./ConfirmationAction/ConfirmationAction"

export default function TopReplicSelector(props) {
    let showButtonReject = false
    let showButtonApprove = false
    let showButtonDelete = false

    let statusCount = 0
    let statusDetail = {}

    function declOfNum(number, words) {
        return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]];
    }

    if (props.arraySelectedItems && props.arraySelectedItems.length) {
        for (const item of props.arraySelectedItems) {
            statusDetail[item.status.code] = item.status.code
        }
    }

    if (statusDetail) {
        statusCount = Object.keys(statusDetail).length

        if (statusCount === 1) {
            if (props.accessLevel === 1) {
                showButtonDelete = true
            }

            if (statusDetail["waiting-agreement"]) {
                showButtonReject = true
                showButtonApprove = true
            }
        }
    }

    return (
        <div className={classes.TopReplicSelector} data-delete={showButtonDelete && !showButtonApprove && !showButtonReject}>
            {props.rejectValue && showButtonReject ? (
                <ConfirmationAction
                    text="Укажите причину отклонения!"
                    subtext="Без указания причины выбранные реплики не могут быть отклонены!"
                    dataSmallTextArea="S"
                    dataColorTextArea="lightred"
                    buttonText="Подтвердить отклонение"
                    buttonColor="red"
                    textFinish={declOfNum(props.num, ['выбранная реплика отклонена', 'выбранных реплики отклонены', 'выбранных реплик отклонено'])}
                    num={props.num}
                    clickFinish={props.clickRejectFinal}
                    confirm={props.confirm}
                    onChangeMsg={(event) => props.onChangeMsg(event.target.value, 'reject')}
                    msg={props.msgReject}
                />
            ) : null}

            {props.approveValue && showButtonApprove ? (
                <ConfirmationAction
                    text="Комментарий:"
                    dataSmallTextArea="S"
                    dataColorTextArea="lightgreen"
                    buttonText="Подтвердить согласование"
                    buttonColor="green"
                    textFinish={declOfNum(props.num, ['выбранная реплика одобрена', 'выбранных реплики одобрены', 'выбранных реплик одобрено'])}
                    num={props.num}
                    clickFinish={props.clickApproveFinal}
                    confirm={props.confirm}
                    onChangeMsg={(event) => props.onChangeMsg(event.target.value, 'approve')}
                    msg={props.msgApprove}
                />
            ) : null}


            <div className={classes.Text}>
                <div className={classes.Num}>{props.num}</div>
                {/* {declOfNum(props.num, ['реплика выбрана', 'реплики выбраны', 'реплик выбрано'])} */}
                {declOfNum(props.num, ['выбрана', 'выбраны', 'выбрано'])}
            </div>

            <div className={classes.BtnBlockWrapper}>
                <div className={`${classes.BtnWrapper} ${classes.BtnWrapperMobile}`}>
                    <ButtonSelector
                        text="Отменить выбор"
                        color="blue"
                        click={() => {
                            props.setArraySelectedItems([])
                            props.setIsAllSelected(false)
                        }}
                    />
                </div>

                {showButtonReject ? (
                    <div className={`${classes.BtnWrapper} ${classes.BtnWrapperMobile1}`}>
                        <ButtonSelector
                            text="Отклонить"
                            color="red"
                            click={props.clickReject}
                            dataDisabled={props.btnDisabled.rejectActive}
                        />
                    </div>
                ) : null}

                {showButtonApprove ? (
                    <div className={`${classes.BtnWrapper} ${classes.BtnWrapperMobile2}`}>
                        <ButtonSelector
                            text="Одобрить"
                            color="green"
                            click={props.clickApprove}
                            dataDisabled={props.btnDisabled.approveActive}
                        />
                    </div>
                ) : null}

                <div className={`${classes.BtnWrapper} ${classes.BtnWrapperMobile3}`}>
                    <ButtonSelector
                        text="Удалить"
                        color="violet"
                        click={props.clickDelete}
                        dataDisabled={props.btnDisabled.deleteActive}
                    />
                </div>
            </div>
        </div>
    )
}
