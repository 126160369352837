import React from 'react'
import { useRef, useState } from 'react'
import classes from './SelectCustom.module.scss'
import cn from 'classnames'
import anime from 'animejs'
import IconSelect from './IconSelect'
import IconSelectMobail from './IconSelectMobail'

const options = [
    {
        label: '1',
        value: '1'
    },
    {
        label: '5',
        value: '5'
    },
    {
        label: '10',
        value: '10'
    },
    {
        label: 'все',
        value: 'all'
    }
]

function SelectCustom({ activeValue, placeholder, onChange, className }) {
    const [activeObj, setActiveObj] = useState(activeValue || options[0])
    const [isOpen, setIsOpen] = useState(false)

    const optionsForRender = options.filter((item) => item.label !== activeObj.label)

    const dropdownRef = useRef(null)

    const onOpenDropdown = (e) => {
        e.stopPropagation()

        if (isOpen) {
            dropdownRef.current.style.pointerEvents = 'none'

            anime({
                targets: dropdownRef.current,
                duration: 800,
                opacity: [1, 0]
            })
        } else {
            dropdownRef.current.style.pointerEvents = 'visible'

            anime({
                targets: dropdownRef.current,
                duration: 800,
                opacity: [0, 1]
            })
        }

        setIsOpen((prev) => !prev)
    }

    const onChangeActiveObj = (e, obj) => {
        setActiveObj(obj)
        onChange && onChange(obj)
        onOpenDropdown(e)
    }

    if (!options.length) {
        return null
    }

    return (
        <div className={cn(classes.Wrapper, className)}>
            {(activeValue || placeholder) && (
                <button className={cn(classes.Btn, { [classes.Open]: isOpen === true })} onClick={onOpenDropdown}>
                    <div className={classes.BtnText}>
                        <IconSelectMobail className={classes.IconSelectMobail} />
                        <span className={classes.TitleMobail}>Кол-во последних периодов:</span>
                        {activeValue?.label || activeObj?.label || placeholder}
                    </div>

                    <IconSelect className={classes.IconSelect} />
                </button>
            )}

            <div className={classes.Dropdown} ref={dropdownRef}>
                <ul className={classes.DropdownList}>
                    {optionsForRender.map((item) => (
                        <li className={classes.DropdownItem} key={item.value}>
                            <button className={classes.DropdownBrn} onClick={(e) => onChangeActiveObj(e, item)}>
                                {item.label}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default SelectCustom
