import React, { useContext } from 'react'
import './Layout.scss'
import LeftSideBar from '../../components/LeftSideBar/LeftSideBar'
import ButtonEscape from '../../components/UI/ButtonEscape/ButtonEscape'
import { AuthContext } from '../../context/auth/authContext'
import Footer from '../../components/Footer/Footer'

const Layout = ({ children }) => {
    const authContextData = useContext(AuthContext)

    return (
        <>
            <main id='app'>
                <div className='main-container'>
                    <div className='main-wrap'>
                        {authContextData.auth ? <ButtonEscape /> : null}

                        {children}
                    </div>

                    {authContextData.auth ? <LeftSideBar /> : null}
                </div>
            </main>

            {authContextData.auth ? <Footer /> : null}
        </>
    )
}

export default Layout
