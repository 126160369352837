import classes from './Select.module.scss'
import cn from 'classnames'
import React from 'react'
import SelectStyle from '../../../UI/SelectStyle/SelectStyle'

const initialItem = {
    name: 'test',
    id: 'test'
}

export const Select = ({className, items, value, optionDefault, onChange}) => {
    return (
        <div className={cn(classes.Select, className)}>
            <SelectStyle
                value={value}
                optionDefault={optionDefault}
                options={items || []}
                onChange={onChange}
            />
        </div>
    )
}