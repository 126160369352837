import React, {useContext, useEffect} from "react"
import {Redirect} from "react-router-dom";
import {AuthContext} from "../../context/auth/authContext";

const Logout = () => {
    const authContextData = useContext(AuthContext)

    useEffect(() => {
        localStorage.clear()
        authContextData.setAuth(false)
    })

    return <Redirect to={'/'} />
}

export default Logout