import React from "react"
import "./Filter.scss"
import SelectStyle from "../../../../UI/SelectStyle/SelectStyle"

const Filter = (props) => {
    return (
        <div className="select-pages__select-block">
            <div className="select-pages__select-unit select-pages__select-unit_width-219px select-pages__select-unit_small-arrow">
                <SelectStyle
                    name={props.filter.status.name}
                    value={props.filter.status.value}
                    options={props.filter.status.options}
                    optionDefault={props.filter.status.optionDefault}
                    onChange={(event) => props.filterInputChange(event.target.value, props.filter.status.name)}
                    location={'adminUsers'}
                />
            </div>

            {/*<div className="select-pages__select-unit select-pages__select-unit_width-306px select-pages__select-unit_big-arrow">*/}
            {/*    <SelectStyle*/}
            {/*        name={inputs["role"].name}*/}
            {/*        value={inputs["role"].value}*/}
            {/*        options={inputs["role"].options}*/}
            {/*        optionDefault={inputs["role"].optionDefault}*/}
            {/*        disabled={inputs["role"].disabled}*/}
            {/*        onChange={(event) => selectChange(event, inputs["role"].name)}*/}
            {/*        location={'adminUsers'}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    )
}

export default Filter
