import React, { useContext, useState } from 'react'
import classes from './ScreenSearch.module.scss'
import cn from 'classnames'
import UrlDropDown from '../../UI/UrlDropDown/UrlDropDown'
import IconDots from '../../icons/IconDots'
import IconArrowDown from '../../icons/IconArrowDown'
import IconLink from '../../icons/IconLink'
import IconNeutral from '../../icons/IconNeutral'
import IconDefault from '../../icons/IconDefault'
import IconPositive from '../../icons/IconPositive'
import IconNegative from '../../icons/IconNegative'
import IconPending from '../../icons/IconPending'
import { AnaliseContext } from '../../../../context/analise'

function ScreenSearch() {
    const { state, actions } = useContext(AnaliseContext)

    const [selectedRow, setSelectedRow] = useState(null)
    const [typeOpenElem, setTypeOpenElem] = useState(null) // 'url' | 'changeType' | 'categories'

    const onChangeSelectedRow = (idRow, type) => {
        if (idRow === selectedRow) {
            setSelectedRow(null)
            setTypeOpenElem(null)
            return
        }

        setSelectedRow(idRow)
        setTypeOpenElem(type)
    }

    const halperChangeTonality = (id, value) => {
        actions.onChangeTonality(id, value)
        setSelectedRow(null)
        setTypeOpenElem(null)
    }

    const halperChangeCategory = (id, value) => {
        actions.onChangeCanegory(id, value)
        setSelectedRow(null)
        setTypeOpenElem(null)
    }

    return (
        <section className={classes.Section}>
            <div className={classes.List}>
                {state.contentScreenSearch.length > 0 ? (
                    state.contentScreenSearch.map((item, index) => (
                        <div className={classes.Item} key={index}>
                            <div className={classes.ItemHeader}>
                                <div className={classes.ItemNumber}>1</div>

                                <div className={classes.ItemHeaderContent}>
                                    <div className={classes.ItemChange}>
                                        <IconArrowDown />
                                        {item?.change}
                                    </div>

                                    <div className={classes.ItemUrl} onClick={() => onChangeSelectedRow(item.id, 'url')}>
                                        <div className={classes.ItemUrlInner}>
                                            <IconLink />
                                            {item?.url}
                                        </div>
                                    </div>
                                </div>

                                <UrlDropDown
                                    isOpen={item.id === selectedRow && typeOpenElem === 'url'}
                                    href={item.urlFull}
                                    onClose={() => setSelectedRow(null)}
                                />
                            </div>

                            <div className={classes.Row}>
                                <button className={classes.BtnChangeType} onClick={() => onChangeSelectedRow(item.id, 'changeType')}>
                                    <IconDots className={classes.IconDots} />

                                    {!item.tonality && <IconDefault />}
                                    {item.tonality === 'positive' && <IconPositive />}
                                    {item.tonality === 'negative' && <IconNegative />}
                                    {item.tonality === 'neutral' && <IconNeutral />}
                                    {item.tonality === 'pending' && <IconPending />}
                                </button>

                                <div className={classes.ItemCategory}>
                                    <p className={classes.ItemCategoryValue}>{item.category.name || 'Категория не определена'}</p>

                                    <button
                                        className={classes.BtnOpenCategories}
                                        onClick={() => onChangeSelectedRow(item.id, 'categories')}
                                    >
                                        <IconDots />
                                    </button>
                                </div>

                                <div
                                    className={cn(classes.DropdownChangeType, {
                                        [classes.Active]: item.id === selectedRow && typeOpenElem === 'changeType'
                                    })}
                                >
                                    <p>Тональность:</p>

                                    <div className={classes.VariantsChangeType}>
                                        <button onClick={() => halperChangeTonality(item.id, 'positive')}>
                                            <IconPositive />
                                        </button>

                                        <button onClick={() => halperChangeTonality(item.id, 'negative')}>
                                            <IconNegative />
                                        </button>

                                        <button onClick={() => halperChangeTonality(item.id, 'neutral')}>
                                            <IconNeutral />
                                        </button>

                                        <button onClick={() => halperChangeTonality(item.id, 'pending')}>
                                            <IconPending />
                                        </button>
                                    </div>
                                </div>

                                <div
                                    className={cn(classes.DropdownCategories, {
                                        [classes.Active]: item.id === selectedRow && typeOpenElem === 'categories'
                                    })}
                                >
                                    <p>Тип площадки:</p>

                                    <div className={classes.VariantsCategories}>
                                        {state.categories?.map((category, indexCategory) => (
                                            <button key={indexCategory} onClick={() => halperChangeCategory(item.id, category.value)}>
                                                {category.label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className={classes.ItemContent}>
                                <p className={classes.ItemTitle}>{item.info.title}</p>

                                <p className={classes.ItemText}>{item.info.text}</p>

                                <div className={classes.ItemRating}>
                                    Рейтинг:
                                    <span>{item?.info?.rating}</span>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <>
                        {state.filter.query ? (
                            <div className={classes.Empty}>По данному фильтру ничего не найдено</div>
                        ) : (
                            <div className={classes.Empty}>Выберите поисковый запрос для просмотра результатов поисковой выдачи</div>
                        )}
                    </>
                )}
            </div>
        </section>
    )
}

export default ScreenSearch
