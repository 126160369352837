import React, { useEffect, useMemo, useState } from 'react'

import './Filter.scss'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import Input from '../../../UI/Input/Input'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import flatpickr from 'flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.min.css'
import SelectWithCounter from '../../../UI/SelectWithCounter/SelectWithCounter'
import Checkbox from '../../../UI/Checkbox/Checkbox'

SwiperCore.use([Navigation])

const Filter = (props) => {
    const isCheckbox = !props.filter.dateFrom.value || !props.filter.dateTo.value

    useEffect(() => {
        flatpickr('.js-flatpickr', {
            dateFormat: 'd.m.Y',
            locale: Russian,
            lang: 'ru',
            onDayCreate: [
                function(dObj, dStr, fp, dayElem) {
                    var date = dayElem.dateObj
                    if (date.getDay() === 0 || date.getDay() === 6) {
                        dayElem.className += ' weekday'
                    }
                }
            ],
            onChange: (selectedDates, dateStr, instance) => {
                props.filterSelectChange(instance.element.value, instance.element.getAttribute('name'))
            }
        })
        // eslint-disable-next-line
    }, [])


    const categoryOptions = props.filter.category.options;
    const platformOptions = props.filter.platform.options;
    const statusOptions = props.filter.status.options
    const company =  props.filter.company.options;
    const projectOptions = props.filter.project.options;



    return (
        <div className='replic__filter'>
            {window.innerWidth > 767 && window.innerWidth < 1025 ? (
                <div className={'filter-slider'}>
                    <div className={'arrows'}>
                        <div className={'swiper-button-prev'} />
                        <div className={'swiper-button-next'} />
                    </div>

                    <Swiper
                        spaceBetween={37}
                        slidesPerView={2}
                        navigation={{
                            nextEl: '.filter-slider .arrows .swiper-button-next',
                            prevEl: '.filter-slider .arrows .swiper-button-prev'
                        }}
                    >
                        {props.accessLevel !== 2 ? (
                            <SwiperSlide className='swiperFilter'>
                                <div className='replic__filter-unit big'>
                                    <SelectWithCounter
                                        name={props.filter.company.name}
                                        value={props.filter.company.value}
                                        options={company}
                                        optionDefault={props.filter.company.optionDefault}
                                        disabled={props.filter.company.disabled}
                                        onChange={(event) =>
                                            props.filterSelectChange(event.target.value, props.filter.company.name)
                                        }
                                    />
                                </div>
                            </SwiperSlide>
                        ) : null}

                        <SwiperSlide className='swiperFilter'>
                            <div className='replic__filter-unit middle'>
                                <SelectWithCounter
                                    name={props.filter.project.name}
                                    value={props.filter.project.value}
                                    options={projectOptions}
                                    optionDefault={props.filter.project.optionDefault}
                                    disabled={props.filter.project.disabled}
                                    onChange={(event) => props.filterSelectChange(event.target.value, props.filter.project.name)}
                                />
                            </div>
                        </SwiperSlide>

                        {props.replicaType !== 1 ? (
                            <SwiperSlide className='swiperFilter'>
                                <div className='replic__filter-unit small'>
                                    <SelectWithCounter
                                        name={props.filter.category.name}
                                        value={props.filter.category.value}
                                        options={categoryOptions}
                                        optionDefault={props.filter.category.optionDefault}
                                        disabled={props.filter.category.disabled}
                                        onChange={(event) =>
                                            props.filterSelectChange(event.target.value, props.filter.category.name)
                                        }
                                    />
                                </div>
                            </SwiperSlide>
                        ) : null}

                        <SwiperSlide className='swiperFilter'>
                            <div className='replic__filter-unit little'>
                                <SelectWithCounter
                                    name={props.filter.platform.name}
                                    value={props.filter.platform.value}
                                    options={platformOptions}
                                    optionDefault={props.filter.platform.optionDefault}
                                    disabled={props.filter.platform.disabled}
                                    onChange={(event) => props.filterSelectChange(event.target.value, props.filter.platform.name)}
                                />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='swiperFilter'>
                            <div className='replic__filter-unit big  date'>

                                <div className='replic__filter-unit-input-container'>

                                    <Input
                                        type={props.filter.dateFrom.type}
                                        name={props.filter.dateFrom.name}
                                        value={props.filter.dateFrom.value}
                                        placeholder={props.filter.dateFrom.placeholder}
                                        onChange={(event) =>
                                            props.filterSelectChange(event.target.value, props.filter.dateFrom.name)
                                        }
                                        className='js-flatpickr'
                                    />
                                </div>

                                <span className='replic__filter-unit-separator' />

                                <div className='replic__filter-unit-input-container'>
                                    <Input
                                        type={props.filter.dateTo.type}
                                        name={props.filter.dateTo.name}
                                        value={props.filter.dateTo.value}
                                        placeholder={props.filter.dateTo.placeholder}
                                        onChange={(event) =>
                                            props.filterSelectChange(event.target.value, props.filter.dateTo.name)
                                        }
                                        className='js-flatpickr'
                                    />
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='swiperFilter'>
                            <div className='replic__filter-unit little'>
                                <SelectWithCounter
                                    name={props.filter.status.name}
                                    value={props.filter.status.value}
                                    options={statusOptions}
                                    optionDefault={props.filter.status.optionDefault}
                                    disabled={props.filter.status.disabled}
                                    onChange={(event) => props.filterSelectChange(event.target.value, props.filter.status.name)}
                                />
                            </div>
                        </SwiperSlide>
                    </Swiper>

                    <div className='FilterByDate  tablet' data-disabled={isCheckbox ? 'Y' : 'N'}>
                        <Checkbox
                            id={`checkbox-filter-by-date`}
                            name={props.filter.filterByDateChangeStatus.name}
                            checked={props.filter.filterByDateChangeStatus.value}
                            // disabled={!props.filter.dateFrom.value && !props.filter.dateTo.value}
                            onChange={(event) =>
                                props.filterSelectChange(event.target.checked, props.filter.filterByDateChangeStatus.name)
                            }
                        />
                        Фильтровать по дате изменения статуса
                    </div>
                </div>
            ) : (
                <>
                    {props.accessLevel !== 2 ? (
                        <div className='replic__filter-unit big'>
                            <SelectWithCounter
                                name={props.filter.company.name}
                                value={props.filter.company.value}
                                options={company}
                                optionDefault={props.filter.company.optionDefault}
                                disabled={props.filter.company.disabled}
                                onChange={(event) => props.filterSelectChange(event.target.value, props.filter.company.name)}
                            />
                        </div>
                    ) : null}

                    <div className='replic__filter-unit middle'>
                        <SelectWithCounter
                            name={props.filter.project.name}
                            value={props.filter.project.value}
                            options={projectOptions}
                            optionDefault={props.filter.project.optionDefault}
                            disabled={props.filter.project.disabled}
                            onChange={(event) => props.filterSelectChange(event.target.value, props.filter.project.name)}
                        />
                    </div>

                    {props.replicaType !== 1 ? (
                        <div className='replic__filter-unit small'>
                            <SelectWithCounter
                                name={props.filter.category.name}
                                value={props.filter.category.value}
                                options={categoryOptions}
                                optionDefault={props.filter.category.optionDefault}
                                disabled={props.filter.category.disabled}
                                onChange={(event) => props.filterSelectChange(event.target.value, props.filter.category.name)}
                            />
                        </div>
                    ) : null}

                    <div className='replic__filter-unit little'>
                        <SelectWithCounter
                            name={props.filter.platform.name}
                            value={props.filter.platform.value}
                            options={platformOptions}
                            optionDefault={props.filter.platform.optionDefault}
                            disabled={props.filter.platform.disabled}
                            onChange={(event) => props.filterSelectChange(event.target.value, props.filter.platform.name)}
                        />
                    </div>

                    <div className='replic__filter-unit big  date'>

                        <div className='replic__filter-unit-input-container'>
                            <Input
                                type={props.filter.dateFrom.type}
                                name={props.filter.dateFrom.name}
                                value={props.filter.dateFrom.value}
                                placeholder={props.filter.dateFrom.placeholder}
                                onChange={(event) => {
                                    props.filterSelectChange(event.target.value, props.filter.dateFrom.name)
                                }}
                                className='js-flatpickr'
                            />
                        </div>

                        <span className='replic__filter-unit-separator' />

                        <div className='replic__filter-unit-input-container'>
                            <Input
                                type={props.filter.dateTo.type}
                                name={props.filter.dateTo.name}
                                value={props.filter.dateTo.value}
                                placeholder={props.filter.dateTo.placeholder}
                                onChange={(event) => {
                                    props.filterSelectChange(event.target.value, props.filter.dateTo.name)
                                }}
                                className='js-flatpickr'
                            />
                            {props.isShowResetDate ? null : <div className='icon_date_for_input'></div>}
                        </div>


                        <div className='FilterByDate' data-disabled={isCheckbox ? 'Y' : 'N'}>
                            <Checkbox
                                id={`checkbox-filter-by-date`}
                                name={props.filter.filterByDateChangeStatus.name}
                                checked={props.filter.filterByDateChangeStatus.value}
                                onChange={(event) =>
                                    props.filterSelectChange(event.target.checked, props.filter.filterByDateChangeStatus.name)
                                }
                            />
                            Фильтровать по дате изменения статуса
                        </div>
                        {props.isShowResetDate ? <span className='replic__filter-unit-reset-date'
                                                       onClick={() => props.onResetDate(props.filter.dateFrom.name, props.filter.dateTo.name)} /> : null}

                    </div>

                    <div className='replic__filter-unit little'>
                        <SelectWithCounter
                            name={props.filter.status.name}
                            value={props.filter.status.value}
                            options={statusOptions}
                            optionDefault={props.filter.status.optionDefault}
                            disabled={props.filter.status.disabled}
                            onChange={(event) => props.filterSelectChange(event.target.value, props.filter.status.name)}
                        />
                    </div>
                </>
            )}

            {props.buttonDefault ? (
                <div onClick={props.filterDefaultAction} className='replic__filter-dafault' title='Сбросить фильтр'>
                    {window.innerWidth < 768 ? <span>Сбросить фильтры</span> : null}
                </div>
            ) : null}
        </div>
    )
}

export default Filter
