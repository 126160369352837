import React, { useContext, useEffect, useState } from 'react'
import './AdminClient.scss'
import Helmet from 'react-helmet'
import Search from '../../components/Replica/Admin/Users/Search/Search'
import NavTabs from '../../components/Replica/Admin/Users/NavTabs/NavTabs'
import Filter from '../../components/Replica/Admin/Users/Filter/Filter'
import { apiCompanyList } from '../../api/companies'
import Loading from '../../components/UI/Loading/Loading'
import CompanyList from '../../components/Replica/Admin/Users/CompanyList/CompanyList'
import { PopupContext } from '../../context/popup/popupContext'

const AdminClient = () => {
    const popupContextData = useContext(PopupContext)
    const [loading, setLoading] = useState(true)
    const [companies, setCompanies] = useState()

    const [filter, setFilter] = useState({
        search: {
            type: 'text',
            name: 'search',
            value: '',
            placeholder: 'Введите запрос и нажмите Enter',
            label: 'Поиск'
        },
        status: {
            name: 'status',
            value: '',
            options: [
                { id: 'Y', name: 'Активен' },
                { id: 'N', name: 'Не активен' }
            ],
            optionDefault: 'Статус'
        }
    })

    const getCompanies = async () => {
        popupContextData.setPreloader(true)
        const companies = await apiCompanyList(filter)

        if (companies.status === 'success') {
            setCompanies(companies.data)
        } else {
            setCompanies()
        }

        popupContextData.setPreloader(false)
        setLoading(false)
    }

    const filterSelectChange = async (value, name) => {
        const filterCopy = { ...filter }
        filterCopy[name].value = value
        setFilter(filterCopy)

        await getCompanies()
    }

    const onToggleCompany = (id) => {
        const companiesCopy = { ...companies }
        companiesCopy[id].openCompany = !companiesCopy[id].openCompany
        setCompanies(companiesCopy)
    }

    useEffect(() => {
        getCompanies()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Helmet>
                <title>Клиенты</title>
            </Helmet>

            <div className='admin-company'>
                <div className='admin-company__wrap'>
                    <div className='admin-company__h1-mobile admin-company__h1-mobile_users-mode'>Пользователи</div>

                    <Search search={filter.search} filterInputChange={(value, name) => filterSelectChange(value, name)} />

                    <div className='select-pages select-pages_users-mode'>
                        <NavTabs active='client' />

                        <Filter filter={filter} filterInputChange={(value, name) => filterSelectChange(value, name)} />
                    </div>

                    {loading ? <Loading /> : null}

                    {!loading && companies !== undefined ? (
                        <CompanyList
                            onToggleCompany={(companyId) => onToggleCompany(companyId)}
                            companies={companies}
                            filter={filter}
                        />
                    ) : null}

                    {/*<ShowMore />*/}
                </div>
            </div>
        </>
    )
}

export default AdminClient
