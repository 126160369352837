import React, { useState, useEffect } from 'react'
import classes from './DesktopPlan.module.scss'
import cn from 'classnames'
import { useDesktopPlan } from './useDesktopPlan'
import AddRow from '../UI/AddRow/AddRow'
import IconAccordionOpen from '../DesktopPlanStart/IconAccordionOpen'
import IconShevronBottom from '../DesktopPlanStart/IconShevronBottom'
import AccordionDropdown from './AccordionDropdown/AccordionDropdown'
import { useDesktopPlanStart } from '../DesktopPlanStart/useDesktopPlanStart'
import AccordionDropdownCreate from './AccordionDropdownCreate/AccordionDropdownCreate'

function DesktopPlan({ title, plan, isControl = false, onSavePlan }) {
    const [activeQuantity, setActiveQuantity] = useState({
        planNull: { label: 'все', value: 'all' },
        planOne: { label: 'все', value: 'all' },
        planTwo: { label: 'все', value: 'all' }
    })

    const onSaveHandler = () => {
        const arrData = [
            {
                type: null,
                data: items?.length ? items : itemsCreate
            },
            {
                type: 1,
                data: itemsOne?.length ? itemsOne : itemsOneCreate
            },
            {
                type: 2,
                data: itemsTwo?.length ? itemsTwo : itemsTwoCreate
            }
        ]

        onSavePlan(arrData)

        onChangeIsEdit()
        onChangeIsEditOne()
        onChangeIsEditTwo()
    }

    const handlerSaveForDeleteInnerRow = (typePlan, newDataPlan) => {
        const arrData = [
            {
                type: null,
                data: typePlan && newDataPlan && typePlan !== 'planNull' ? items : newDataPlan
            },
            {
                type: 1,
                data: typePlan && newDataPlan && typePlan !== 'planOne' ? itemsOne : newDataPlan
            },
            {
                type: 2,
                data: typePlan && newDataPlan && typePlan !== 'planTwo' ? itemsTwo : newDataPlan
            }
        ]

        onSavePlan(arrData)

        onChangeIsEdit()
        onChangeIsEditOne()
        onChangeIsEditTwo()
    }

    // данные для вывода плана с типом null
    const {
        items,
        onAddRow,
        onDeleteRow,
        onAddInnerRow,
        onDeleteInnerRow,
        onChangeFieldsInnerRow,
        onChangeFieldsRow,
        onChangeIsEdit,
        prevDateEnd,
        isDisabledAddRow,
        onCopyRow
    } = useDesktopPlan(plan?.planNull, onSaveHandler, handlerSaveForDeleteInnerRow)

    // данные для вывода плана с типом 1
    const {
        items: itemsOne,
        onAddRow: onAddRowOne,
        onDeleteRow: onDeleteRowOne,
        onAddInnerRow: onAddInnerRowOne,
        onDeleteInnerRow: onDeleteInnerRowOne,
        onChangeFieldsInnerRow: onChangeFieldsInnerRowOne,
        onChangeFieldsRow: onChangeFieldsRowOne,
        onChangeIsEdit: onChangeIsEditOne,
        prevDateEnd: prevDateEndOne,
        isDisabledAddRow: isDisabledAddRowOne
    } = useDesktopPlan(plan.planOne, onSaveHandler, handlerSaveForDeleteInnerRow)

    // данные для вывода плана с типом 2
    const {
        items: itemsTwo,
        onAddRow: onAddRowTwo,
        onDeleteRow: onDeleteRowTwo,
        onAddInnerRow: onAddInnerRowTwo,
        onDeleteInnerRow: onDeleteInnerRowTwo,
        onChangeFieldsInnerRow: onChangeFieldsInnerRowTwo,
        onChangeFieldsRow: onChangeFieldsRowTwo,
        onChangeIsEdit: onChangeIsEditTwo,
        prevDateEnd: prevDateEndTwo,
        isDisabledAddRow: isDisabledAddRowTwo
    } = useDesktopPlan(plan.planTwo, onSaveHandler, handlerSaveForDeleteInnerRow)

    // данные для создания плана с типом null
    const {
        items: itemsCreate,
        onAddRow: onAddRowCreate,
        onDeleteRow: onDeleteRowCreate,
        onAddInnerRow: onAddInnerRowCreate,
        onDeleteInnerRow: onDeleteInnerRowCreate,
        onChangeFieldsRow: onChangeFieldsRowCreate,
        onChangeFieldsInnerRow: onChangeFieldsInnerRowCreate,
        prevDateEnd: prevDateEndCreate,
        isDisabledAddRow: isDisabledAddRowCreate
    } = useDesktopPlanStart()

    // данные для создания плана с типом 1
    const {
        items: itemsOneCreate,
        onAddRow: onAddRowOneCreate,
        onDeleteRow: onDeleteRowOneCreate,
        onAddInnerRow: onAddInnerRowOneCreate,
        onDeleteInnerRow: onDeleteInnerRowOneCreate,
        onChangeFieldsRow: onChangeFieldsRowOneCreate,
        onChangeFieldsInnerRow: onChangeFieldsInnerRowOneCreate,
        prevDateEnd: prevDateEndOneCreate,
        isDisabledAddRow: isDisabledAddRowOneCreate
    } = useDesktopPlanStart()

    // данные для создания плана с типом 2
    const {
        items: itemsTwoCreate,
        onAddRow: onAddRowTwoCreate,
        onDeleteRow: onDeleteRowTwoCreate,
        onAddInnerRow: onAddInnerRowTwoCreate,
        onDeleteInnerRow: onDeleteInnerRowTwoCreate,
        onChangeFieldsRow: onChangeFieldsRowTwoCreate,
        onChangeFieldsInnerRow: onChangeFieldsInnerRowTwoCreate,
        prevDateEnd: prevDateEndTwoCreate,
        isDisabledAddRow: isDisabledAddRowTwoCreate
    } = useDesktopPlanStart()

    const [isOpenDropdown, setIsOpenDropdown] = useState({
        planNull: false,
        planOne: false,
        planTwo: false
    })

    const onChangeIsOpenDropdown = (namePlan) => {
        setIsOpenDropdown((prev) => ({ ...prev, [namePlan]: !prev[namePlan] }))
    }

    const onIsShowCreateItem = (arr) => {
        if (!arr.length) {
            return false
        }

        if (arr?.length > 1) {
            return true
        }

        if (arr?.length === 1) {
            if (!arr[0]?.dateStart && !arr[0]?.dateEnd) {
                return false
            }

            return true
        }
    }

    const onIsShowAccordeonItem = (items, itemsCreate, plan) => {
        if (!isControl) {
            return (!!items?.length || onIsShowCreateItem(itemsCreate)) && plan
        } else {
            return items?.length || itemsCreate?.length
        }
    }

    const onSaveActiveQuantity = (typePlan, newObj) => {
        const copy = { ...activeQuantity, [typePlan]: newObj }
        setActiveQuantity(copy)
        localStorage.setItem(`quantity-period-plan`, JSON.stringify(copy))
    }

    const onVisibilityQuantityPeriods = (arr, typePlan) => {
        if (isControl) {
            return arr
        } else {
            const quantity = activeQuantity[typePlan].value

            if (quantity === 'all') {
                return arr
            } else {
                return arr.slice(0, quantity)
            }
        }
    }

    useEffect(() => {
        const activeState = localStorage.getItem('quantity-period-plan')

        if (activeState) {
            setActiveQuantity(JSON.parse(activeState))
        }
    }, [])

    useEffect(() => {
        const newObj = {
            planNull: !!items?.length,
            planOne: !!itemsOne?.length,
            planTwo: !!itemsTwo?.length
        }

        setIsOpenDropdown(newObj)
    }, [items, itemsOne, itemsTwo])

    return (
        <div className={cn(classes.Wrap, { [classes.Controller]: isControl })}>
            {/* {title && <h2 className={classes.Title}>{title}</h2>} */}

            <div className={classes.Accordion}>
                {onIsShowAccordeonItem(items, itemsCreate, plan?.planNull) && (
                    <div className={cn(classes.AccordionItem, { [classes.Open]: isOpenDropdown.planNull })}>
                        <div className={classes.AccordionItemHeader} onClick={() => onChangeIsOpenDropdown('planNull')}>
                            <div className={classes.AccordionItemTitle}>План — одиночные реплики</div>

                            {isControl ? (
                                !items.length && !isOpenDropdown.planNull ? (
                                    <IconAccordionOpen />
                                ) : (
                                    <IconShevronBottom className={classes.IconShevronBottom} />
                                )
                            ) : null}

                            {!isControl && <IconShevronBottom className={classes.IconShevronBottom} />}
                        </div>

                        {items.length ? (
                            <AccordionDropdown
                                items={onVisibilityQuantityPeriods(items, 'planNull')}
                                isControl={isControl}
                                onAddRow={onAddRow}
                                onDeleteRow={onDeleteRow}
                                onAddInnerRow={onAddInnerRow}
                                onDeleteInnerRow={onDeleteInnerRow}
                                onChangeFieldsInnerRow={onChangeFieldsInnerRow}
                                onChangeFieldsRow={onChangeFieldsRow}
                                onSavePlan={onSaveHandler}
                                prevDateEnd={prevDateEnd}
                                isDisabledAddRow={isDisabledAddRow}
                                typePlan={'planNull'}
                                onCopyRow={onCopyRow}
                                activeQuantity={activeQuantity}
                                onSaveActiveQuantity={onSaveActiveQuantity}
                            />
                        ) : (
                            <AccordionDropdownCreate
                                items={itemsCreate}
                                isControl={isControl}
                                onAddRow={onAddRowCreate}
                                onDeleteRow={onDeleteRowCreate}
                                onAddInnerRow={onAddInnerRowCreate}
                                onDeleteInnerRow={onDeleteInnerRowCreate}
                                onChangeFieldsInnerRow={onChangeFieldsInnerRowCreate}
                                onChangeFieldsRow={onChangeFieldsRowCreate}
                                onSavePlan={onSaveHandler}
                                prevDateEnd={prevDateEndCreate}
                                isDisabledAddRow={isDisabledAddRowCreate}
                                typePlan={'planNull'}
                            />
                        )}
                    </div>
                )}

                {onIsShowAccordeonItem(itemsOne, itemsOneCreate, plan?.planOne) && (
                    <div className={cn(classes.AccordionItem, { [classes.Open]: isOpenDropdown.planOne })}>
                        <div className={classes.AccordionItemHeader} onClick={() => onChangeIsOpenDropdown('planOne')}>
                            <div className={classes.AccordionItemTitle}>План — инициирование</div>

                            {isControl ? (
                                !itemsOne.length && !isOpenDropdown.planOne ? (
                                    <IconAccordionOpen />
                                ) : (
                                    <IconShevronBottom className={classes.IconShevronBottom} />
                                )
                            ) : null}

                            {!isControl && <IconShevronBottom className={classes.IconShevronBottom} />}
                        </div>

                        {itemsOne.length ? (
                            <AccordionDropdown
                                items={onVisibilityQuantityPeriods(itemsOne, 'planOne')}
                                isControl={isControl}
                                onAddRow={onAddRowOne}
                                onDeleteRow={onDeleteRowOne}
                                onAddInnerRow={onAddInnerRowOne}
                                onDeleteInnerRow={onDeleteInnerRowOne}
                                onChangeFieldsInnerRow={onChangeFieldsInnerRowOne}
                                onChangeFieldsRow={onChangeFieldsRowOne}
                                onSavePlan={onSaveHandler}
                                prevDateEnd={prevDateEndOne}
                                isDisabledAddRow={isDisabledAddRowOne}
                                typePlan={'planOne'}
                                activeQuantity={activeQuantity}
                                onSaveActiveQuantity={onSaveActiveQuantity}
                            />
                        ) : (
                            <AccordionDropdownCreate
                                items={itemsOneCreate}
                                isControl={isControl}
                                onAddRow={onAddRowOneCreate}
                                onDeleteRow={onDeleteRowOneCreate}
                                onAddInnerRow={onAddInnerRowOneCreate}
                                onDeleteInnerRow={onDeleteInnerRowOneCreate}
                                onChangeFieldsInnerRow={onChangeFieldsInnerRowOneCreate}
                                onChangeFieldsRow={onChangeFieldsRowOneCreate}
                                onSavePlan={onSaveHandler}
                                prevDateEnd={prevDateEndOneCreate}
                                isDisabledAddRow={isDisabledAddRowOneCreate}
                                typePlan={'planOne'}
                            />
                        )}
                    </div>
                )}

                {onIsShowAccordeonItem(itemsTwo, itemsTwoCreate, plan?.planTwo) && (
                    <div className={cn(classes.AccordionItem, { [classes.Open]: isOpenDropdown.planTwo })}>
                        <div className={classes.AccordionItemHeader} onClick={() => onChangeIsOpenDropdown('planTwo')}>
                            <div className={classes.AccordionItemTitle}>План — реагирование</div>

                            {isControl ? (
                                !itemsTwo.length && !isOpenDropdown.planTwo ? (
                                    <IconAccordionOpen />
                                ) : (
                                    <IconShevronBottom className={classes.IconShevronBottom} />
                                )
                            ) : null}

                            {!isControl && <IconShevronBottom className={classes.IconShevronBottom} />}
                        </div>

                        {itemsTwo.length ? (
                            <AccordionDropdown
                                items={onVisibilityQuantityPeriods(itemsTwo, 'planTwo')}
                                isControl={isControl}
                                onAddRow={onAddRowTwo}
                                onDeleteRow={onDeleteRowTwo}
                                onAddInnerRow={onAddInnerRowTwo}
                                onDeleteInnerRow={onDeleteInnerRowTwo}
                                onChangeFieldsInnerRow={onChangeFieldsInnerRowTwo}
                                onChangeFieldsRow={onChangeFieldsRowTwo}
                                onSavePlan={onSaveHandler}
                                prevDateEnd={prevDateEndTwo}
                                isDisabledAddRow={isDisabledAddRowTwo}
                                typePlan={'planTwo'}
                                activeQuantity={activeQuantity}
                                onSaveActiveQuantity={onSaveActiveQuantity}
                            />
                        ) : (
                            <AccordionDropdownCreate
                                items={itemsTwoCreate}
                                isControl={isControl}
                                onAddRow={onAddRowTwoCreate}
                                onDeleteRow={onDeleteRowTwoCreate}
                                onAddInnerRow={onAddInnerRowTwoCreate}
                                onDeleteInnerRow={onDeleteInnerRowTwoCreate}
                                onChangeFieldsInnerRow={onChangeFieldsInnerRowTwoCreate}
                                onChangeFieldsRow={onChangeFieldsRowTwoCreate}
                                onSavePlan={onSaveHandler}
                                prevDateEnd={prevDateEndTwoCreate}
                                isDisabledAddRow={isDisabledAddRowTwoCreate}
                                typePlan={'planTwo'}
                            />
                        )}
                    </div>
                )}
            </div>

            {isControl && <AddRow className={classes.AddRow} onClick={onAddRow} disabled={isDisabledAddRow} />}
        </div>
    )
}

export default DesktopPlan
