import React from 'react'

function IconRight() {
    return (
        <svg width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.5 6.48468L1.44273 7.5L4.30467 4.41765C4.42973 4.28306 4.5 4.10045 4.5 3.91003C4.5 3.71962 4.42973 3.53701 4.30467 3.40241L1.44273 0.5L0.5 1.51532L2.8906 3.91003L0.5 6.48468Z" fill="#505D6F" />
        </svg>
    )
}

export default IconRight
