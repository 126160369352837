import React, { useContext } from 'react'
import classes from './Tabs.module.scss'
import IconChevronDown from '../../icons/IconChevronDown'
import { CSSTransition } from 'react-transition-group'
import ModalTabs from '../popups/ModalTabs/ModalTabs'
import { AnaliseContext } from '../../../../context/analise'

function Tabs({ controlModal }) {
    const { state } = useContext(AnaliseContext)

    return (
        <>
            <div className={classes.Wrap}>
                <button className={classes.Btn} onClick={() => controlModal.onOpenModal('tabs')}>
                    {state.activeTabScreen === 'search' && <span>Поисковая выдыча</span>}
                    {state.activeTabScreen === 'tonality' && <span>Анализ тональности</span>}
                    {state.activeTabScreen === 'rating' && <span>Анализ рейтинга площадок</span>}

                    <IconChevronDown className={classes.IconChevronDown} />
                </button>
            </div>

            <CSSTransition in={controlModal.showModal === 'tabs'} timeout={200} mountOnEnter={true} unmountOnExit={true}>
                {(state) => <ModalTabs className={state} controlModal={controlModal} />}
            </CSSTransition>
        </>
    )
}

export default Tabs
