import React from 'react'

function IconQuery() {
    return (
        <svg width={26} height={26} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.8523 18.4861C15.0228 19.1734 13.9948 19.5145 12.9668 19.5145C11.8218 19.5145 10.6767 19.0893 9.80652 18.2389C8.06097 16.548 8.06606 13.7941 9.80652 12.0983C11.547 10.4025 14.3816 10.4025 16.1271 12.0983C17.5826 13.5123 17.8218 15.663 16.8498 17.3193L18.7836 19.1981C19.089 19.4947 19.089 19.9694 18.7836 20.266C18.6309 20.4144 18.4325 20.4885 18.234 20.4885C18.0355 20.4885 17.8371 20.4144 17.6844 20.266L15.8523 18.4861ZM12.9668 12.4147C12.2391 12.4147 11.5114 12.6817 10.9566 13.2206C9.84723 14.2984 9.84723 16.0487 10.9566 17.1265C12.0661 18.2043 13.8676 18.2043 14.977 17.1265C16.0864 16.0487 16.0864 14.2984 14.977 13.2206C14.4223 12.6817 13.6946 12.4147 12.9668 12.4147Z'
                fill='#2E2382'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M25.1196 0H0.814249C0.366412 0 0 0.355979 0 0.791064V24.4192C0 24.8542 0.366412 25.2102 0.814249 25.2102H25.1247C25.5725 25.2102 25.9389 24.8542 25.9389 24.4192V0.791064C25.9338 0.355979 25.5725 0 25.1196 0ZM24.3053 23.633H1.6285V7.38656H24.3104V23.633H24.3053ZM1.6285 5.80443H24.3053H24.3104V1.58213H1.6285V5.80443Z'
                fill='#2E2382'
            />
            <path
                d='M12.7327 4.54368H21.547C21.9948 4.54368 22.3612 4.1877 22.3612 3.75261C22.3612 3.31753 21.9948 2.96155 21.547 2.96155H12.7327C12.2849 2.96155 11.9185 3.31753 11.9185 3.75261C11.9185 4.1877 12.2798 4.54368 12.7327 4.54368Z'
                fill='#2E2382'
            />
            <ellipse cx='4.53938' cy='3.75255' rx='0.80916' ry='0.78612' fill='#2E2382' />
            <ellipse cx='7.21126' cy='3.75255' rx='0.80916' ry='0.78612' fill='#2E2382' />
            <ellipse cx='9.88289' cy='3.75255' rx='0.80916' ry='0.78612' fill='#2E2382' />
        </svg>
    )
}

export default IconQuery
