import React, {useContext, useEffect, useState} from "react"
import "./AdminManager.scss"
import Helmet from "react-helmet";
import Search from "../../components/Replica/Admin/Users/Search/Search";
import NavTabs from "../../components/Replica/Admin/Users/NavTabs/NavTabs";
import Filter from "../../components/Replica/Admin/Users/Filter/Filter";
import Loading from "../../components/UI/Loading/Loading";
import {apiRoleList} from "../../api/role";
import RoleList from "../../components/Replica/Admin/Users/RoleList/RoleList";
import {PopupContext} from "../../context/popup/popupContext";

const AdminManager = () => {
    const popupContextData = useContext(PopupContext)
    const [loading, setLoading] = useState(true)
    const [roles, setRoles] = useState(undefined)

    const [filter, setFilter] = useState({
        search: {
            type: 'text',
            name: 'search',
            value: '',
            placeholder: 'Введите запрос и нажмите Enter',
            label: 'Поиск',
        },
        status: {
            name: 'status',
            value: '',
            options: [
                {id: 'Y', name: 'Активен'},
                {id: 'N', name: 'Не активен'},
            ],
            optionDefault: 'Статус',
        },
    })

    const getRoles = async () => {
        popupContextData.setPreloader(true)

        const roles = await apiRoleList({
            onlyManagers: 'Y'
        }, filter)

        if (roles.status === 'success') {
            setRoles(roles.data)
        } else {
            setRoles(undefined)
        }

        popupContextData.setPreloader(false)
        setLoading(false)
    }

    const filterSelectChange = async (value, name) => {
        const filterCopy = {...filter}
        filterCopy[name].value = value
        setFilter(filterCopy)

        await getRoles()
    }

    const onToggleRole = (id) => {
        const rolesCopy = {...roles}
        rolesCopy[id].openRole = !rolesCopy[id].openRole
        setRoles(rolesCopy)
    }

    useEffect(() => {
        getRoles()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Helmet>
                <title>Агентство</title>
            </Helmet>

            <div className="admin-company">
                <div className="admin-company__wrap">
                    <div className="admin-company__h1-mobile admin-company__h1-mobile_users-mode">Пользователи</div>

                    <Search
                        search={filter.search}
                        filterInputChange={(value, name) => filterSelectChange(value, name)}
                    />

                    <div className="select-pages select-pages_users-mode">
                        <NavTabs active="manager" />

                        <Filter
                            filter={filter}
                            filterInputChange={(value, name) => filterSelectChange(value, name)}
                        />
                    </div>

                    {loading ? <Loading /> : null}

                    {!loading && roles !== undefined ?
                        <RoleList
                            onToggleRole={(roleId) => onToggleRole(roleId)}
                            roles={roles}
                            filter={filter}
                        />
                    : null}

                    {/*<ShowMore />*/}
                </div>
            </div>
        </>
    )
}

export default AdminManager