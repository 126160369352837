import React, { useState, useEffect, useContext } from 'react'
import Helmet from 'react-helmet'
import CompanyList from '../../components/Replica/Admin/Company/CompanyList/CompanyList'
import CompanyAdd from '../../components/Replica/Admin/Company/CompanyAdd/CompanyAdd'
import Search from '../../components/Replica/Admin/Company/Search/Search'
import { apiCompanyList } from '../../api/companies'
import Loading from '../../components/UI/Loading/Loading'
import { PopupContext } from '../../context/popup/popupContext'

const AdminCompany = () => {
    const popupContextData = useContext(PopupContext)
    const [loading, setLoading] = useState(true)
    const [companies, setCompanies] = useState()
    const [companyAdd, setCompanyAdd] = useState(false)

    const [filter, setFilter] = useState({
        search: {
            type: 'text',
            name: 'search',
            value: '',
            placeholder: 'Введите запрос и нажмите Enter',
            label: 'Поиск'
        }
    })

    const getCompanies = async () => {
        popupContextData.setPreloader(true)
        const companies = await apiCompanyList(filter)

        if (companies.status === 'success') {
            setCompanies(companies.data)
        } else {
            setCompanies()
        }

        popupContextData.setPreloader(false)
        setLoading(false)
    }

    const filterSelectChange = async (value, name) => {
        const filterCopy = { ...filter }
        filterCopy[name].value = value
        setFilter(filterCopy)

        await getCompanies()
    }

    const onToggleCompany = (id) => {
        const companiesCopy = { ...companies }
        companiesCopy[id].openCompany = !companiesCopy[id].openCompany
        setCompanies(companiesCopy ? companiesCopy : undefined)
    }

    useEffect(() => {
        getCompanies()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Helmet>
                <title>Компании и проекты</title>
            </Helmet>

            <div className='admin-company'>
                <div className='admin-company__wrap'>
                    <div className='admin-company__h1-mobile'>Компании и проекты</div>

                    {companyAdd ? (
                        <CompanyAdd close={() => setCompanyAdd(false)} getCompanies={getCompanies} />
                    ) : (
                        <Search
                            nav={true}
                            cls='search-and-settings search-and-settings_company-mode search-and-settings_margin-bottom-0px search-and-settings__add-edit-replic_padding'
                            search={filter.search}
                            filterInputChange={(value, name) => filterSelectChange(value, name)}
                            companyAdd={() => setCompanyAdd(true)}
                        />
                    )}

                    {loading ? <Loading /> : null}

                    {!loading && companies !== undefined ? (
                        <CompanyList
                            onToggleCompany={(companyId) => onToggleCompany(companyId)}
                            companies={companies}
                            getCompanies={getCompanies}
                            filter={filter}
                        />
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default AdminCompany
