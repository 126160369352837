import React, { useContext, useEffect, useState } from 'react'
import classes from './TabScreenTonality.module.scss'
import TonalBlockChart from './TonalBlockChart/TonalBlockChart'
import TonalBlockChartLine from './TonalBlockChartLine/TonalBlockChartLine'
import axios from 'axios'
import config from '../../../../config/config'
import { AnaliseContext } from '../../../../context/analise'

const TabScreenTonality = () => {
    const { state } = useContext(AnaliseContext)
    const { filter } = state

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const onGetData = async () => {
        setIsLoading(true)

        try {
            const token = localStorage.getItem('token')

            const { data } = await axios.get(`${config.SERVER_BASE_URL}/analysis/tonality`, {
                params: {
                    token: token,
                    project: state.filter.project?.value,
                    deepLevel: state.filter.deepLevel,
                    searchSystem: state.filter.searchSystem,
                    region: state.filter.region,
                    query: state.filter.query,
                    dateFrom: state.dateFrom,
                    dateTo: state.dateTo
                }
            })

            setIsLoading(false)

            if (data?.status === 'fail') {
                alert(data?.errorText?.split(',')?.join('\n'))
                return
            }

            if (data?.status === 'success') {
                setData(data.content)
            }
        } catch (err) {
            console.log('onGetData', err)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (filter.query) {
            onGetData()
        }
    }, [state.dateFrom, state.dateTo, filter.query])

    return (
        <div className={classes.TonalBlock}>
            <div className={classes.TopBlock}>
                <div className={classes.RowTwo}>
                    <div className={classes.Infotmation}>
                        <div className={classes.ColorDot} data-color='Orange' />
                        <div className={classes.Text}>Управляемое</div>
                    </div>

                    <div className={classes.Infotmation}>
                        <div className={classes.ColorDot} data-color='Black' />
                        <div className={classes.Text}>Нерелевант</div>
                    </div>

                    <div className={classes.Infotmation}>
                        <div className={classes.ColorDot} data-color='Green' />
                        <div className={classes.Text}>Позитив</div>
                    </div>

                    <div className={classes.Infotmation}>
                        <div className={classes.ColorDot} data-color='Red' />
                        <div className={classes.Text}>Негатив</div>
                    </div>
                </div>
            </div>

            <div className={classes.MainBlock}>
                {!data?.length && <div className={classes.MainBlockText}>Данные отсутствуют</div>}

                {data?.length === 1 && <TonalBlockChart data={data} width={310} height={310} cx={150} cy={150} />}

                {data?.length > 1 && <TonalBlockChartLine data={data} />}
            </div>
        </div>
    )
}

export default TabScreenTonality
