import React from 'react'

function IconDefault() {
    return (
        <svg width={28} height={28} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 14C0 6.26865 6.26842 0 13.9995 0C21.7319 0 28 6.26865 28 14C28 21.732 21.7319 28 13.9995 28C6.26842 28 0 21.732 0 14ZM3.04351 14C3.04351 20.051 7.94904 24.9565 13.9995 24.9565C20.0516 24.9565 24.9565 20.051 24.9565 14C24.9565 7.94865 20.0516 3.04348 13.9995 3.04348C7.94904 3.04348 3.04351 7.94865 3.04351 14Z'
                fill='#BECBDC'
            />
            <path
                d='M12.6309 9.61568V19.1236C12.6309 19.8117 13.2441 20.3696 14.0004 20.3696C14.7567 20.3696 15.37 19.8117 15.37 19.1236V9.61568C15.37 8.92758 14.7567 8.36963 14.0004 8.36963C13.2441 8.36963 12.6309 8.92758 12.6309 9.61568Z'
                fill='#BECBDC'
            />
            <path
                d='M18.754 13L9.24605 13C8.55795 13 8 13.6133 8 14.3696C8 15.1259 8.55795 15.7391 9.24605 15.7391L18.754 15.7391C19.442 15.7391 20 15.1259 20 14.3696C20 13.6133 19.442 13 18.754 13Z'
                fill='#BECBDC'
            />
        </svg>
    )
}

export default IconDefault
