import React, { useEffect, useState } from 'react'
import IconChevronTop from '../../../../../icons/IconChevronTop'
import classes from './ReplicaHistory.module.scss'
import HistoryItem from './HistoryItem/HistoryItem'

function ReplicaHistory({ items, onCancelNewReplica, status, accessLevel, isOpenedReplic }) {
    const [isOpen, setIsOpen] = useState(false)

    const onToogleIsOpen = () => {
        setIsOpen((prev) => !prev)
    }

    return (
        <div className={classes.Wrap}>
            <div className={classes.Header}>
                История изменений:
                <button className={classes.BtnToogle} onClick={onToogleIsOpen} data-open={isOpen ? 'Y' : 'N'}>
                    {isOpen ? 'Свернуть' : 'Развернуть'}
                    <span className={classes.IconWrap}>
                        <IconChevronTop />
                    </span>
                </button>
            </div>

            {isOpen && (
                <div className={classes.Items}>
                    {items.map((item, index) => (
                        <HistoryItem item={item} key={index} status={status} onCancelNewReplica={onCancelNewReplica} accessLevel={accessLevel} />
                    ))}
                </div>
            )}
        </div>
    )
}

export default ReplicaHistory
