import React from 'react'
import classes from '../DesktopPlan.module.scss'
import { headerDataDesctop, headerDataTablet, headerDataInner } from '../headerData'
import Row from '../Row/Row'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { getTypeHeader } from '../../../../utils/getTypeHeader'
import ControlQuantity from '../ControlQuantity/ControlQuantity'

function AccordionDropdown({
    items,
    isControl,
    onAddRow,
    onDeleteRow,
    onAddInnerRow,
    onDeleteInnerRow,
    onChangeFieldsInnerRow,
    onChangeFieldsRow,
    onSavePlan,
    prevDateEnd,
    isDisabledAddRow,
    typePlan,
    onCopyRow,
    onSaveActiveQuantity,
    activeQuantity
}) {
    const { width } = useWindowSize()
    const typeHeader = getTypeHeader(width)

    const getTitleColumns = () => {
        if (typeHeader === 'desctop') {
            return headerDataDesctop
        }
        if (typeHeader === 'tablet') {
            return headerDataTablet
        }
        if (typeHeader === 'mobail') {
            return headerDataInner
        }
    }

    const titleColumns = getTitleColumns()

    return (
        <div className={classes.AccordionDropdown}>
            {items?.length > 0 && (
                <>
                    {!isControl && (
                        <ControlQuantity
                            activeValue={activeQuantity[typePlan]}
                            onSave={onSaveActiveQuantity}
                            typePlan={typePlan}
                        />
                    )}

                    <div className={classes.Header}>
                        {titleColumns.map((item, index) => (
                            <div className={classes.HeaderItem} key={index} dangerouslySetInnerHTML={{ __html: item }} />
                        ))}
                    </div>

                    <div className={classes.Items}>
                        {items.map((item, index) => (
                            <Row
                                item={item}
                                num={index + 1}
                                key={`row-${item.id}`}
                                isControl={isControl}
                                isLast={items?.length - 1 === index}
                                onAddRow={onAddRow}
                                onDeleteRow={onDeleteRow}
                                onAddInnerRow={onAddInnerRow}
                                onDeleteInnerRow={onDeleteInnerRow}
                                onChangeFieldsInnerRow={onChangeFieldsInnerRow}
                                titleColumns={titleColumns}
                                onChangeFieldsRow={onChangeFieldsRow}
                                onSavePlan={onSavePlan}
                                prevDateEnd={prevDateEnd}
                                isDisabledAddRow={isDisabledAddRow}
                                typePlan={typePlan}
                                onCopyRow={onCopyRow}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default AccordionDropdown
