import React from 'react'

function IconChecked({ className }) {
    return (
        <svg className={className} width={13} height={11} viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M2 4.625L5.24 8L11 2' stroke='#505D6F' strokeWidth={3} strokeLinecap='round' />
        </svg>
    )
}

export default IconChecked
