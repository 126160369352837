import React, {useEffect} from "react"
import "./Monitoring.scss"
import Helmet from "react-helmet"

const Monitoring = () => {
    const load = async () => {

    }

    useEffect(() => {
        load()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Helmet>
                <title>Мониторинг</title>
            </Helmet>
        </>
    )
}

export default Monitoring