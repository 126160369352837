import React from 'react'

function IconArrowDown() {
    return (
        <svg width={7} height={10} viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.5 10C3.43221 10 3.36756 9.98651 3.30861 9.96206C3.25051 9.93802 3.19602 9.90256 3.14857 9.85567C3.14786 9.85497 3.14715 9.85426 3.14645 9.85355L0.146447 6.85355C-0.0488157 6.65829 -0.0488157 6.34171 0.146447 6.14645C0.341709 5.95118 0.658291 5.95118 0.853553 6.14645L3 8.29289L3 0.5C3 0.223858 3.22386 0 3.5 0C3.77614 0 4 0.223858 4 0.5L4 8.29289L6.14645 6.14645C6.34171 5.95118 6.65829 5.95118 6.85355 6.14645C7.04882 6.34171 7.04882 6.65829 6.85355 6.85355L3.85375 9.85335C3.85369 9.85342 3.85362 9.85349 3.85355 9.85355C3.85285 9.85426 3.85214 9.85497 3.85143 9.85567C3.80398 9.90256 3.74949 9.93802 3.69139 9.96206C3.63244 9.98651 3.56779 10 3.5 10Z" fill="#94A2B6" />
        </svg>
    )
}

export default IconArrowDown
