import React from 'react'

function IconNeutral({ className }) {
    return (
        <svg className={className} width={28} height={28} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 14C0 6.26865 6.26842 0 13.9995 0C21.7319 0 28 6.26865 28 14C28 21.732 21.7319 28 13.9995 28C6.26842 28 0 21.732 0 14ZM3.04351 14C3.04351 20.051 7.94904 24.9565 13.9995 24.9565C20.0516 24.9565 24.9565 20.051 24.9565 14C24.9565 7.94865 20.0516 3.04348 13.9995 3.04348C7.94904 3.04348 3.04351 7.94865 3.04351 14Z'
                fill='#505D6F'
            />
            <ellipse cx='9.89044' cy='10.6097' rx='2.02911' ry='2.02909' fill='#505D6F' />
            <ellipse cx='18.1092' cy='10.6097' rx='2.02911' ry='2.02909' fill='#505D6F' />
            <path
                d='M19.2244 17.1108H8.77388C8.01756 17.1108 7.4043 17.7241 7.4043 18.4804C7.4043 19.2367 8.01756 19.85 8.77388 19.85H19.2244C19.9807 19.85 20.594 19.2367 20.594 18.4804C20.594 17.7241 19.9807 17.1108 19.2244 17.1108Z'
                fill='#505D6F'
            />
        </svg>
    )
}

export default IconNeutral
