import React from 'react'

function IconPencil() {
    return (
        <svg width={17} height={17} viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 16.6998H6.4L16.4 6.6998L10 0.299805L0 10.2998V16.6998ZM5.6 14.6998H2V11.0998L5.6 14.6998ZM3.4 9.6998L7 13.2998L11.1 9.1998L7.5 5.5998L3.4 9.6998ZM12.5 7.7998L8.9 4.1998L10 3.0998L13.6 6.6998L12.5 7.7998Z'
                fill='#2E2382'
            />
        </svg>
    )
}

export default IconPencil
