import React from 'react'

function IconAdd() {
    return (
        <svg width={26} height={25} viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <mask id='path-1-inside-1_1509_20588' fill='white'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M25.1787 0H0.816166C0.367275 0 0 0.35301 0 0.784468V24.2155C0 24.647 0.367275 25 0.816166 25H25.1838C25.6327 25 26 24.647 26 24.2155V0.784468C25.9949 0.35301 25.6327 0 25.1787 0ZM24.363 23.436H1.63281V7.32495H24.3681V23.436H24.363ZM1.63281 5.756H24.363H24.3681V1.56891H1.63281V5.756Z'
                />
            </mask>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M25.1787 0H0.816166C0.367275 0 0 0.35301 0 0.784468V24.2155C0 24.647 0.367275 25 0.816166 25H25.1838C25.6327 25 26 24.647 26 24.2155V0.784468C25.9949 0.35301 25.6327 0 25.1787 0ZM24.363 23.436H1.63281V7.32495H24.3681V23.436H24.363ZM1.63281 5.756H24.363H24.3681V1.56891H1.63281V5.756Z'
                fill='white'
            />
            <path
                d='M26 0.784468H26.3L26.3 0.780921L26 0.784468ZM1.63281 23.436H1.33281V23.736H1.63281V23.436ZM1.63281 7.32495V7.02495H1.33281V7.32495H1.63281ZM24.3681 7.32495H24.6681V7.02495H24.3681V7.32495ZM24.3681 23.436V23.736H24.6681V23.436H24.3681ZM1.63281 5.756H1.33281V6.056H1.63281V5.756ZM24.3681 5.756V6.056H24.6681V5.756H24.3681ZM24.3681 1.56891H24.6681V1.26891H24.3681V1.56891ZM1.63281 1.56891V1.26891H1.33281V1.56891H1.63281ZM0.816166 0.3H25.1787V-0.3H0.816166V0.3ZM0.3 0.784468C0.3 0.529786 0.521649 0.3 0.816166 0.3V-0.3C0.212901 -0.3 -0.3 0.176235 -0.3 0.784468H0.3ZM0.3 24.2155V0.784468H-0.3V24.2155H0.3ZM0.816166 24.7C0.521649 24.7 0.3 24.4702 0.3 24.2155H-0.3C-0.3 24.8238 0.212901 25.3 0.816166 25.3V24.7ZM25.1838 24.7H0.816166V25.3H25.1838V24.7ZM25.7 24.2155C25.7 24.4702 25.4784 24.7 25.1838 24.7V25.3C25.7871 25.3 26.3 24.8238 26.3 24.2155H25.7ZM25.7 0.784468V24.2155H26.3V0.784468H25.7ZM25.1787 0.3C25.4763 0.3 25.697 0.528604 25.7 0.788014L26.3 0.780921C26.2928 0.177417 25.7892 -0.3 25.1787 -0.3V0.3ZM1.63281 23.736H24.363V23.136H1.63281V23.736ZM1.33281 7.32495V23.436H1.93281V7.32495H1.33281ZM24.3681 7.02495H1.63281V7.62495H24.3681V7.02495ZM24.6681 23.436V7.32495H24.0681V23.436H24.6681ZM24.363 23.736H24.3681V23.136H24.363V23.736ZM24.363 5.456H1.63281V6.056H24.363V5.456ZM24.3681 5.456H24.363V6.056H24.3681V5.456ZM24.0681 1.56891V5.756H24.6681V1.56891H24.0681ZM1.63281 1.86891H24.3681V1.26891H1.63281V1.86891ZM1.93281 5.756V1.56891H1.33281V5.756H1.93281Z'
                fill='white'
                mask='url(#path-1-inside-1_1509_20588)'
            />
            <rect x='12.0283' y='10.9082' width='1.50353' height='7.93329' fill='white' />
            <path fillRule='evenodd' clipRule='evenodd' d='M8.77051 15.6187V14.1312H16.7893V15.6187H8.77051Z' fill='white' />
            <path
                d='M12.7625 4.50595H21.5975C22.0463 4.50595 22.4136 4.15294 22.4136 3.72148C22.4136 3.29002 22.0463 2.93701 21.5975 2.93701H12.7625C12.3136 2.93701 11.9463 3.29002 11.9463 3.72148C11.9463 4.15294 12.3085 4.50595 12.7625 4.50595Z'
                fill='white'
            />
            <ellipse cx='4.55032' cy='3.72146' rx='0.811065' ry='0.779565' fill='white' />
            <ellipse cx='7.22806' cy='3.72146' rx='0.811065' ry='0.779565' fill='white' />
            <ellipse cx='9.90579' cy='3.72146' rx='0.811065' ry='0.779565' fill='white' />
        </svg>
    )
}

export default IconAdd
