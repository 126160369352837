import classes from './Tile.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import IconPencil from '../../../icons/IconPencil'
import { DocumentsTile } from './DocumentsTile/DocumentsTile'
import { TextTile } from './TextTile/TextTile'
import { CustomTile } from './CustomTile/CustomTile'
import { Buttons } from './Buttons/Buttons'
import { IconPlus } from '../../../icons/IconPlus'
import { cloneDeep } from 'lodash'
import { IconTrash } from '../../../icons/IconTrash'
import { useWindowSize } from '../../../hooks/useWindowSize'
import InputEditText from '../InputEditText/InputEditText'


const ACTIVE_EDIT = {
    default: null,
    name: 'name',
    description: 'description'
}

export const Tile = ({ type, onChange, data, deleteTile, size, setSize, onTapUploadFiles }) => {
    const [editInput, setEditInput] = useState(ACTIVE_EDIT.default)
    const [name, setName] = useState(data?.name ?? '')
    const [description, setDescriptions] = useState(data?.description ?? '')
    const [documents, setDocuments] = useState(data?.documents ?? [])

    const onSelectDocuments = (e) => {
        const media = e.target.files[0];


        const result = []

        result.push({
            name: '',
            file: {
                size: media.size,
                name: media.name,
                type: media.type,
                file: media,
                path: '',
                id: media.id || '1'
            }
        });


        console.log(`[DEV] @@@onSelectDocuments length: ${result.length}, result: ${JSON.stringify(result)}, result: ${result.length}`)


        onChange(type === 'custom' ? 'blocksCustom' : 'blocksSystem', data.id, result, description, name)
        setDocuments(result)
        setEditInput(null)
    }

    const onRemoveFile = (name) => {

        const array = documents.filter((document) => document.file.name !== name)
        setDocuments([...array])
        onChange(type === 'custom' ? 'blocksCustom' : 'blocksSystem', data.id, array, description, name)


        setEditInput(null)
    }

    // Утерян фокус на поле ввода - отменяем редактирование. Сохраняем изменения
    const onSaveData = () => {

        // Каждый символ в названии пробел ?
        if (name.length < 3 || name.length > 255) {
            alert('Название должно быть не меньше 3 символов и не больше 255.')
            setName(data.name)
        } else {
            if ([...name].every((item) => [' ', '\n'].includes(item))) {
                alert('Введите называние папки')
            } else {

                if (description.length > 1) {
                    if (description.length <= 5) {
                        alert('Описание должно превышать 5 символов')
                        return
                    }

                    // Проверяем, есть ли хотя бы один символ != пробелу
                    if ([...description].every((item) => [' ', '\n'].includes(item))) {
                        setDescriptions(data.description)
                        alert('Введите корректное описание')
                        return
                    }

                }

            }
        }

        onChange(type === 'custom' ? 'blocksCustom' : 'blocksSystem', data.id, documents, description, name)
        setEditInput(null)
    }


    const onChangeStatusEdit = (fieldId) => setEditInput(fieldId)


    const id = useRef()

    useEffect(() => {
        id.current = Math.floor(Math.random() * 1000000)
    }, [])


    const handleDefaultTitle = () => {
        if (type !== 'custom') {
            return editInput === 'name' ?

                <InputEditText
                    id={'name'}
                    min={3}
                    max={100}
                    onChange={(id, value) => setName(value)}
                    onBlur={onSaveData}
                    value={name}
                    style={{
                        fontWeight: 600,
                        fontSize: 24,
                        color: '#2E2382',
                        textDecoration: 'none',
                        border: 'none',
                        overflow: 'none',
                        outline: 'none',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        resize: 'none'
                    }} /> :
                <div onClick={() => onChangeStatusEdit('name')} className={classes.TileTitle}>
                    {name}
                </div>
        }

        return null
    }


    const onChangeInput = (id, value) => {
        if (id === 'name') {
            setName(value)
        }
        if (id === 'description') {
            setDescriptions(value)
        }

    }

    return (
        <div
            className={cn(classes.Tile, {
                [classes.DefaultTile]: type !== 'custom'
            })}
        >
            {data.documents.length === 1 || documents.length === 1 ? null : <label
                htmlFor={id.current}
                style={{ cursor: 'pointer' }}
            >
                <input
                    style={{
                        display: 'none'
                    }}
                    accept={'.doc,.docx,.xml,application/msword,.pdf'}
                    type='file'
                    id={id.current}
                    onChange={onSelectDocuments}
                />
                <span className={classes.iconUploadImage} onClick={() => onTapUploadFiles()}> </span>

            </label>}


            {type === 'custom' &&
                <span className={classes.iconRemoveFolder} onClick={() => deleteTile(data.id)}> </span>}


            <div className={classes.Icon}></div>
            {handleDefaultTitle()}


            {['text', 'documents'].includes(type) &&
                (
                <TextTile
                    activeEdit={editInput}
                    onBlur={onSaveData}
                    onChangeStatusEdit={onChangeStatusEdit}
                    documents={documents}
                    description={description}
                    onChange={onChangeInput}
                />
            )}

            {type === 'custom' && (
                <CustomTile
                    onBlur={onSaveData}
                    activeEdit={editInput}
                    onChangeStatusEdit={onChangeStatusEdit}
                    name={name}
                    description={description}
                    documents={documents}
                    onChange={onChangeInput}
                    onRemoveFile={onRemoveFile}
                />
            )}
        </div>
    )
}
