import React, { useContext } from 'react'
import classes from './ModalRegion.module.scss'
import cn from 'classnames'
import MobailModal from '../../../UI/MobailModal/MobailModal'
import IconGeo from '../../../icons/IconGeo'
import { AnaliseContext } from '../../../../../context/analise'
import IconCheckedBig from '../../../icons/IconCheckedBig'

function ModalRegion({ controlModalChildren }) {
    const { state, actions } = useContext(AnaliseContext)

    return (
        <MobailModal title='Регион' controlModal={controlModalChildren} icon={<IconGeo />} isIcon>
            <div className={classes.Wrap}>
                {state.regions?.length > 0 ? (
                    state.regions?.map((item, index) => (
                        <button
                            className={cn(classes.Btn, { [classes.Active]: item.name === state.filter?.region })}
                            key={index}
                            onClick={() => {
                                actions.onChangeFilter('region', item.name)
                                controlModalChildren.onCloseModal()
                            }}
                        >
                            {item.name}
                            <IconCheckedBig />
                        </button>
                    ))
                ) : (
                    <div className={classes.Empty}>Список пуст</div>
                )}
            </div>
        </MobailModal>
    )
}

export default ModalRegion
