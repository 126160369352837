export const customStyles = {
    container: (base, state) => ({
        ...base,
        width: '100%',
        height: '100%',
        opacity: state.isDisabled ? '.2' : '1'
    }),
    control: (base) => ({
        ...base,
        background: 'unset',
        width: '100%',
        height: '100%',
        borderStyle: 'none',
        borderRadius: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        cursor: 'pointer',
        '&:hover': {
            background: 'unset'
        }
    }),
    valueContainer: (base) => ({
        ...base,
        height: '100%',
        padding: '8px',
        marginLeft: '-3px',
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '16px',
        lineHeight: '19px'
    }),
    singleValue: (base, state) => ({
        ...base,
        color: '#2E2382',
        opacity: state.isDisabled ? '.5' : '1',
        fontSize: window.innerWidth <= 1440 ? '16px' : '18px',
        lineHeight: window.innerWidth <= 1440 ? '19px' : '21px'
    }),
    placeholder: (base, state) => ({
        ...base,
        color: '#2E2382'
    }),
    menu: (base) => ({
        ...base,
        marginTop: '0px',
        zIndex: '2000',
        boxShadow: '0 10px 24px 0 #f2f0ff'
    }),
    menuList: (base) => ({
        ...base,
        marginTop: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'none',
        position: 'relative',
        width: '100%'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    option: (base) => ({
        ...base,
        width: '100%',
        paddingLeft: '20px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderTop: '1px solid #D0DDEF',
        height: '61px',
        padding: '0px',
        // fontSize: window.innerWidth < 768 ? '16px' : '18px',
        // lineHeight: window.innerWidth < 768 ? '19px' : '21px',
        fontSize: window.innerWidth <= 1440 ? '16px' : '18px',
        lineHeight: window.innerWidth <= 1440 ? '19px' : '21px',
        cursor: 'pointer',
        color: window.innerWidth < 768 ? '#2E2382' : '#505D6F',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#e00c3c'
        }
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: state.selectProps.menuIsOpen ? '#e00c3c' : '#e00c3c;',
        '&:hover': {
            color: state.selectProps.menuIsOpen ? '#e00c3c' : '#e00c3c;'
        }
    })
}

export const customStyles2 = {
    container: (base) => ({
        ...base,
        width: '100%',
        height: '100%'
    }),
    control: (base) => ({
        ...base,
        width: '100%',
        height: '100%',
        borderStyle: 'none',
        borderRadius: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        cursor: 'pointer'
        // '&:hover': {
        //     background: window.innerWidth > 767 && window.innerWidth < 1280 ? '#FFFFFF' : 'unset'
        // }
    }),
    valueContainer: (base) => ({
        ...base,
        height: '100%',
        padding: '8px',
        marginLeft: '-3px',
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: window.innerWidth < 768 ? 'flex-start' : 'flex-end',
        // fontSize: '18px',
        // lineHeight: '21px'
        fontSize: window.innerWidth <= 1440 ? '16px' : '18px',
        lineHeight: window.innerWidth <= 1440 ? '19px' : '21px'
    }),
    singleValue: (base, state) => ({
        ...base,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent:
            window.innerWidth < 768 ? 'flex-start' : window.innerWidth > 767 && window.innerWidth < 1200 ? 'center' : 'flex-end',
        alignItems: 'center',
        textAlign: 'end',
        paddingLeft: window.innerWidth < 768 ? '0px' : '28px',
        opacity: state.isDisabled ? '.5' : '1'
    }),
    menu: (base) => ({
        ...base,
        marginTop: '-5px',
        boxShadow: '0 10px 24px 0 #f2f0ff'
    }),
    menuList: (base) => ({
        ...base,
        backgroundColor: 'none',
        paddingBottom: '0px',
        position: 'relative',
        zIndex: '1000',
        width: '100%'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    option: (base) => ({
        ...base,
        width: '100%',
        display: 'flex',
        justifyContent: window.innerWidth < 768 ? 'start' : 'center',
        paddingLeft: window.innerWidth < 768 ? '20px' : '0px',
        alignItems: 'center',
        borderTop: '1px solid #D0DDEF',
        height: '61px',
        padding: '0px',
        // fontSize: '18px',
        // lineHeight: '21px',
        fontSize: window.innerWidth <= 1440 ? '16px' : '18px',
        lineHeight: window.innerWidth <= 1440 ? '19px' : '21px',
        cursor: 'pointer',
        color: '#505D6F',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#e00c3c'
        }
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: state.selectProps.menuIsOpen ? '#e00c3c' : '#e00c3c;',
        '&:hover': {
            color: state.selectProps.menuIsOpen ? '#e00c3c' : '#e00c3c;'
        }
    })
}

export const customStyles3 = {
    container: (base) => ({
        ...base,
        width: '100%',
        height: '100%'
    }),
    control: (base) => ({
        ...base,
        width: '100%',
        height: '100%',
        marginRight: '0px',
        borderStyle: 'none',
        borderRadius: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        cursor: 'pointer'
        // '&:hover': {
        //     background: window.innerWidth > 767 && window.innerWidth < 1280 ? '#FFFFFF' : 'unset'
        // }
    }),
    valueContainer: (base) => ({
        ...base,
        height: '100%',
        padding: window.innerWidth > 767 && window.innerWidth < 1200 ? '0px' : '8px',
        paddingRight: '0px',
        marginLeft: '-3px',
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
        // fontSize: '18px',
        // lineHeight: '21px'
        fontSize: window.innerWidth <= 1440 ? '16px' : '18px',
        lineHeight: window.innerWidth <= 1440 ? '19px' : '21px'
    }),
    singleValue: (base, state) => ({
        ...base,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        textAlign: 'end',
        marginRight: '0px',
        opacity: state.isDisabled ? '.5' : '1'
    }),
    placeholder: (base, state) => ({
        ...base,
        // color: '#2E2382',
        left: '20px'
    }),
    menu: (base) => ({
        ...base,
        marginTop: '-2px',
        zIndex: '1000',
        boxShadow: '0 10px 24px 0 #f2f0ff'
    }),
    menuList: (base) => ({
        ...base,
        backgroundColor: 'none',
        paddingTop: '0px',
        paddingBottom: '0px',
        position: 'relative',
        width: '100%'
    }),
    indicatorsContainer: (base, state) => {
        return {
            ...base,
            position: 'absolute',
            right: window.innerWidth <= 1440 ? '114px' : '130px',
            top: '10px'
        }
    },
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    option: (base) => ({
        ...base,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '1px solid #D0DDEF',
        height: '61px',
        padding: '0px',
        fontSize: '18px',
        lineHeight: '21px',
        cursor: 'pointer',
        color: '#505D6F',

        '&:hover': {
            color: '#fff',
            backgroundColor: '#e00c3c'
        }
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: state.selectProps.menuIsOpen ? '#e00c3c' : '#e00c3c;',
        '&:hover': {
            color: state.selectProps.menuIsOpen ? '#e00c3c' : '#e00c3c;'
        }
    })
}

export const customStyles4 = {
    ...customStyles2,
    singleValue: (state) => ({
        paddingLeft: '0px',
        opacity: state.isDisabled ? '.5' : '1',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '21px',
        color: '#505D6F'
    }),
    valueContainer: (base) => ({
        ...base,
        height: '60px',
        justifyContent: window.innerWidth > 767 ? 'flex-start' : 'flex-end',
        padding: 0
    })
}

export const customStyles5 = {
    control: (base) => ({
        ...base,
        borderStyle: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid #D0DDEF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        cursor: 'pointer',
        '&:hover': {
            background: 'unset'
        }
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '8px',
        marginLeft: '-3px',
        pointerEvents: 'none'
    }),
    menu: (base) => ({
        ...base,
        width: '80px',
        boxShadow: ' 0 10px 24px 0 #f2f0ff'
    }),
    menuList: (base) => ({
        ...base,
        marginTop: '-13px',
        backgroundColor: 'none',
        position: 'relative',
        zIndex: '1000',
        width: '79px',
        paddingBottom: '0px'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    option: (base) => ({
        ...base,
        borderTop: '1px solid #D0DDEF',
        height: '100%',
        padding: '0px',
        paddingLeft: '6px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e00c3c'
        }
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: state.selectProps.menuIsOpen ? '#e00c3c' : '#D8D8D8;',
        '&:hover': {
            color: state.selectProps.menuIsOpen ? '#e00c3c' : '#D8D8D8;'
        }
    })
}

export const customStyles6 = {
    control: (base) => ({
        ...base,
        borderStyle: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid #D0DDEF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        cursor: 'pointer',
        '&:hover': {
            background: 'unset'
        }
    }),
    valueContainer: (base) => ({
        ...base,
        width: '100%',
        padding: '8px',
        marginLeft: '-3px',
        pointerEvents: 'none'
    }),
    menu: (base) => ({
        ...base,
        boxShadow: ' 0 10px 24px 0 #f2f0ff',
        width: '140px'
    }),
    menuList: (base) => ({
        ...base,
        marginTop: '-13px',
        backgroundColor: 'none',
        position: 'relative',
        zIndex: '1000',
        width: '139px',
        paddingBottom: '0px'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    option: (base) => ({
        ...base,
        borderTop: '1px solid #D0DDEF',
        height: '100%',
        padding: '0px',
        paddingLeft: '6px',
        cursor: 'pointer',
        color: '#505D6F',
        '&:hover': {
            backgroundColor: '#e00c3c'
        }
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: state.selectProps.menuIsOpen ? '#e00c3c' : '#D8D8D8;',
        '&:hover': {
            color: state.selectProps.menuIsOpen ? '#e00c3c' : '#D8D8D8;'
        }
    })
}

export const customStyles7 = {
    container: (base) => ({
        ...base,
        width: '100%',
        height: '100%',
        borderBottom: '1px solid #d0ddef'
    }),
    control: (base) => ({
        ...base,
        background: 'unset',
        width: '100%',
        height: '100%',
        borderStyle: 'none',
        borderRadius: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        cursor: 'pointer',
        paddingBottom: 10,

        '&:hover': {
            background: 'unset'
        }
    }),
    valueContainer: (base) => ({
        ...base,
        height: '100%',
        padding: '8px',
        marginLeft: '-2px',
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '16px',
        lineHeight: '19px'
    }),
    singleValue: (base, state) => ({
        ...base,
        color: '#2E2382',
        opacity: state.isDisabled ? '.5' : '1'
    }),
    menu: (base) => ({
        ...base,
        marginTop: '0px',
        zIndex: '2000',
        boxShadow: '0 10px 24px 0 #f2f0ff'
    }),
    menuList: (base) => ({
        ...base,
        marginTop: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'none',
        position: 'relative',
        width: '100%'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    option: (base) => ({
        ...base,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderTop: '1px solid #D0DDEF',
        height: '48px',
        padding: '0px',
        paddingLeft: '10px',
        fontSize: window.innerWidth < 768 ? '16px' : '18px',
        lineHeight: window.innerWidth < 768 ? '19px' : '21px',
        cursor: 'pointer',
        color: window.innerWidth < 768 ? '#2E2382' : '#505D6F',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#e00c3c',
            svg: {
                ellipse: {
                    fill: '#fff'
                },
                path: {
                    fill: '#fff'
                },
                circle: {
                    fill: '#fff'
                }
            }
        }
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: state.selectProps.menuIsOpen ? '#D8D8D8' : '#D8D8D8',
        '&:hover': {
            color: state.selectProps.menuIsOpen ? '#D8D8D8' : '#D8D8D8'
        }
    })
}

export const customStyles8 = {
    container: (base) => ({
        ...base,
        width: '100%',
        height: '100%',
        borderBottom: '1px solid #d0ddef'
    }),
    control: (base) => ({
        ...base,
        background: 'unset',
        width: '100%',
        height: '100%',
        borderStyle: 'none',
        borderRadius: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        cursor: 'pointer',
        paddingBottom: 10,

        '&:hover': {
            background: 'unset'
        }
    }),
    valueContainer: (base) => ({
        ...base,
        height: '100%',
        padding: '0 14px',
        marginLeft: '-3px',
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '16px',
        lineHeight: '19px'
    }),
    singleValue: (base, state) => ({
        ...base,
        opacity: state.isDisabled ? '.5' : '1'
    }),
    menu: (base) => ({
        ...base,
        marginTop: '0px',
        zIndex: '2000',
        boxShadow: '0 10px 24px 0 #f2f0ff'
    }),
    menuList: (base) => ({
        ...base,
        marginTop: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'none',
        position: 'relative',
        width: '100%',
        height: window.innerWidth > 1280 ? '300px' : '200px'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    option: (base) => ({
        ...base,
        width: '100%',
        // minHeight: '48px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: window.innerWidth < 768 ? '16px' : '16px',
        lineHeight: window.innerWidth < 768 ? '19px' : '19px',
        color: window.innerWidth < 768 ? '#505D6F' : '#505D6F',
        borderTop: '1px solid #D0DDEF',
        padding: '16px 14px',
        cursor: 'pointer',

        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#e00c3c',
            svg: {
                ellipse: {
                    fill: '#fff'
                },
                path: {
                    fill: '#fff'
                },
                circle: {
                    fill: '#fff'
                }
            }
        }
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: state.selectProps.menuIsOpen ? '#D8D8D8' : '#D8D8D8',
        '&:hover': {
            color: state.selectProps.menuIsOpen ? '#D8D8D8' : '#D8D8D8'
        }
    })
}
