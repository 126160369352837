import React from 'react'

function IconCopyRow({ className, onClick }) {
    return (
        <svg
            className={className}
            onClick={onClick}
            width={20}
            height={20}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.6079 0H6.96608C6.19834 0 5.57393 0.624667 5.57393 1.39218V13.034C5.57393 13.8015 6.19834 14.4262 6.96608 14.4262H18.6079C19.3756 14.4262 20 13.8015 20 13.034V1.39218C20 0.624667 19.3756 0 18.6079 0ZM18.1842 12.6103H7.38979V1.81594H18.1842V12.6103ZM1.81585 18.1841H12.6102V15.1325H14.4261V18.6078C14.4261 19.3754 13.8017 20 13.0339 20H1.39215C0.62441 20 0 19.3754 0 18.6078V6.96603C0 6.19851 0.62441 5.57385 1.39215 5.57385H4.86768V7.38974H1.81585V18.1841Z'
                fill='#BECBDC'
            />
        </svg>
    )
}

export default IconCopyRow
