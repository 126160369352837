import React from 'react'

function IconDownLoad() {
    return (
        <svg width={29} height={28} viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M28.0291 10.3944C28.0291 6.8615 25.1112 3.97091 21.5448 3.97091C21.3091 3.97091 21.0733 3.97091 20.8375 4.00011C19.3048 1.51829 16.5933 0 13.5869 0C9.93221 0 6.74905 2.27743 5.57011 5.63519C2.50484 5.66438 0.0290527 8.1754 0.0290527 11.212C0.0290527 14.307 2.56379 16.818 5.688 16.818H5.77642C5.59958 17.4603 5.51116 18.1611 5.51116 18.8618C5.51116 23.5043 9.31326 27.3 14.0291 27.3C18.7448 27.3 22.5469 23.5335 22.5469 18.8618C22.5469 18.1319 22.4585 17.4311 22.2817 16.7888C25.4943 16.4092 28.0291 13.6938 28.0291 10.3944ZM14.0291 25.4313C10.3743 25.4313 7.39747 22.4824 7.39747 18.8618C7.39747 15.2413 10.3743 12.2923 14.0291 12.2923C17.6838 12.2923 20.6606 15.2413 20.6606 18.8618C20.6606 22.4824 17.6838 25.4313 14.0291 25.4313ZM14.0291 10.4236C17.3006 10.4236 20.1301 12.2631 21.5448 14.9493C24.0796 14.9493 26.1427 12.9055 26.1427 10.3652C26.1427 7.85422 24.0796 5.81037 21.5448 5.81037C20.1301 5.81037 18.8333 6.42353 17.9491 7.50385C17.6248 7.91262 17.0354 7.97102 16.6227 7.64984C16.2101 7.32866 16.1512 6.74471 16.4754 6.33594C17.1238 5.5184 17.9491 4.90524 18.8922 4.49647C17.6838 2.83219 15.7091 1.83947 13.5869 1.83947C10.5512 1.83947 7.928 3.91251 7.19116 6.8615C7.07326 7.32866 6.63116 7.62064 6.15958 7.56225L6.04168 7.53305C5.98274 7.53305 5.92379 7.52575 5.86484 7.51845C5.80589 7.51115 5.74695 7.50385 5.688 7.50385C3.59537 7.50385 1.91537 9.13893 1.91537 11.212C1.91537 13.285 3.62484 14.9493 5.688 14.9493H6.51326C7.928 12.2631 10.7575 10.4236 14.0291 10.4236Z'
                fill='white'
            />
            <path
                d='M17.1939 18.7242C17.045 18.5729 16.8365 18.5729 16.6876 18.7242L15.3471 20.0858V15.063C15.3471 14.8512 15.1982 14.7 14.9897 14.7H13.7386C13.5301 14.7 13.3812 14.8512 13.3812 15.063V20.0858L12.0408 18.7242C11.8918 18.5729 11.6833 18.5729 11.5344 18.7242L10.6408 19.632C10.4918 19.7832 10.4918 19.995 10.6408 20.1463L14.1259 23.6865C14.2748 23.8378 14.4833 23.8378 14.6322 23.6865L18.1174 20.1463C18.2663 19.995 18.2663 19.7832 18.1174 19.632L17.1939 18.7242Z'
                fill='white'
            />
        </svg>
    )
}

export default IconDownLoad
