import React from 'react'
import classes from './HistoryItem.module.scss'

function HistoryItem({ item, onCancelNewReplica, status, accessLevel }) {
    const { code } = status

    const isShowCancelBtn =
        !(code === 'for-posting' || code === 'under-moderation' || code === 'posted') &&
        (accessLevel === 1 || accessLevel === 4 || accessLevel === 5)

    return (
        <div className={classes.Item}>
            <div className={classes.Header}>
                {item.date && <p className={classes.Date}>{item.date}</p>}
                {item.author && <p className={classes.Author}>{item.author}</p>}
                {item.typeChange === 'replica' && <span className={classes.Label}>отредактировал реплику</span>}
                {item.typeChange === 'repair' && <span className={classes.Label}>восстановил реплику</span>}
            </div>

            {item.typeChange === 'created' && <div className={classes.TypeCreated}>Добавил реплику</div>}

            {item.typeChange === 'status' && (
                <div className={classes.TypeStatus}>
                    Изменил статус на
                    <div className={classes.Status} data-type={item.status.code}>
                        <div className={classes.StatusIcon} />

                        {item.status.name}
                    </div>
                </div>
            )}

            {(item.typeChange === 'replica' || item.typeChange === 'repair') && (
                <div className={classes.TypeReplica}>
                    <div className={classes.NewReplica}>{item?.newReplica}</div>
                    <div className={classes.SubTitle}>Реплика до редактирования</div>
                    <div className={classes.OldReplica}>{item?.oldReplica}</div>

                    {isShowCancelBtn && (
                        <button className={classes.BtnCancelReplica} onClick={() => onCancelNewReplica(item.id)}>
                            Отменить редактирование и восстановить
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}

export default HistoryItem
