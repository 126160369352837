import React from 'react'

function IconSelectMobail({ className }) {
    return (
        <svg className={className} width={21} height={17} viewBox='0 0 21 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M20.4999 2.37815C20.4999 3.18264 19.8478 3.83492 19.0432 3.83492H1.95677C1.15228 3.83492 0.5 3.18281 0.5 2.37815C0.5 1.57367 1.15211 0.921387 1.95677 0.921387H19.0436C19.8479 0.921555 20.5 1.57384 20.5 2.37815H20.4999Z'
                fill='white'
            />
            <path
                d='M18.0801 8.50022C18.0801 9.30471 17.428 9.95699 16.6234 9.95699H4.37571C3.57123 9.95699 2.91895 9.30488 2.91895 8.50022C2.91895 7.69574 3.57105 7.04346 4.37571 7.04346H16.6238C17.4281 7.04346 18.0802 7.69574 18.0802 8.50022H18.0801Z'
                fill='white'
            />
            <path
                d='M14.4519 14.6213C14.4519 15.4258 13.7998 16.0781 12.9951 16.0781H8.00559C7.20111 16.0781 6.54883 15.426 6.54883 14.6213C6.54883 13.8168 7.20094 13.1646 8.00559 13.1646H12.9955C13.7999 13.1646 14.452 13.8168 14.452 14.6213H14.4519Z'
                fill='white'
            />
        </svg>
    )
}

export default IconSelectMobail
