import React, { useState, useEffect, Fragment } from 'react'
import classes from './Row.module.scss'
import cn from 'classnames'
import IconCalendar from '../../../../icons/IconCalendar'
import RowInner from '../RowInner/RowInner'
import DeleteRow from '../../UI/DeleteRow/DeleteRow'
import AddRow from '../../UI/AddRow/AddRow'
import DateField from '../../UI/DateField/DateField'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import IconCopyRow from '../IconCopyRow'

const excludeFields = ['dateStart', 'dateEnd', 'sites']

function Row({
    item,
    num,
    isControl,
    isLast,
    onAddRow,
    onDeleteRow,
    onAddInnerRow,
    onDeleteInnerRow,
    onChangeFieldsInnerRow,
    titleColumns,
    onChangeFieldsRow,
    onSavePlan,
    prevDateEnd,
    isDisabledAddRow,
    typePlan,
    onCopyRow
}) {
    const { width } = useWindowSize()
    const [activeRow, setActiveRow] = useState(null)
    const [newInnerRow, setNewInnerRow] = useState(null)

    const [activeModalId, setActiveModalId] = useState(null)

    const onChangeActiveRow = (id) => {
        if (id === activeRow) {
            return setActiveRow(null)
        }

        setActiveRow(id)
    }

    useEffect(() => {
        if (width < 768) {
            const entries = Object.entries(item)
            const filteredEntries = entries.filter(([key, value]) => !excludeFields.includes(key))

            const newObj = filteredEntries.reduce((obj, [key, value]) => {
                obj.name = { value: 'Всего', isEdit: false }
                obj[key] = { value, isEdit: false }

                return obj
            }, {})

            setNewInnerRow(newObj)
        }
    }, [width, item])

    return (
        <>
            <div
                className={cn(classes.Item, { [classes.Active]: activeRow === item.id, [classes.Control]: isControl })}
                data-id={item.id}
            >
                <span className={classes.ItemNumber}>{num}.</span>

                <div className={classes.Columns}>
                    {!isControl && (
                        <div className={`${classes.ItemCol} ${classes.Date}`}>
                            <div className={classes.DateItem}>
                                <span>{item.dateStart.toLocaleDateString('ru-RU')}</span>
                                <IconCalendar />
                            </div>

                            <span className={classes.DateSeparator}>&mdash;</span>

                            <div className={classes.DateItem}>
                                <span>{item.dateEnd.toLocaleDateString('ru-RU')}</span>
                                <IconCalendar />
                            </div>
                        </div>
                    )}

                    {isControl && (
                        <div className={`${classes.ItemCol} ${classes.Date}`}>
                            <div className={classes.DateField}>
                                <DateField
                                    value={item.dateStart}
                                    onChangeFieldsRow={onChangeFieldsRow}
                                    rowId={item.id}
                                    name='dateStart'
                                    minDate={isLast ? prevDateEnd : null}
                                    typePlan={typePlan}
                                />
                            </div>

                            <span className={classes.DateSeparator}>&mdash;</span>

                            <div className={classes.DateField}>
                                <DateField
                                    value={item.dateEnd}
                                    onChangeFieldsRow={onChangeFieldsRow}
                                    rowId={item.id}
                                    name='dateEnd'
                                    minDate={item.dateStart}
                                    typePlan={typePlan}
                                />
                            </div>
                        </div>
                    )}

                    <div className={`${classes.ItemCol} ${classes.RemainsOld}`}>{item.remainsOld}</div>
                    <div className={`${classes.ItemCol} ${classes.Needed}`}>{item.needed}</div>
                    <div className={classes.ItemCol}>{item.remains}</div>
                    <div className={classes.ItemCol}>{item.used}</div>
                    <div className={classes.ItemCol}>{item.agreed}</div>
                    <div className={classes.ItemCol}>{item.posted}</div>
                </div>

                {isControl && isLast && <AddRow className={classes.AddRow} onClick={onAddRow} disabled={isDisabledAddRow} />}

                {isControl && <DeleteRow className={classes.DeleteRow} onClick={() => onDeleteRow(item.id, typePlan)} />}

                <div className={classes.ItemControl} onClick={() => onChangeActiveRow(item.id)}>
                    <span />
                    <span />
                    <span />
                </div>

                {isControl && <IconCopyRow className={classes.BtnCopyRow} onClick={() => onCopyRow(item.id)} />}
            </div>

            {item?.items?.length > 0 && (
                <div className={classes.ItemInner}>
                    {width < 768 && (
                        <div className={cn(classes.Header, { [classes.Control]: isControl })}>
                            {titleColumns.map((item, index) => (
                                <div className={classes.HeaderItem} key={index} dangerouslySetInnerHTML={{ __html: item }} />
                            ))}
                        </div>
                    )}
                    {newInnerRow &&
                        [newInnerRow].map((itemInner, index) => (
                            <RowInner
                                rowId={item.id}
                                item={itemInner}
                                key={index}
                                isControl={isControl}
                                activeModalId={activeModalId}
                                onActiveModalId={setActiveModalId}
                                isLast={item.items.length - 1 === index}
                                onAddInnerRow={onAddInnerRow}
                                onDeleteInnerRow={onDeleteInnerRow}
                                onChangeFieldsInnerRow={onChangeFieldsInnerRow}
                                onSavePlan={onSavePlan}
                                typePlan={typePlan}
                            />
                        ))}
                    {item.items.map((itemInner, index) => {
                        return (
                            <Fragment key={`inner-${index}`}>
                                <RowInner
                                    rowId={item.id}
                                    rowInnerId={itemInner.id}
                                    item={itemInner}
                                    activeModalId={activeModalId}
                                    onActiveModalId={setActiveModalId}
                                    isControl={isControl}
                                    isLast={item.items.length - 1 === index}
                                    onAddInnerRow={onAddInnerRow}
                                    onDeleteInnerRow={onDeleteInnerRow}
                                    onChangeFieldsInnerRow={onChangeFieldsInnerRow}
                                    onSavePlan={onSavePlan}
                                    isDisabled={itemInner?.items?.length > 0}
                                    typePlan={typePlan}
                                />
                                {itemInner?.items?.length > 0 &&
                                    itemInner.items.map((itemInnerLvlTwo, indexInnerLvlTwo) => {
                                        return (
                                            <RowInner
                                                // rowId={item.id} // ????
                                                rowId={item.id}
                                                rowInnerId={itemInner.id}
                                                item={itemInnerLvlTwo}
                                                activeModalId={activeModalId}
                                                onActiveModalId={setActiveModalId}
                                                key={`inner-lvlTwo-${indexInnerLvlTwo}`}
                                                isControl={isControl}
                                                isLast={item.items.length - 1 === indexInnerLvlTwo}
                                                onAddInnerRow={onAddInnerRow}
                                                onDeleteInnerRow={onDeleteInnerRow}
                                                onChangeFieldsInnerRow={onChangeFieldsInnerRow}
                                                onSavePlan={onSavePlan}
                                                isInnerLvlTwo
                                                typePlan={typePlan}
                                            />
                                        )
                                    })}
                            </Fragment>
                        )
                    })}
                </div>
            )}
        </>
    )
}

export default Row
