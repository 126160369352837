import React from 'react'

function IconCopyLink({ className }) {
    return (
        <svg className={className} width={16} height={17} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.6734 2.83738C9.90039 2.83738 9.17587 3.12719 8.62017 3.68289L7.41242 4.89064C7.0259 5.27716 6.42202 5.27716 6.03549 4.89064C5.64897 4.50412 5.64897 3.90024 6.03549 3.51372L7.21913 2.30596C8.13707 1.38803 9.34482 0.880859 10.625 0.880859C11.9053 0.880859 13.1371 1.3882 14.0309 2.30596C14.9489 3.2239 15.4561 4.43165 15.4561 5.71187C15.4561 6.99209 14.9487 8.22391 14.0309 9.11778L12.8473 10.3255C12.654 10.5188 12.4126 10.6153 12.171 10.6153C11.9293 10.6153 11.6879 10.5188 11.4946 10.3255C11.1081 9.93901 11.1081 9.33513 11.4946 8.94861L12.7024 7.74086C13.2579 7.18533 13.5479 6.46064 13.5479 5.68759C13.5479 4.91454 13.2581 4.19002 12.7024 3.63432C12.171 3.12715 11.4463 2.83733 10.6734 2.83733L10.6734 2.83738ZM2.99212 6.58141C3.37864 6.19489 3.98252 6.19489 4.36904 6.58141C4.75556 6.96793 4.75556 7.57181 4.36904 7.95833L3.1854 9.14197C2.62988 9.6975 2.33989 10.4222 2.33989 11.1952C2.33989 11.9683 2.6297 12.6928 3.1854 13.2485C3.74093 13.804 4.46562 14.094 5.23867 14.094C6.01172 14.094 6.73624 13.8042 7.29194 13.2485L8.49969 12.0649C8.88621 11.6783 9.49009 11.6783 9.87662 12.0649C10.2631 12.4514 10.2631 13.0553 9.87662 13.4418L8.69297 14.6254C7.77504 15.5434 6.56728 16.0505 5.28707 16.0505C4.00685 16.0505 2.77503 15.5432 1.88116 14.6254C0.963223 13.7075 0.456054 12.4997 0.456054 11.2195C0.456054 9.93931 0.963395 8.70748 1.88116 7.81362L2.99212 6.58141ZM5.72165 10.6878C5.91494 10.8811 6.15638 10.9776 6.398 10.9776C6.6637 10.9776 6.90532 10.8811 7.09858 10.688L10.1663 7.62026C10.5528 7.23374 10.5528 6.62986 10.1663 6.24334C9.77979 5.85682 9.17591 5.85682 8.78939 6.24334L5.72165 9.3109C5.33514 9.69743 5.33514 10.3013 5.72165 10.6878Z'
                fill='#505D6F'
            />
        </svg>
    )
}

export default IconCopyLink
