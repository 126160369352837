import React from 'react'

function IconHidden() {
    return (
        <svg width={24} height={20} viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M21.0253 19.0245C21.2593 18.7895 21.2593 18.4095 21.0253 18.1755L17.3483 14.4985C17.3373 14.4865 17.3263 14.4755 17.3153 14.4655L12.0233 9.1755L9.90325 7.0555C9.90219 7.05497 9.9014 7.05443 9.9006 7.05375L9.89825 7.0515L3.02125 0.1755C2.78625 -0.0585 2.40525 -0.0585 2.17325 0.1755C1.93925 0.4095 1.93925 0.7895 2.17325 1.0235L5.27125 4.1205C3.23125 5.2055 1.50625 6.7865 0.250251 8.7365C-0.0837494 9.2605 -0.0837494 9.9375 0.252251 10.4645C2.75525 14.3375 6.99625 16.6495 11.5993 16.6495C13.3893 16.6495 15.1283 16.3045 16.7743 15.6225L20.1772 19.0245C20.2943 19.1415 20.4482 19.2005 20.6012 19.2005C20.7542 19.2005 20.9093 19.1415 21.0253 19.0245ZM19.7733 13.8875C21.0273 12.9395 22.0953 11.7875 22.9482 10.4615C23.2823 9.9375 23.2823 9.2615 22.9463 8.7345C20.4433 4.8615 16.2023 2.5495 11.5993 2.5495C10.8183 2.5495 10.0253 2.6205 9.24325 2.7585C8.91625 2.8165 8.70025 3.1285 8.75725 3.4545C8.81625 3.7815 9.12425 3.9975 9.45325 3.9405C10.1653 3.8135 10.8883 3.7495 11.5993 3.7495C15.7923 3.7495 19.6583 5.8565 21.9363 9.3825C22.0212 9.5165 22.0213 9.6835 21.9373 9.8155C21.1623 11.0195 20.1903 12.0685 19.0483 12.9315C18.7853 13.1305 18.7323 13.5075 18.9323 13.7715C19.0513 13.9275 19.2293 14.0095 19.4113 14.0095C19.5383 14.0095 19.6653 13.9695 19.7733 13.8875ZM9.19825 9.5995C9.19825 10.9225 10.2753 11.9995 11.5993 11.9995C12.0353 11.9995 12.4523 11.8835 12.8183 11.6665L9.53225 8.3805C9.31425 8.7465 9.19825 9.1645 9.19825 9.5995ZM13.6842 12.5325L15.8483 14.6955C14.4883 15.1965 13.0623 15.4495 11.5993 15.4495C7.40625 15.4495 3.54025 13.3425 1.26225 9.8175C1.17725 9.6825 1.17725 9.5155 1.26025 9.3845C2.47125 7.5065 4.16025 6.0025 6.16325 5.0125L8.66525 7.5145C8.23225 8.1205 7.99825 8.8425 7.99825 9.5995C7.99825 11.5845 9.61425 13.1995 11.5993 13.1995C12.3573 13.1995 13.0793 12.9655 13.6842 12.5325Z'
                fill='white'
            />
        </svg>
    )
}

export default IconHidden
