import classes from './CustomTile.module.scss'
import React from 'react'
import ListMedia from '../../ListMedia/ListMedia'
import { TitleCard } from '../../TitleCard/TitleCard'
import InputEditText from '../../InputEditText/InputEditText'

export const CustomTile = ({
                               onChangeStatusEdit,
                               activeEdit,
                               name,
                               description,
                               documents,
                               onChange,
                               onBlur,
                               onRemoveFile
                           }) => {

    return (
        <>
            {activeEdit === 'name' ?

                <InputEditText
                    id={'name'}
                    min={3}
                    max={100}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={name}
                    style={{
                        fontWeight: 600,
                        fontSize: 24,
                        color: '#2E2382',
                        textDecoration: 'none',
                        border: 'none',
                        overflow: 'none',
                        outline: 'none',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        resize: 'none'
                    }} /> :
                <TitleCard title={name} onClick={onChangeStatusEdit} id={'name'} />}


            <br></br>


            {![...description].every((item) =>  ["", " ", "\n"].includes(item)) || activeEdit === 'description' ?  null : <p onClick={() => onChangeStatusEdit('description')} style={{
                fontWeight: 400,
                fontSize: 16,
                color: '#94A2B6',
            }}>Редактировать описание</p> }

            {activeEdit === 'description' ?
                <InputEditText
                    id={'description'}
                    min={0}
                    max={255}
                    onChange={onChange}
                    style={{
                        width: '100%',
                        cursor: 'pointer',
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: 1.27,
                        color: '#94A2B6',
                        textDecoration: 'none',
                        border: 'none',
                        outline: 'none',
                        overflow: 'auto',
                        boxShadow: 'none',
                        resize: 'none',
                    }}
                    value={description}
                    onBlur={onBlur}
                />  : <a
                    onClick={() => onChangeStatusEdit('description')}
                    className={classes.Description}>{description}</a>}

            <ListMedia data={documents} onRemoveFile={onRemoveFile} />
        </>
    )
}
