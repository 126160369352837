import React, { useContext } from 'react'
import classes from './ModalProject.module.scss'
import cn from 'classnames'
import MobailModal from '../../../UI/MobailModal/MobailModal'
import { AnaliseContext } from '../../../../../context/analise'
import IconCheckedBig from '../../../icons/IconCheckedBig'

function ModalProject({ controlModalChildren }) {
    const { state, actions } = useContext(AnaliseContext)

    const optionsProects = state.projects.map(({ id, name }) => {
        return { value: id, label: name }
    })

    return (
        <MobailModal title='Выбор проекта' controlModal={controlModalChildren} isIcon>
            <div className={classes.Wrap}>
                {optionsProects.length > 0 ? (
                    optionsProects.map((item, index) => (
                        <button
                            className={cn(classes.Btn, { [classes.Active]: item.value === state.filter.project?.value })}
                            key={index}
                            onClick={() => {
                                actions.onChangeFilter('project', item)
                                controlModalChildren.onCloseModal()
                            }}
                        >
                            {item.label}
                            <IconCheckedBig />
                        </button>
                    ))
                ) : (
                    <div className={classes.Empty}>Список пуст</div>
                )}
            </div>
        </MobailModal>
    )
}

export default ModalProject
