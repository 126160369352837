import React, { useState } from 'react'
import cn from 'classnames'
import classes from './UrlDropDown.module.scss'
import IconCopy from './IconCopy'
import IconLink from './IconLink'
import IconClose from './IconClose'

function isValidURL(str) {
    var a = document.createElement('a')
    a.href = str
    return a.host && a.host !== window.location.host
}

function UrlDropDown({ href, isOpen, onClose }) {
    const [isEdit, setIsEdit] = useState(false)
    const [newUrl, setNewUrl] = useState('')

    const onChangeIsEdit = (value) => {
        setIsEdit(value)
    }

    const onChangeNewUrl = (e) => {
        setNewUrl(e.currentTarget.value)
    }

    return (
        <div className={cn(classes.Wrapper, { [classes.Open]: isOpen })}>
            <div className={classes.Inner}>
                {!isEdit ? (
                    <div className={cn(classes.Field, { [classes.IsEdit]: isEdit })}>
                        <span className={classes.Href}>{href}</span>
                    </div>
                ) : (
                    <input className={cn(classes.Field, { [classes.IsEdit]: isEdit })} value={newUrl} onChange={onChangeNewUrl} />
                )}

                {!isEdit && (
                    <div className={classes.Control}>
                        <button
                            className={cn(classes.BtnCopy, { [classes.Disabled]: !isValidURL(href) })}
                            onClick={() => {
                                navigator.clipboard.writeText(href)
                                onClose()
                            }}
                        >
                            <IconCopy />
                            Копировать
                        </button>

                        <a
                            className={cn(classes.BtnGoToLink, { [classes.Disabled]: !isValidURL(href) })}
                            href={href}
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={onClose}
                        >
                            <IconLink />
                            Перейти
                        </a>
                    </div>
                )}

                <button className={classes.Close} onClick={onClose}>
                    <IconClose />
                </button>
            </div>
        </div>
    )
}

export default UrlDropDown
