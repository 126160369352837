import React from 'react'

function IconLeft() {
    return (
        <svg width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 6.48468L3.55727 7.5L0.695329 4.41765C0.570267 4.28306 0.5 4.10045 0.5 3.91003C0.5 3.71962 0.570267 3.53701 0.695329 3.40241L3.55727 0.5L4.5 1.51532L2.1094 3.91003L4.5 6.48468Z" fill="#505D6F" />
        </svg>
    )
}

export default IconLeft
