import React from 'react'
import classes from '../Tile/Tile.module.scss'
import config from '../../../config/config'

export default ({ data, onRemoveFile }) => {


   return  data && data.length > 0 &&
    <div style={{ marginTop: 24 }}>
        {data.map((doc) =>{
            let url = doc.file.url
            url = (url ? config.SERVER_BASE_URL + '/' + url : '')

            return (<div key={doc.file?.name}
                 style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginTop: 12 }}>
                <a href={url} style={{ color: 'blue', fontWeight: 'bold', cursor: 'pointer', fontSize: 18 }}>
                    — {doc.file?.name}
                </a>
                <span className={classes.iconRemoveFile} onClick={() => onRemoveFile(doc.file?.name)}> </span>

            </div>)}
        )}
    </div>
}
