import React, {useContext, useState} from "react"
import "./ProjectDelete.scss"
import {apiProjectDelete} from "../../../../../api/projects"
import Input from "../../../../UI/Input/Input"
import {PopupContext} from "../../../../../context/popup/popupContext"

const ProjectDelete = props => {
    const popupContextData = useContext(PopupContext)
    const [msg, setMsg] = useState('')
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)

    const projectDelete = async (event, projectId) => {
        event.preventDefault()

        if (!msg || msg.length < 5) {
            alert('Укажите причину удаления')
        } else {
            popupContextData.setPreloader(true)
            const data = await apiProjectDelete(projectId, msg)

            if (data.status === 'fail') {
                popupContextData.setPreloader(false)
                alert(data.errorText)
            } else {
                props.close()
                props.getProjects()
            }
        }
    }

    return (
        <div className="admin-company__project-manipulation admin-company__project-manipulation_removing">
            <div className="admin-company__project-manipulation-top-side">
                <div className="admin-company__project-manipulation-top-side-name">Удаление проекта</div>

                <div
                    className="admin-company__project-manipulation-top-side-button"
                    onClick={props.close}
                />
            </div>

            {!openDeleteConfirm ? (
                <div className="admin-company__project-manipulation-content">
                    <div className="admin-company__project-manipulation-text">Вы действительно хотите удалить проект?</div>

                    <div className="admin-company__project-manipulation-buttons">
                        <div className="admin-company__project-manipulation-button admin-company__project-manipulation-button_mobile-width-71px admin-company__project-manipulation-button_blue">
                            <button onClick={props.close}>Нет</button>
                        </div>

                        <div className="admin-company__project-manipulation-button admin-company__project-manipulation-button_mobile-width-71px admin-company__project-manipulation-button_red">
                            <button onClick={() => setOpenDeleteConfirm(true)}>Да</button>
                        </div>
                    </div>
                </div>
            ) : null}

            {openDeleteConfirm ? (
                <div className="admin-company__project-manipulation-reason-content">
                    <div className="admin-company__project-manipulation-reason-text">
                        <span>Укажите причину!</span><br /> Без указания причины проект не может быть удален!
                    </div>

                    <div className="admin-company__project-manipulation-reason-field">
                        <Input
                            type="text"
                            name="msg"
                            value={msg}
                            onChange={(event) => setMsg(event.target.value)}
                            className="admin-company__project-manipulation-reason-input"
                        />

                        <button
                            className="admin-company__project-manipulation-reason-button"
                            onClick={(event) => projectDelete(event, props.id)}
                        >Подтвердить удаление</button>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default ProjectDelete
