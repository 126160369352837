import React from 'react'

function IconGoogle() {
    return (
        <svg width={30} height={31} viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <mask id='mask0_0_7608' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x={0} y={0} width={30} height={31}>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M29.6512 12.6818H15.3488V18.6705H23.5814C22.814 22.475 19.6047 24.6591 15.3488 24.6591C10.3256 24.6591 6.27907 20.5727 6.27907 15.5C6.27907 10.4273 10.3256 6.34091 15.3488 6.34091C17.5116 6.34091 19.4651 7.11591 21 8.38409L25.4651 3.875C22.7442 1.47955 19.2558 0 15.3488 0C6.83721 0 0 6.90455 0 15.5C0 24.0955 6.83721 31 15.3488 31C23.0233 31 30 25.3636 30 15.5C30 14.5841 29.8605 13.5977 29.6512 12.6818Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask0_0_7608)'>
                <path d='M-1.39526 24.6591V6.34088L10.4652 15.5L-1.39526 24.6591Z' fill='#FBBC05' />
            </g>
            <mask id='mask1_0_7608' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x={0} y={0} width={30} height={31}>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M29.6512 12.6818H15.3488V18.6705H23.5814C22.814 22.475 19.6047 24.6591 15.3488 24.6591C10.3256 24.6591 6.27907 20.5727 6.27907 15.5C6.27907 10.4273 10.3256 6.34091 15.3488 6.34091C17.5116 6.34091 19.4651 7.11591 21 8.38409L25.4651 3.875C22.7442 1.47955 19.2558 0 15.3488 0C6.83721 0 0 6.90455 0 15.5C0 24.0955 6.83721 31 15.3488 31C23.0233 31 30 25.3636 30 15.5C30 14.5841 29.8605 13.5977 29.6512 12.6818Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask1_0_7608)'>
                <path
                    d='M-1.39526 6.34088L10.4652 15.5L15.3489 11.2022L32.0931 8.45452V-1.40912H-1.39526V6.34088Z'
                    fill='#EA4335'
                />
            </g>
            <mask id='mask2_0_7608' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x={0} y={0} width={30} height={31}>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M29.6512 12.6818H15.3488V18.6705H23.5814C22.814 22.475 19.6047 24.6591 15.3488 24.6591C10.3256 24.6591 6.27907 20.5727 6.27907 15.5C6.27907 10.4273 10.3256 6.34091 15.3488 6.34091C17.5116 6.34091 19.4651 7.11591 21 8.38409L25.4651 3.875C22.7442 1.47955 19.2558 0 15.3488 0C6.83721 0 0 6.90455 0 15.5C0 24.0955 6.83721 31 15.3488 31C23.0233 31 30 25.3636 30 15.5C30 14.5841 29.8605 13.5977 29.6512 12.6818Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask2_0_7608)'>
                <path
                    d='M-1.39526 24.6591L19.535 8.45452L25.0466 9.15906L32.0931 -1.40912V32.4091H-1.39526V24.6591Z'
                    fill='#34A853'
                />
            </g>
            <mask id='mask3_0_7608' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x={0} y={0} width={30} height={31}>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M29.6512 12.6818H15.3488V18.6705H23.5814C22.814 22.475 19.6047 24.6591 15.3488 24.6591C10.3256 24.6591 6.27907 20.5727 6.27907 15.5C6.27907 10.4273 10.3256 6.34091 15.3488 6.34091C17.5116 6.34091 19.4651 7.11591 21 8.38409L25.4651 3.875C22.7442 1.47955 19.2558 0 15.3488 0C6.83721 0 0 6.90455 0 15.5C0 24.0955 6.83721 31 15.3488 31C23.0233 31 30 25.3636 30 15.5C30 14.5841 29.8605 13.5977 29.6512 12.6818Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask3_0_7608)'>
                <path d='M32.0929 32.4091L10.465 15.5L7.67432 13.3863L32.0929 6.34088V32.4091Z' fill='#4285F4' />
            </g>
        </svg>
    )
}

export default IconGoogle
