import React from 'react'

function IconSend({ className }) {
    return (
        <svg className={className} width={21} height={19} viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                id='Path'
                d='M10.8817 9.99745L2.30842 10.7619L0.0519517 17.4094C-0.0827453 17.8024 0.049704 18.2315 0.388692 18.5004C0.72768 18.7693 1.20799 18.8263 1.60984 18.6452L20.3996 10.2633C20.7669 10.0982 21 9.75461 21 9.37802C21 9.00143 20.7669 8.6578 20.3996 8.4927L1.62295 0.104796C1.22111 -0.0762795 0.740799 -0.0193334 0.401811 0.249577C0.0628231 0.518486 -0.0696263 0.947625 0.0650707 1.34062L2.32154 7.9881L10.8784 8.75255C11.2241 8.78405 11.4876 9.05194 11.4876 9.37198C11.4876 9.69202 11.2241 9.95991 10.8784 9.9914L10.8817 9.99745Z'
                fill='white'
            />
        </svg>
    )
}

export default IconSend
