import React from 'react'
import './RightReserved.scss'

const RightReserved = (props) => {
    const cls = ['rights-reserved']

    const currentYearJSX = new Date().getFullYear()

    if (props.classes && props.classes.length) {
        props.classes.map((className) => {
            return cls.push(className)
        })
    }

    return (
        <div className={cls.join(' ')}>
            &copy; 2020 - {currentYearJSX} Все права защищены. Сервис предназначен исключительно для решения целей и задач
            клиентов компании
        </div>
    )
}

export default RightReserved
