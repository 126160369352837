import React, { useContext } from 'react'
import { AnaliseContext } from '../../../../context/analise'
import DateField from '../../UI/DateField/DateField'
import classes from './Calendar.module.scss'

function Calendar() {
    const { state, actions } = useContext(AnaliseContext)

    return (
        <div className={classes.Wrap}>
            <div className={classes.Date}>
                <DateField
                    value={state.dateFrom}
                    onChange={(name, date) => actions.onChangeState('dateFrom', date.toLocaleDateString())}
                    name='dateFrom'
                />

                <span className={classes.Separator}>&#8212;</span>

                <DateField
                    value={state.dateTo}
                    onChange={(name, date) => actions.onChangeState('dateTo', date.toLocaleDateString())}
                    name='dateTo'
                />
            </div>
        </div>
    )
}

export default Calendar
