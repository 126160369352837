import React, {useState} from "react"
import "./UserItem.scss"
import {apiUsersInProjectEdit} from "../../../../../api/users-in-project"
import UserDelete from "../UserDelete/UserDelete"

const UserItem = props => {
    const [linkDelete, setLinkDelete] = useState(false)
    const cls = ['admin-company__user-item']
    const accessLevel = localStorage.getItem('accessLevel')

    if (props.active) {
        cls.push('admin-company__user-item_active')
    } else {
        cls.push('admin-company__user-item_not-active')
    }

    const changeActive = async (linkId, active) => {
        const data = await apiUsersInProjectEdit(linkId, !active)

        if (data.status === 'fail') {
            alert(data.errorText)
        } else {
            props.getLinks()
        }
    }

    return (
        <div className={cls.join(' ')}>
            <div className="admin-company__user-item-bar">
                <div className="admin-company__user-item-name">{props.userLastName}<span>&nbsp;</span>{props.userName}</div>
                <div className="admin-company__user-item-position">{props.userRole}</div>

                {accessLevel === '1' ? (
                    <div
                        className="admin-company__user-item-link"
                        onClick={() => setLinkDelete(true)}
                    />
                ) : null}

                <div className="admin-company__user-item-status">{props.active ? 'Активен' : 'Не активен'}</div>

                <div
                    className="admin-company__user-item-switcher"
                    onClick={() => changeActive(props.id, props.active)}
                />
            </div>

            {linkDelete ?
                <UserDelete
                    id={props.id}
                    userName={props.userName}
                    userLastName={props.userLastName}
                    userRole={props.userRole}
                    close={() => setLinkDelete(false)}
                    getLinks={props.getLinks}
                />
            : null}
        </div>
    )
}

export default UserItem