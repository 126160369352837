import classes from './EditButton.module.scss'
import React from 'react'
import { useWindowSize } from '../../../hooks/useWindowSize'

export const EditButton = ({ onClick }) => {
    const {width} = useWindowSize()

    return (
        <button
            onClick={onClick}
            className={classes.EditButton}
        >

                            {width && width > 767 && (
                                <>Добавить папку</>
                            )}
                            {width && width <= 767 && (
                                <>Добавить папку</>
                            )}

        </button>
    )
}
