import React, { useState } from 'react'
import classes from './List.module.scss'
import cn from 'classnames'
import IconArrowDown from '../../../icons/IconArrowDown'
import IconChevronDown from '../../../icons/IconChevronDown'
import UrlDropDown from './UrlDropDown/UrlDropDown'
import Collapsible from 'react-collapsible'
import RowInner from './RowInner/RowInner'
import axios from 'axios'
import config from '../../../../../config/config'

function List({ list, data, onGetData }) {
    const [isLoading, setIsLoading] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [newComment, setNewComment] = useState('')

    const [activeTab, setActiveTab] = useState(null)

    const onChangeNewComment = (e) => {
        setNewComment(e.currentTarget.value)
    }

    const onChangeActiveTab = (num) => {
        if (num === activeTab) {
            return setActiveTab(null)
        }

        setNewComment('')
        setActiveTab(num)
    }

    const onChangeSelectedRow = (id) => {
        setSelectedRow(id)
    }

    const onAddComment = async (idRow) => {
        try {
            setIsLoading(true)

            const token = localStorage.getItem('token')

            const { data } = await axios.post(`${config.SERVER_BASE_URL}/analysis/rating/comments/${idRow}`, {
                token: token,
                msg: newComment
            })

            setIsLoading(false)

            if (data?.status === 'fail') {
                alert(data?.errorText?.split(',')?.join('\n'))
                return
            }

            if (data?.status === 'success') {
                setNewComment('')
                onGetData()
            }
        } catch (err) {
            console.log('onAddComment', err)
            setIsLoading(false)
        }
    }

    const onDeleteComment = async (idComment) => {
        try {
            setIsLoading(true)

            const token = localStorage.getItem('token')

            const { data } = await axios.delete(`${config.SERVER_BASE_URL}/analysis/rating/comments/${idComment}`, { data: { token } })

            setIsLoading(false)

            if (data?.status === 'fail') {
                alert(data?.errorText?.split(',')?.join('\n'))
                return
            }

            if (data?.status === 'success') {
                onGetData()
            }
        } catch (err) {
            console.log('onDeleteComment', err)
            setIsLoading(false)
        }
    }

    return (
        <div className={classes.Wrap}>
            <div className={classes.TableHeader}>
                <div className={classes.TableCol} data-type='url'>
                    URL
                </div>

                <div className={classes.TableCol} data-type='type'>
                    Тип площадки
                </div>

                <div className={classes.TableCol} data-type='quantity'>
                    Количество
                </div>

                <div className={classes.TableCol} data-type='rating'>
                    Рейтинг
                </div>
            </div>

            <div className={classes.TableList}>
                {list.map((item, index) => (
                    <Collapsible
                        key={index}
                        open={activeTab === index + 1}
                        triggerTagName='div'
                        transitionTime={500}
                        transitionCloseTime={500}
                        easing='ease'
                        accordionPosition={index + 1}
                        handleTriggerClick={(e) => onChangeActiveTab(index + 1)}
                        className={cn(classes.Item)}
                        openedClassName={cn(classes.Item, classes.Active)}
                        trigger={
                            <>
                                <div className={classes.TableRow} data-tonality={item.info.tonalityCode}>
                                    <UrlDropDown
                                        isOpen={item.id === selectedRow}
                                        href={item.urlFull}
                                        onClose={(e) => {
                                            e.stopPropagation()
                                            setSelectedRow(null)
                                        }}
                                    />

                                    <div className={classes.TableCol} data-type='url'>
                                        <span
                                            className={classes.RowUrl}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                onChangeSelectedRow(item.id)
                                            }}
                                        >
                                            {item?.url}
                                        </span>
                                    </div>

                                    <div className={classes.TableCol} data-type='type'>
                                        <p className={classes.RowType}>{item.type}</p>
                                    </div>

                                    <div className={classes.TableCol} data-type='quantity'>
                                        <span className={classes.RowQuantity}>{item.quantity}</span>
                                    </div>

                                    <div className={classes.TableCol} data-type='rating'>
                                        <span className={classes.RowRating} data-color={item.ratingChange}>
                                            {item?.rating}
                                        </span>
                                    </div>

                                    <button className={classes.RowBtn}>
                                        <IconChevronDown />
                                    </button>
                                </div>
                            </>
                        }
                    >
                        <RowInner
                            item={item}
                            newComment={newComment}
                            onChangeNewComment={onChangeNewComment}
                            onAddComment={onAddComment}
                            onDeleteComment={onDeleteComment}
                        />
                    </Collapsible>
                ))}
            </div>

            <div className={classes.Average}>
                <span className={classes.AverageTitle}>Среднее значение:</span>

                <div className={classes.AverageValue} data-position={data.ratingOld > data.rating ? 'down' : 'up'}>
                    {data.ratingOld && <IconArrowDown />}
                    {data?.rating}
                </div>
            </div>
        </div>
    )
}

export default List
