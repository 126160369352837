import React, { useContext } from 'react'
import classes from './ModalSystem.module.scss'
import cn from 'classnames'
import MobailModal from '../../../UI/MobailModal/MobailModal'
import IconGoogle from '../../../icons/IconGoogle'
import IconYandex from '../../../icons/IconYandex'
import { AnaliseContext } from '../../../../../context/analise'
import IconCheckedBig from '../../../icons/IconCheckedBig'

function ModalSystem({ controlModalChildren }) {
    const { state, actions } = useContext(AnaliseContext)

    return (
        <MobailModal title='Поисковая система' controlModal={controlModalChildren} isIcon>
            <div className={classes.Wrap}>
                {state.activeTabScreen === 'tonality' && (
                    <button
                        className={cn(classes.Btn, { [classes.Active]: state.filter?.searchSystem === 'all' })}
                        onClick={() => {
                            actions.onChangeFilter('searchSystem', 'all')
                            controlModalChildren.onCloseModal()
                        }}
                    >
                        Все
                        <IconCheckedBig className={classes.IconCheckedBig} />
                    </button>
                )}

                <button
                    className={cn(classes.Btn, { [classes.Active]: state.filter?.searchSystem === 'google' })}
                    onClick={() => {
                        actions.onChangeFilter('searchSystem', 'google')
                        controlModalChildren.onCloseModal()
                    }}
                >
                    <div className={classes.Icon}>
                        <IconGoogle />
                    </div>
                    Google
                    <IconCheckedBig className={classes.IconCheckedBig} />
                </button>

                <button
                    className={cn(classes.Btn, { [classes.Active]: state.filter?.searchSystem === 'yandex' })}
                    onClick={() => {
                        actions.onChangeFilter('searchSystem', 'yandex')
                        controlModalChildren.onCloseModal()
                    }}
                >
                    <div className={classes.Icon}>
                        <IconYandex />
                    </div>
                    Яндекс
                    <IconCheckedBig className={classes.IconCheckedBig} />
                </button>
            </div>
        </MobailModal>
    )
}

export default ModalSystem
