import React, { useEffect, useState } from 'react'
import classes from './TonalBlockChart.module.scss'
import { PieChart, Pie, Cell } from 'recharts'
import './charttets.css'

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const radian = Math.PI / 180
    const x = cx + radius * Math.cos(-midAngle * radian)
    const y = cy + radius * Math.sin(-midAngle * radian)

    const value = (percent * 100).toFixed(0)

    return (
        <>
            {value > 0 && (
                <text x={x} y={y} fill='white' textAnchor='middle' dominantBaseline='central'>
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            )}
        </>
    )
}

function TonalBlockChart({ data, width, height, cx, cy, innerRadius, outerRadius }) {
    const colors = ['#119E00', '#DF0C3C', '#505D6F', '#FF6400']

    const [result, setResult] = useState([])

    useEffect(() => {
        if (data?.length === 1) {
            const arr = Object.entries(data?.[0])
            const filteredArr = arr.filter(([key]) => key !== 'date' && key !== 'all')

            const arrResult = filteredArr.map(([key, value]) => {
                return { [key]: key, value: value }
            })

            setResult(arrResult)
        }
    }, [data])

    return (
        <div className={classes.Circle}>
            <PieChart width={width} height={height}>
                <Pie
                    data={result}
                    cx={cx}
                    cy={cy}
                    innerRadius={100}
                    outerRadius={150}
                    fill='#8884d8'
                    dataKey='value'
                    label={renderCustomizedLabel}
                    isAnimationActive={false}
                >
                    {result.map((item, index) => {
                        return <Cell key={`cell-${index}`} fill={colors[index]} />
                    })}
                </Pie>
            </PieChart>
        </div>
    )
}

export default TonalBlockChart
