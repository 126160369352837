import React, { useState } from 'react'
import classes from './AddCustomTile.module.scss'
import { IconPlus } from '../../../../icons/IconPlus'
import cn from 'classnames'
import { TextArea } from '../../Tile/Fields/TextArea/TextArea'
import { FileField } from '../../Tile/Fields/FileField/FileField'
import { TextField } from '../../Tile/Fields/TextField/TextField'

export const AddCustomTile = ({isCreatedFolder, onCancelCreatedFolder,  onSuccessCreatedFolder}) => {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [documents, setDocuments] = useState([])


    console.log('Доки', JSON.stringify(documents))
    // Выбор нескольких файлов
    const onSelectDocuments = (e) => {
        const media = e.target.files[0];

        setDocuments([({
            name: '',
            file: {
                size: media.size,
                name: media.name,
                type: media.type,
                file: media,
                path: '',
                id: ''
            }
        })])
    }

    const onRemoveDocument = (name) => {
       const newDocuments = documents.filter((doc) => doc.file.name !== name)
       setDocuments([...newDocuments])
    }

    const createDir = async () => {
        if (name.length < 3 || name.length > 255) {
            alert('Название должно быть не меньше 3 символов и не больше 255.')
            return;
        }


        if ([...name].every((item) =>  [" ", "\n"].includes(item) )) {
            alert('Введите называние папки')
            return;
        }


        if (description.length > 1) {
            if (description.length <= 5) {
                alert("Описание должно превышать 5 символов")
                return
            }


            // Проверяем, есть ли хотя бы один символ != пробелу
            if ([...description].every((item) =>  [" ", "\n"].includes(item) )) {
                setDescription('');
                alert("Введите корректное описание")
                return
            }
        }


       await onSuccessCreatedFolder({
            name,
            description,
            documents
        })

        setName('')
        setDescription('')
        setDocuments([])


    }

    const clickCancel = () => {
        setName('')
        setDescription('')
        setDocuments([])
        onCancelCreatedFolder();
    }


    return (
        <div
            className={cn(classes.Tile, {[classes.Active]: isCreatedFolder})}
        >
                <>
                    <div className={classes.Header}>
                        <IconPlus className={classes.Icon} />
                        <div className={classes.Text}>Новая папка</div>
                    </div>
                    <div className={classes.Body}>
                        <div className={classes.FolderIcon}></div>

                        <TextField
                            fullWidth={true}
                            border={'all'}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />


                        <TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />

                        <FileField
                            border={'all'}
                            documents={documents}
                            multipleMedia={false}
                            onRemoveDocument={onRemoveDocument}
                            onSelectDocuments={onSelectDocuments}
                        />
                    </div>
                    <div className={classes.Footer}>
                        <button onClick={createDir} className={classes.Create}>Создать</button>
                        <button onClick={clickCancel} className={classes.Cancel}>Отменить</button>
                    </div>
                </>

        </div>
    )
}
