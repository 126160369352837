import React from 'react'

function IconLink() {
    return (
        <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.7029 11.6002L16.4915 8.81219C18.5029 6.7939 18.4997 3.5279 16.4843 1.51357C14.4689 -0.500764 11.2029 -0.502272 9.18567 1.5102L6.39707 4.29819C6.38506 4.31019 6.37505 4.3202 6.36507 4.3322L7.63739 5.6042C7.64739 5.5922 7.65739 5.5822 7.66939 5.5702L10.458 2.7822C11.7719 1.46798 13.9024 1.46771 15.2166 2.78159C16.5308 4.09548 16.5311 6.22598 15.2172 7.54019L12.4286 10.3282C12.4166 10.3402 12.4066 10.3502 12.3946 10.3602L13.6689 11.6342L13.7029 11.6002ZM5.16464 17.9982C6.53563 18.0017 7.85103 17.4565 8.81755 16.4842L11.6061 13.6962C11.6182 13.6842 11.6282 13.6742 11.6382 13.6622L10.3659 12.3902L7.54325 15.2122C6.2288 16.5231 4.101 16.5218 2.78815 15.2093C1.4753 13.8968 1.47347 11.769 2.78405 10.4542L5.57465 7.66619L5.5747 7.66615L5.57471 7.66614C5.5867 7.65416 5.59669 7.64418 5.60865 7.6342L4.33434 6.3602L1.51174 9.1822C0.0353312 10.6596 -0.405971 12.8807 0.393542 14.8103C1.19306 16.7399 3.076 17.998 5.16464 17.9982ZM11.9382 4.78899C12.2897 4.43757 12.8596 4.43757 13.2111 4.78899C13.3799 4.95774 13.4748 5.18664 13.4748 5.42532C13.4748 5.664 13.3799 5.8929 13.2111 6.06165L6.06362 13.2074C5.71219 13.5589 5.14233 13.5589 4.79082 13.2075C4.4393 12.8561 4.43924 12.2862 4.79067 11.9347L11.9382 4.78899Z'
                fill='#94A2B6'
            />
        </svg>
    )
}

export default IconLink
