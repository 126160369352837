import React from 'react'

function IconShevronBottom({ className }) {
    return (
        <svg className={className} width={15} height={10} viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                id='Combined Shape'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.50003 3.69972L13.1333 9.33301L15 7.46632L8.46661 0.932929L8.43337 0.966168L7.46679 -0.000412646L5.16616e-05 7.46632L1.86674 9.33301L7.50003 3.69972Z'
                fill='#E00C3C'
            />
        </svg>
    )
}

export default IconShevronBottom
