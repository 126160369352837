import React from 'react'
import classes from './TextArea.module.scss'

const TextArea = (props) => {
    return (
        <div className={classes.TextArea}>
            {props.titleSection ? (
                <div className={classes.Title}>{props.titleSection}</div>
            ) : null}

            <textarea
                className={classes.Area}
                value={props.textValue ? props.textValue : ''}
                readOnly={!!props.readOnly}
                onChange={props.onChangeField ? props.onChangeField : null}
                data-small={props.dataSmall}
                data-color={props.color}
            />
        </div>
    )
}

export default TextArea