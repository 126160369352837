import React, { useContext, useState } from 'react'
import './UserEdit.scss'
import UserAddInputs from '../UserAddInputs/UserAddInputs'
import { apiUserEdit } from '../../../../../api/users'
import axios from 'axios'
import config from '../../../../../config/config'
import { PopupContext } from '../../../../../context/popup/popupContext'

const UserEdit = (props) => {
    const popupContextData = useContext(PopupContext)

    const [inputs, setInputs] = useState({
        name: {
            cls: ['admin-company__user-manipulation-input-unit'],
            label: 'Имя',
            type: 'text',
            name: 'name',
            value: props.name,
            placeholder: ''
        },
        lastName: {
            cls: [
                'admin-company__user-manipulation-input-unit',
                'admin-company__user-manipulation-input-unit_margin-bottom-0-mobile'
            ],
            label: 'Фамилия',
            type: 'text',
            name: 'lastName',
            value: props.lastName,
            placeholder: ''
        },
        email: {
            cls: ['admin-company__user-manipulation-input-unit'],
            label: 'Электронная почта',
            type: 'text',
            name: 'email',
            value: props.email,
            placeholder: ''
        }
    })

    const [isOnlyView, setIsOnlyView] = useState(props?.onlyView)
    const [isNotificationDelete, setIsNotificationDelete] = useState(props?.notifyReplicaDelete)
    const [isNotificationAdd, setIsNotificationAdd] = useState(props?.notifyReplicaAdd)
    const [isNotificationStatusChange, setIsNotificationStatusChange] = useState(props?.notifyReplicaStatusChange)

    const onToogleIsOnlyView = () => {
        setIsOnlyView((prev) => !prev)
    }

    const fieldChange = (event, name) => {
        const value = event.target.value
        const inputsCopy = { ...inputs }
        inputsCopy[name].value = value
        setInputs(inputsCopy)
    }

    const userEdit = async (event) => {
        event.preventDefault()

        popupContextData.setPreloader(true)
        const onlyView = isOnlyView ? 'Y' : 'N'
        const data = await apiUserEdit(props.id, inputs, onlyView, { notifyReplicaDelete: isNotificationDelete ? 'Y' : 'N', notifyReplicaAdd: isNotificationAdd ? 'Y' : 'N', notifyReplicaStatusChange: setIsNotificationStatusChange ? 'Y' : 'N' })

        if (data.status === 'fail') {
            popupContextData.setPreloader(false)
            alert(data.errorText)
        } else {
            props.close()
            props.getUsers()
        }
    }

    const userChangePassword = async () => {
        popupContextData.setPreloader(true)
        const token = localStorage.getItem('token')

        const response = await axios.put(`${config.SERVER_BASE_URL}/users/change-password/${props.id}`, {
            token
        })

        const responseData = response.data
        popupContextData.setPreloader(false)

        if (responseData.status === 'fail') {
            alert(responseData.errorText)
        } else {
            props.close()
        }
    }

    return (
        <div className='admin-company__user-manipulation admin-company__user-manipulation_editing'>
            <div className='admin-company__user-manipulation-top-side'>
                <div className='admin-company__user-manipulation-top-side-name'>Редактирование учетной записи пользователя</div>

                <div className='admin-company__user-manipulation-top-side-button' onClick={props.close} />
            </div>

            <div className='admin-company__user-manipulation-content'>
                <div className='admin-company__user-manipulation-selects-container'>
                    {Object.keys(inputs).map((inputId, index) => {
                        const input = inputs[inputId]

                        return (
                            <UserAddInputs
                                key={index}
                                cls={input.cls.join(' ')}
                                label={input.label}
                                type={input.type}
                                name={input.name}
                                value={input.value}
                                placeholder={input.placeholder}
                                fieldChange={(event) => fieldChange(event, input.name)}
                            />
                        )
                    })}
                </div>

                <div className='admin-company__user-manipulation-buttons'>
                    <div className='admin-company__user-manipulation-button admin-company__user-manipulation-button_red admin-company__user-manipulation-button_password'>
                        <button onClick={userChangePassword}>Сменить пароль</button>
                    </div>

                    <div className='admin-company__user-manipulation-button admin-company__user-manipulation-button_blue'>
                        <button onClick={userEdit}>Сохранить</button>
                    </div>
                </div>
            </div>

            <div className='admin-company__user-manipulation__checkbox'>
                <button onClick={onToogleIsOnlyView}>
                    {!isOnlyView && (
                        <svg width={48} height={23} viewBox='0 0 48 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M11.7726 0H36.2274C42.6947 0 48 5.13305 48 11.5C48 17.867 42.6947 23 36.2274 23H11.7726C5.30526 23 0 17.867 0 11.5C0 5.13305 5.30526 0 11.7726 0ZM36.2274 19.9399C40.9768 19.9399 44.8674 16.1395 44.8674 11.5C44.8674 6.86051 40.9768 3.06009 36.2274 3.06009C31.4779 3.06009 27.5874 6.86051 27.5874 11.5C27.5874 16.1395 31.4779 19.9399 36.2274 19.9399ZM28.2442 19.9399H11.7726C7.02316 19.9399 3.18316 16.1395 3.18316 11.5C3.18316 6.86051 7.07368 3.06009 11.8232 3.06009H28.2442C25.92 5.1824 24.4547 8.14378 24.4547 11.5C24.4547 14.8562 25.92 17.8176 28.2442 19.9399Z'
                                fill='#94A2B6'
                            />
                            <path
                                d='M40.5455 7.37643C40.0455 6.87452 39.1364 6.87452 38.6364 7.37643L36.4545 9.56654L34.3182 7.37643C33.8182 6.87452 32.9091 6.87452 32.4091 7.37643C31.8636 7.92395 31.8636 8.74525 32.4091 9.29278L34.5909 11.4829L32.4091 13.673C31.8636 14.2205 31.8636 15.0418 32.4091 15.5894C32.6818 15.8631 33 16 33.3636 16C33.7273 16 34.0455 15.8631 34.3182 15.5894L36.5 13.3992L38.6818 15.5894C38.9545 15.8631 39.2727 16 39.6364 16C40 16 40.3182 15.8631 40.5909 15.5894C41.1364 15.0418 41.1364 14.2205 40.5909 13.673L38.4091 11.4829L40.5909 9.29278C41.0909 8.79087 41.0909 7.92395 40.5455 7.37643Z'
                                fill='#94A2B6'
                            />
                        </svg>
                    )}
                    {isOnlyView && (
                        <svg width={48} height={23} viewBox='0 0 48 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M36.2274 0H11.7726C5.30526 0 0 5.13305 0 11.5C0 17.867 5.30526 23 11.7726 23H36.2274C42.6947 23 48 17.867 48 11.5C48 5.13305 42.6947 0 36.2274 0ZM11.7726 19.9399C7.02316 19.9399 3.13263 16.1395 3.13263 11.5C3.13263 6.86051 7.02316 3.06009 11.7726 3.06009C16.5221 3.06009 20.4126 6.86051 20.4126 11.5C20.4126 16.1395 16.5221 19.9399 11.7726 19.9399ZM19.7558 19.9399H36.2274C40.9768 19.9399 44.8168 16.1395 44.8168 11.5C44.8168 6.86051 40.9263 3.06009 36.1768 3.06009H19.7558C22.08 5.1824 23.5453 8.14378 23.5453 11.5C23.5453 14.8562 22.08 17.8176 19.7558 19.9399Z'
                                fill='#119E00'
                            />
                            <path
                                d='M38.5455 7.37643C38.0455 6.87452 37.1364 6.87452 36.6364 7.37643L34.4545 9.56654L32.3182 7.37643C31.8182 6.87452 30.9091 6.87452 30.4091 7.37643C29.8636 7.92395 29.8636 8.74525 30.4091 9.29278L32.5909 11.4829L30.4091 13.673C29.8636 14.2205 29.8636 15.0418 30.4091 15.5894C30.6818 15.8631 31 16 31.3636 16C31.7273 16 32.0455 15.8631 32.3182 15.5894L34.5 13.3992L36.6818 15.5894C36.9545 15.8631 37.2727 16 37.6364 16C38 16 38.3182 15.8631 38.5909 15.5894C39.1364 15.0418 39.1364 14.2205 38.5909 13.673L36.4091 11.4829L38.5909 9.29278C39.0909 8.79087 39.0909 7.92395 38.5455 7.37643Z'
                                fill='#119E00'
                            />
                        </svg>
                    )}
                    Редактор
                </button>
            </div>

            <div className='admin-company__user-manipulation__checkbox'>
                <button onClick={ () => setIsNotificationAdd(prev => !prev)}>
                    {!isNotificationAdd && (
                        <svg width={48} height={23} viewBox='0 0 48 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M11.7726 0H36.2274C42.6947 0 48 5.13305 48 11.5C48 17.867 42.6947 23 36.2274 23H11.7726C5.30526 23 0 17.867 0 11.5C0 5.13305 5.30526 0 11.7726 0ZM36.2274 19.9399C40.9768 19.9399 44.8674 16.1395 44.8674 11.5C44.8674 6.86051 40.9768 3.06009 36.2274 3.06009C31.4779 3.06009 27.5874 6.86051 27.5874 11.5C27.5874 16.1395 31.4779 19.9399 36.2274 19.9399ZM28.2442 19.9399H11.7726C7.02316 19.9399 3.18316 16.1395 3.18316 11.5C3.18316 6.86051 7.07368 3.06009 11.8232 3.06009H28.2442C25.92 5.1824 24.4547 8.14378 24.4547 11.5C24.4547 14.8562 25.92 17.8176 28.2442 19.9399Z'
                                fill='#94A2B6'
                            />
                            <path
                                d='M40.5455 7.37643C40.0455 6.87452 39.1364 6.87452 38.6364 7.37643L36.4545 9.56654L34.3182 7.37643C33.8182 6.87452 32.9091 6.87452 32.4091 7.37643C31.8636 7.92395 31.8636 8.74525 32.4091 9.29278L34.5909 11.4829L32.4091 13.673C31.8636 14.2205 31.8636 15.0418 32.4091 15.5894C32.6818 15.8631 33 16 33.3636 16C33.7273 16 34.0455 15.8631 34.3182 15.5894L36.5 13.3992L38.6818 15.5894C38.9545 15.8631 39.2727 16 39.6364 16C40 16 40.3182 15.8631 40.5909 15.5894C41.1364 15.0418 41.1364 14.2205 40.5909 13.673L38.4091 11.4829L40.5909 9.29278C41.0909 8.79087 41.0909 7.92395 40.5455 7.37643Z'
                                fill='#94A2B6'
                            />
                        </svg>
                    )}
                    {isNotificationAdd && (
                        <svg width={48} height={23} viewBox='0 0 48 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M36.2274 0H11.7726C5.30526 0 0 5.13305 0 11.5C0 17.867 5.30526 23 11.7726 23H36.2274C42.6947 23 48 17.867 48 11.5C48 5.13305 42.6947 0 36.2274 0ZM11.7726 19.9399C7.02316 19.9399 3.13263 16.1395 3.13263 11.5C3.13263 6.86051 7.02316 3.06009 11.7726 3.06009C16.5221 3.06009 20.4126 6.86051 20.4126 11.5C20.4126 16.1395 16.5221 19.9399 11.7726 19.9399ZM19.7558 19.9399H36.2274C40.9768 19.9399 44.8168 16.1395 44.8168 11.5C44.8168 6.86051 40.9263 3.06009 36.1768 3.06009H19.7558C22.08 5.1824 23.5453 8.14378 23.5453 11.5C23.5453 14.8562 22.08 17.8176 19.7558 19.9399Z'
                                fill='#119E00'
                            />
                            <path
                                d='M38.5455 7.37643C38.0455 6.87452 37.1364 6.87452 36.6364 7.37643L34.4545 9.56654L32.3182 7.37643C31.8182 6.87452 30.9091 6.87452 30.4091 7.37643C29.8636 7.92395 29.8636 8.74525 30.4091 9.29278L32.5909 11.4829L30.4091 13.673C29.8636 14.2205 29.8636 15.0418 30.4091 15.5894C30.6818 15.8631 31 16 31.3636 16C31.7273 16 32.0455 15.8631 32.3182 15.5894L34.5 13.3992L36.6818 15.5894C36.9545 15.8631 37.2727 16 37.6364 16C38 16 38.3182 15.8631 38.5909 15.5894C39.1364 15.0418 39.1364 14.2205 38.5909 13.673L36.4091 11.4829L38.5909 9.29278C39.0909 8.79087 39.0909 7.92395 38.5455 7.37643Z'
                                fill='#119E00'
                            />
                        </svg>
                    )}
                    Добавление
                </button>
            </div>

            <div className='admin-company__user-manipulation__checkbox'>
                <button onClick={ () => setIsNotificationStatusChange(prev => !prev)}>
                    {!isNotificationStatusChange && (
                        <svg width={48} height={23} viewBox='0 0 48 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M11.7726 0H36.2274C42.6947 0 48 5.13305 48 11.5C48 17.867 42.6947 23 36.2274 23H11.7726C5.30526 23 0 17.867 0 11.5C0 5.13305 5.30526 0 11.7726 0ZM36.2274 19.9399C40.9768 19.9399 44.8674 16.1395 44.8674 11.5C44.8674 6.86051 40.9768 3.06009 36.2274 3.06009C31.4779 3.06009 27.5874 6.86051 27.5874 11.5C27.5874 16.1395 31.4779 19.9399 36.2274 19.9399ZM28.2442 19.9399H11.7726C7.02316 19.9399 3.18316 16.1395 3.18316 11.5C3.18316 6.86051 7.07368 3.06009 11.8232 3.06009H28.2442C25.92 5.1824 24.4547 8.14378 24.4547 11.5C24.4547 14.8562 25.92 17.8176 28.2442 19.9399Z'
                                fill='#94A2B6'
                            />
                            <path
                                d='M40.5455 7.37643C40.0455 6.87452 39.1364 6.87452 38.6364 7.37643L36.4545 9.56654L34.3182 7.37643C33.8182 6.87452 32.9091 6.87452 32.4091 7.37643C31.8636 7.92395 31.8636 8.74525 32.4091 9.29278L34.5909 11.4829L32.4091 13.673C31.8636 14.2205 31.8636 15.0418 32.4091 15.5894C32.6818 15.8631 33 16 33.3636 16C33.7273 16 34.0455 15.8631 34.3182 15.5894L36.5 13.3992L38.6818 15.5894C38.9545 15.8631 39.2727 16 39.6364 16C40 16 40.3182 15.8631 40.5909 15.5894C41.1364 15.0418 41.1364 14.2205 40.5909 13.673L38.4091 11.4829L40.5909 9.29278C41.0909 8.79087 41.0909 7.92395 40.5455 7.37643Z'
                                fill='#94A2B6'
                            />
                        </svg>
                    )}
                    {isNotificationStatusChange && (
                        <svg width={48} height={23} viewBox='0 0 48 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M36.2274 0H11.7726C5.30526 0 0 5.13305 0 11.5C0 17.867 5.30526 23 11.7726 23H36.2274C42.6947 23 48 17.867 48 11.5C48 5.13305 42.6947 0 36.2274 0ZM11.7726 19.9399C7.02316 19.9399 3.13263 16.1395 3.13263 11.5C3.13263 6.86051 7.02316 3.06009 11.7726 3.06009C16.5221 3.06009 20.4126 6.86051 20.4126 11.5C20.4126 16.1395 16.5221 19.9399 11.7726 19.9399ZM19.7558 19.9399H36.2274C40.9768 19.9399 44.8168 16.1395 44.8168 11.5C44.8168 6.86051 40.9263 3.06009 36.1768 3.06009H19.7558C22.08 5.1824 23.5453 8.14378 23.5453 11.5C23.5453 14.8562 22.08 17.8176 19.7558 19.9399Z'
                                fill='#119E00'
                            />
                            <path
                                d='M38.5455 7.37643C38.0455 6.87452 37.1364 6.87452 36.6364 7.37643L34.4545 9.56654L32.3182 7.37643C31.8182 6.87452 30.9091 6.87452 30.4091 7.37643C29.8636 7.92395 29.8636 8.74525 30.4091 9.29278L32.5909 11.4829L30.4091 13.673C29.8636 14.2205 29.8636 15.0418 30.4091 15.5894C30.6818 15.8631 31 16 31.3636 16C31.7273 16 32.0455 15.8631 32.3182 15.5894L34.5 13.3992L36.6818 15.5894C36.9545 15.8631 37.2727 16 37.6364 16C38 16 38.3182 15.8631 38.5909 15.5894C39.1364 15.0418 39.1364 14.2205 38.5909 13.673L36.4091 11.4829L38.5909 9.29278C39.0909 8.79087 39.0909 7.92395 38.5455 7.37643Z'
                                fill='#119E00'
                            />
                        </svg>
                    )}
                    Изменение
                </button>
            </div>


            <div className='admin-company__user-manipulation__checkbox'>
                <button onClick={ () => setIsNotificationDelete(prev => !prev)}>
                    {!isNotificationDelete && (
                        <svg width={48} height={23} viewBox='0 0 48 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M11.7726 0H36.2274C42.6947 0 48 5.13305 48 11.5C48 17.867 42.6947 23 36.2274 23H11.7726C5.30526 23 0 17.867 0 11.5C0 5.13305 5.30526 0 11.7726 0ZM36.2274 19.9399C40.9768 19.9399 44.8674 16.1395 44.8674 11.5C44.8674 6.86051 40.9768 3.06009 36.2274 3.06009C31.4779 3.06009 27.5874 6.86051 27.5874 11.5C27.5874 16.1395 31.4779 19.9399 36.2274 19.9399ZM28.2442 19.9399H11.7726C7.02316 19.9399 3.18316 16.1395 3.18316 11.5C3.18316 6.86051 7.07368 3.06009 11.8232 3.06009H28.2442C25.92 5.1824 24.4547 8.14378 24.4547 11.5C24.4547 14.8562 25.92 17.8176 28.2442 19.9399Z'
                                fill='#94A2B6'
                            />
                            <path
                                d='M40.5455 7.37643C40.0455 6.87452 39.1364 6.87452 38.6364 7.37643L36.4545 9.56654L34.3182 7.37643C33.8182 6.87452 32.9091 6.87452 32.4091 7.37643C31.8636 7.92395 31.8636 8.74525 32.4091 9.29278L34.5909 11.4829L32.4091 13.673C31.8636 14.2205 31.8636 15.0418 32.4091 15.5894C32.6818 15.8631 33 16 33.3636 16C33.7273 16 34.0455 15.8631 34.3182 15.5894L36.5 13.3992L38.6818 15.5894C38.9545 15.8631 39.2727 16 39.6364 16C40 16 40.3182 15.8631 40.5909 15.5894C41.1364 15.0418 41.1364 14.2205 40.5909 13.673L38.4091 11.4829L40.5909 9.29278C41.0909 8.79087 41.0909 7.92395 40.5455 7.37643Z'
                                fill='#94A2B6'
                            />
                        </svg>
                    )}
                    {isNotificationDelete && (
                        <svg width={48} height={23} viewBox='0 0 48 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M36.2274 0H11.7726C5.30526 0 0 5.13305 0 11.5C0 17.867 5.30526 23 11.7726 23H36.2274C42.6947 23 48 17.867 48 11.5C48 5.13305 42.6947 0 36.2274 0ZM11.7726 19.9399C7.02316 19.9399 3.13263 16.1395 3.13263 11.5C3.13263 6.86051 7.02316 3.06009 11.7726 3.06009C16.5221 3.06009 20.4126 6.86051 20.4126 11.5C20.4126 16.1395 16.5221 19.9399 11.7726 19.9399ZM19.7558 19.9399H36.2274C40.9768 19.9399 44.8168 16.1395 44.8168 11.5C44.8168 6.86051 40.9263 3.06009 36.1768 3.06009H19.7558C22.08 5.1824 23.5453 8.14378 23.5453 11.5C23.5453 14.8562 22.08 17.8176 19.7558 19.9399Z'
                                fill='#119E00'
                            />
                            <path
                                d='M38.5455 7.37643C38.0455 6.87452 37.1364 6.87452 36.6364 7.37643L34.4545 9.56654L32.3182 7.37643C31.8182 6.87452 30.9091 6.87452 30.4091 7.37643C29.8636 7.92395 29.8636 8.74525 30.4091 9.29278L32.5909 11.4829L30.4091 13.673C29.8636 14.2205 29.8636 15.0418 30.4091 15.5894C30.6818 15.8631 31 16 31.3636 16C31.7273 16 32.0455 15.8631 32.3182 15.5894L34.5 13.3992L36.6818 15.5894C36.9545 15.8631 37.2727 16 37.6364 16C38 16 38.3182 15.8631 38.5909 15.5894C39.1364 15.0418 39.1364 14.2205 38.5909 13.673L36.4091 11.4829L38.5909 9.29278C39.0909 8.79087 39.0909 7.92395 38.5455 7.37643Z'
                                fill='#119E00'
                            />
                        </svg>
                    )}
                    Удаление
                </button>
            </div>
        </div>
    )
}

export default UserEdit
