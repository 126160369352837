import React from 'react'

function IconSelect({ className }) {
    return (
        <svg className={className} width={10} height={7} viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                id='Combined Shape'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.99999 3.75553L1.24446 0L0 1.24446L4.3556 5.60005L4.37776 5.57789L5.02216 6.22228L9.99998 1.24446L8.75552 0L4.99999 3.75553Z'
                fill='#E00C3C'
            />
        </svg>
    )
}

export default IconSelect
