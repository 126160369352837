import React from 'react'

function IconFilter() {
    return (
        <svg width={23} height={28} viewBox='0 0 23 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.37375 5.5C8.92911 7.22469 7.36171 8.5 5.5 8.5C3.63728 8.5 2.0708 7.22392 1.62628 5.5H1.5C0.947716 5.5 0.5 5.05228 0.5 4.5C0.5 3.94772 0.947716 3.5 1.5 3.5H1.62625C2.07089 1.77531 3.63829 0.5 5.5 0.5C7.36272 0.5 8.9292 1.77608 9.37372 3.5L21.5 3.5C22.0523 3.5 22.5 3.94772 22.5 4.5C22.5 5.05228 22.0523 5.5 21.5 5.5L9.37375 5.5ZM21.3738 15H21.5C22.0523 15 22.5 14.5523 22.5 14C22.5 13.4477 22.0523 13 21.5 13H21.3737C20.9292 11.2761 19.3627 10 17.5 10C15.6383 10 14.0709 11.2753 13.6263 13L1.5 13C0.947716 13 0.5 13.4477 0.5 14C0.5 14.5523 0.947716 15 1.5 15L13.6263 15C14.0708 16.7239 15.6373 18 17.5 18C19.3617 18 20.9291 16.7247 21.3738 15ZM15.5 14C15.5 12.8968 16.3975 12 17.5 12C18.6032 12 19.5 12.8975 19.5 14C19.5 15.1032 18.6025 16 17.5 16C16.3968 16 15.5 15.1025 15.5 14ZM3.5 4.5C3.5 3.39677 4.39746 2.5 5.5 2.5C6.60323 2.5 7.5 3.39746 7.5 4.5C7.5 5.60323 6.60254 6.5 5.5 6.5C4.39677 6.5 3.5 5.60254 3.5 4.5ZM13.8737 24.5C13.4291 26.2247 11.8617 27.5 10 27.5C8.13728 27.5 6.5708 26.2239 6.12628 24.5H1.5C0.947716 24.5 0.5 24.0523 0.5 23.5C0.5 22.9477 0.947716 22.5 1.5 22.5H6.12625C6.57089 20.7753 8.13829 19.5 10 19.5C11.8627 19.5 13.4292 20.7761 13.8737 22.5H21.5C22.0523 22.5 22.5 22.9477 22.5 23.5C22.5 24.0523 22.0523 24.5 21.5 24.5H13.8737ZM8 23.5C8 22.3968 8.89746 21.5 10 21.5C11.1032 21.5 12 22.3975 12 23.5C12 24.6032 11.1025 25.5 10 25.5C8.89677 25.5 8 24.6025 8 23.5Z'
                fill='#2E2382'
            />
        </svg>
    )
}

export default IconFilter
