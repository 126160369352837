import React from 'react'

function IconCloseArrow() {
    return (
        <svg width={22} height={14} viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.69852 0.000610352L1.29207 4.40706L0.584961 5.11417L1.29207 5.82128L5.69852 10.2277L7.11274 8.81352L4.29848 5.99926H16.9999C18.6567 5.99926 19.9999 7.34241 19.9999 8.99926C19.9999 10.6561 18.6567 11.9993 16.9999 11.9993H10.9999V13.9993H16.9999C19.7613 13.9993 21.9999 11.7607 21.9999 8.99926C21.9999 6.23784 19.7613 3.99926 16.9999 3.99926H4.5283L7.11274 1.41482L5.69852 0.000610352Z'
                fill='white'
            />
        </svg>
    )
}

export default IconCloseArrow
