import React from 'react'

function IconYandex() {
    return (
        <svg width={36} height={36} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M36 33.75C36 34.9911 34.9911 36 33.75 36H2.24999C1.00902 36 0 34.9911 0 33.75V2.24998C0 1.00894 1.00902 0 2.24999 0H33.75C34.9911 0 36 1.00894 36 2.24998V33.75Z'
                fill='#ED1F24'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.8358 7.35406H22.0619C22.2601 7.35406 22.3733 7.43923 22.3734 7.63796V28.4195C22.3734 28.5608 22.3167 28.646 22.147 28.646H20.3921C20.2788 28.646 20.1942 28.5322 20.1942 28.4476V20.7538H18.7793L14.8456 28.4476C14.7891 28.5894 14.6753 28.646 14.5057 28.646H12.4962C12.2699 28.646 12.1282 28.4476 12.2699 28.1918L16.5995 20.3563C14.2793 19.3341 12.9775 17.262 12.9775 14.4517C12.9775 9.76702 15.6658 7.35406 18.8358 7.35406ZM19.0056 18.9371H20.1942H20.1942V9.17081H18.7508C17.0246 9.17081 15.3268 10.6186 15.3268 14.1678C15.3268 17.5745 17.1379 18.9371 19.0056 18.9371Z'
                fill='white'
            />
        </svg>
    )
}

export default IconYandex
