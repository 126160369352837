import React, {useContext} from "react"
import "./ReplicaDelete.scss"
import axios from "axios"
import config from "../../../../config/config"
import {PopupContext} from "../../../../context/popup/popupContext"

const ReplicaDelete = props => {
    const popupContextData = useContext(PopupContext)

    const replicaDelete = async (id) => {
        popupContextData.setPreloader(true)

        const token = localStorage.getItem('token')
        const response = await axios.delete(`${config.SERVER_BASE_URL}/replica/${id}?token=${token}`)
        const responseData = response.data

        if (responseData.status === 'fail') {
            popupContextData.setPreloader(false)
            alert(responseData.errorText)
        } else {
            props.close()
            props.updateReplicaList()
        }
    }

    return (
        <div className="replic__remove-replic replic__replic-unit_open">
            <div className="replic__remove-replic-content">
                <div className="replic__remove-replic-content-text">Вы действительно хотите удалить реплику?</div>

                <div className="replic__remove-replic-content-buttons">
                    <div className="replic__remove-replic-content-button replic__remove-replic-content-button_red">
                        <button onClick={props.close}>Нет</button>
                    </div>

                    <div className="replic__remove-replic-content-button replic__remove-replic-content-button_gray">
                        <button onClick={() => replicaDelete(props.id)}>Да</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReplicaDelete