import React from 'react'

function IconFolder({ className }) {
    return (
        <svg className={className} width={22} height={19} viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M11.1614 3.51028L18.9145 3.50138C20.0665 3.5004 20.9999 4.43283 20.9999 5.58466V16.4164C20.9999 17.5664 20.0667 18.4997 18.9165 18.4997H3.08348C1.93326 18.4997 1 17.5665 1 16.4164V3.08327C1 1.93266 1.93301 1 3.08348 1H7.80494C8.36087 1 8.89333 1.22175 9.28503 1.6168L11.1614 3.51028ZM11.1614 3.51028L11.2962 3.64645C11.4581 3.80989 11.4569 4.07373 11.2934 4.23577C11.2383 4.29012 11.1721 4.32609 11.1021 4.34368C11.0355 4.36048 10.9658 4.36048 10.8992 4.34407C10.8276 4.32609 10.7598 4.28913 10.7041 4.23281L8.69358 2.20311C8.4584 1.96615 8.13902 1.83314 7.80519 1.83314H3.08373C2.39337 1.83314 1.83369 2.39267 1.83369 3.08297V16.4161C1.83369 17.1059 2.39377 17.6661 3.08373 17.6661H18.9167C19.6067 17.6661 20.1668 17.1059 20.1668 16.4161V5.58436C20.1668 4.89304 19.6069 4.33372 18.9156 4.33433L10.9933 4.35191C10.7633 4.35211 10.5765 4.16594 10.5763 3.93569C10.5759 3.70565 10.7623 3.51888 10.9925 3.51849L11.1614 3.51028ZM18.9165 17.6661C19.6065 17.6661 20.1666 17.106 20.1666 16.4162V8.08284C20.1666 7.39289 19.6065 6.83281 18.9165 6.83281H3.08353C2.39357 6.83281 1.83349 7.39289 1.83349 8.08284V16.4162C1.83349 17.106 2.39357 17.6661 3.08353 17.6661H18.9165ZM18.9165 18.4995H3.08353C1.93331 18.4995 1.00005 17.5663 1.00005 16.4162V8.08284C1.00005 6.93263 1.93326 5.99957 3.08353 5.99957H18.9165C20.0667 5.99957 21 6.93258 21 8.08284V16.4162C21 17.5662 20.0668 18.4995 18.9165 18.4995Z'
                fill='#94A2B6'
            />
            <path
                d='M11.1614 3.51028L18.9145 3.50138C20.0665 3.5004 20.9999 4.43283 20.9999 5.58466V16.4164C20.9999 17.5664 20.0667 18.4997 18.9165 18.4997H3.08348C1.93326 18.4997 1 17.5665 1 16.4164V3.08327C1 1.93266 1.93301 1 3.08348 1H7.80494C8.36087 1 8.89333 1.22175 9.28503 1.6168L11.1614 3.51028ZM11.1614 3.51028L11.2962 3.64645C11.4581 3.80989 11.4569 4.07373 11.2934 4.23577C11.2383 4.29012 11.1721 4.32609 11.1021 4.34368C11.0355 4.36048 10.9658 4.36048 10.8992 4.34407C10.8276 4.32609 10.7598 4.28913 10.7041 4.23281L8.69358 2.20311C8.4584 1.96615 8.13901 1.83314 7.80519 1.83314H3.08373C2.39337 1.83314 1.83369 2.39267 1.83369 3.08297V16.4161C1.83369 17.1059 2.39377 17.6661 3.08373 17.6661H18.9167C19.6067 17.6661 20.1668 17.1059 20.1668 16.4161V5.58436C20.1668 4.89304 19.6069 4.33372 18.9156 4.33432L10.9933 4.35191C10.7633 4.35211 10.5765 4.16594 10.5763 3.93569C10.5759 3.70565 10.7623 3.51888 10.9925 3.51849L11.1614 3.51028ZM18.9165 17.6661C19.6065 17.6661 20.1666 17.106 20.1666 16.4162V8.08284C20.1666 7.39289 19.6065 6.83281 18.9165 6.83281H3.08353C2.39357 6.83281 1.83349 7.39289 1.83349 8.08284V16.4162C1.83349 17.106 2.39357 17.6661 3.08353 17.6661H18.9165ZM18.9165 18.4995H3.08353C1.93331 18.4995 1.00005 17.5663 1.00005 16.4162V8.08284C1.00005 6.93263 1.93326 5.99957 3.08353 5.99957H18.9165C20.0667 5.99957 21 6.93258 21 8.08284V16.4162C21 17.5662 20.0668 18.4995 18.9165 18.4995Z'
                stroke='#94A2B6'
            />
        </svg>
    )
}

export default IconFolder
