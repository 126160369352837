import React, {useContext, useEffect, useState} from "react"
import "./UserAdd.scss"
import UserAddFields from "../UserAddFields/UserAddFields"
import {apiRoleList} from "../../../../../api/role"
import {apiUsersInProjectAdd, apiUsersInProjectListAdd} from "../../../../../api/users-in-project"
import {PopupContext} from "../../../../../context/popup/popupContext"

const UserAdd = props => {
    const popupContextData = useContext(PopupContext)

    const [inputs, setInputs] = useState({
        'role': {
            cls: ['admin-company__user-manipulation-selects-unit'],
            label: 'Роль',
            name: 'role',
            value: false,
            options: [],
            optionDefault: 'Выберите роль',
        },
        'user': {
            cls: ['admin-company__user-manipulation-selects-unit'],
            label: 'Пользователь',
            name: 'user',
            value: false,
            options: [],
            optionDefault: 'Выберите пользователя',
        },
    })

    const fieldChange = async (event, name) => {
        const value = event.target.value
        const inputsCopy = {...inputs}
        inputsCopy[name].value = value

        if (name === 'role') {
            inputsCopy['user'].options = []
            inputsCopy['user'].value = false

            if (value) {
                popupContextData.setPreloader(true)
                const users = await apiUsersInProjectListAdd(value, props.companyId, props.projectId)

                if (users.status === 'success') {
                    Object.keys(users.data).map((userId) => {
                        const user = users.data[userId]

                        return inputsCopy['user'].options.push({
                            id: user._id,
                            name: `${user.lastName} ${user.name}`,
                        })
                    })
                }

                popupContextData.setPreloader(false)
            }
        }

        setInputs(inputsCopy)
    }

    const getRoles = async () => {
        popupContextData.setPreloader(true)
        const roles = await apiRoleList()

        if (roles.status === 'success') {
            const inputsCopy = {...inputs}
            inputsCopy['role'].options = []

            Object.keys(roles.data).map((roleId) => {
                const role = roles.data[roleId]

                return inputsCopy['role'].options.push({
                    id: role._id,
                    name: role.name,
                })
            })

            setInputs(inputsCopy)
        }

        popupContextData.setPreloader(false)
    }

    const addUserLink = async (event) => {
        event.preventDefault()

        popupContextData.setPreloader(true)
        const userId = inputs['user'].value
        const data = await apiUsersInProjectAdd(props.projectId, userId)

        if (data.status === 'fail') {
            popupContextData.setPreloader(false)
            alert(data.errorText)
        } else {
            props.getLinks()
            props.close()
        }
    }

    useEffect(() => {
        getRoles()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="admin-company__user-manipulation admin-company__user-manipulation_attaching">
            <div className="admin-company__user-manipulation-top-side">
                <div className="admin-company__user-manipulation-top-side-name">Привязка пользователя к рабочей группе проекта</div>

                <div
                    className="admin-company__user-manipulation-top-side-button"
                    onClick={props.close}
                />
            </div>

            <div className="admin-company__user-manipulation-content admin-company__user-manipulation-content_add-user">
                <div className="admin-company__user-manipulation-selects-container">
                    {Object.keys(inputs).map((inputId, index) => {
                        const input = inputs[inputId]

                        return (
                            <UserAddFields
                                key={index}
                                cls={input.cls.join(' ')}
                                label={input.label}
                                name={input.name}
                                value={input.value}
                                options={input.options}
                                optionDefault={input.optionDefault}
                                fieldChange={(event) => fieldChange(event, input.name)}
                            />
                        )
                    })}
                </div>

                <div className="admin-company__user-manipulation-buttons">
                    <div className="admin-company__user-manipulation-button admin-company__user-manipulation-button_width-100 admin-company__user-manipulation-button_blue">
                        <button onClick={addUserLink}>Привязать</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAdd
