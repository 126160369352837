import 'flatpickr/dist/flatpickr.min.css'
import React from 'react'
import classes from './DateField.module.scss'
import cn from 'classnames'
import { Russian } from 'flatpickr/dist/l10n/ru'
import IconCalendar from '../../../../icons/IconCalendar'
import Flatpickr from 'react-flatpickr'

function DateField({ value, onChange, className, name }) {
    const showMonths = window.innerWidth > 1280 ? 2 : 1

    return (
        <div className={cn(classes.Wrap, className)}>
            <Flatpickr
                options={{
                    dateFormat: 'd.m.Y',
                    locale: Russian,
                    lang: 'ru',
                    disableMobile: true,
                    showMonths: showMonths
                }}
                value={value}
                onChange={([date]) => {
                    onChange(name, date)
                }}
                placeholder={'__.__.__'}
                onDayCreate={[
                    function (dObj, dStr, fp, dayElem) {
                        let date = dayElem.dateObj

                        if (date.getDay() === 0 || date.getDay() === 6) {
                            dayElem.className += ' weekday'
                        }
                    }
                ]}
            />

            <IconCalendar />
        </div>
    )
}

export default DateField
