import React, { useContext, useEffect, useState } from 'react'
import './UserList.scss'
import UserAdd from '../UserAdd/UserAdd'
import { apiUsersInProjectList } from '../../../../../api/users-in-project'
import Loading from '../../../../UI/Loading/Loading'
import UserItem from '../UserItem/UserItem'
import { PopupContext } from '../../../../../context/popup/popupContext'
import DesktopPlan from '../../../../Desktop/DesktopPlan/DesktopPlan'
import { apiProjectAddPlan, apiProjectGetPlan } from '../../../../../api/projects'

const UserList = (props) => {
    const popupContextData = useContext(PopupContext)
    const [loading, setLoading] = useState(true)
    const [links, setLinks] = useState(undefined)
    const [plan, setPlan] = useState({
        planNull: null,
        planOne: null,
        planTwo: null
    })

    const [userAdd, setUserAdd] = useState(false)

    const getLinks = async () => {
        setLoading(true)
        const link = await apiUsersInProjectList(props.projectId)

        if (link.status === 'success') {
            setLinks(link.data)
        } else {
            setLinks(undefined)
        }

        popupContextData.setPreloader(false)
        setLoading(false)
    }

    const getPlan = async () => {
        try {
            setLoading(true)
            const { data } = await apiProjectGetPlan(props.projectId)

            if (data?.status === 'success' && data?.plan?.length) {
                let copyPlan = { ...plan }

                data.plan.forEach((item) => {
                    if (item.type === null) {
                        copyPlan = { ...copyPlan, planNull: item.data }
                    }

                    if (item.type === 1) {
                        copyPlan = { ...copyPlan, planOne: item.data }
                    }

                    if (item.type === 2) {
                        copyPlan = { ...copyPlan, planTwo: item.data }
                    }
                })

                setPlan(copyPlan)
            }

            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onSavePlan = async (arr) => {
        let preResult = []

        preResult = arr.map((mainItem) => {
            let data = []

            mainItem.data.forEach((item) => {
                let newItems = []

                item.items.forEach((itemInner) => {
                    if (itemInner?.items?.length) {
                        itemInner.items.forEach((itemInnerLvlTwo) => {
                            const newItemInnerLvlTwo = Object.entries(itemInnerLvlTwo).reduce((obj, [key, value]) => {
                                if (key !== 'id' && key !== 'items') {
                                    obj[key] = value.value
                                } else {
                                    obj[key] = value
                                }

                                return obj
                            }, {})

                            newItems.push({
                                ...newItemInnerLvlTwo,
                                name: newItemInnerLvlTwo?.newUrl || newItemInnerLvlTwo?.url || newItemInnerLvlTwo.name
                            })
                        })
                    } else {
                        const newItemInner = Object.entries(itemInner).reduce((obj, [key, value]) => {
                            if (key !== 'id' && key !== 'items') {
                                obj[key] = value?.value !== 'undefined' ? value.value : value
                            } else {
                                obj[key] = value
                            }

                            return obj
                        }, {})

                        return newItems.push({
                            ...newItemInner,
                            name: newItemInner?.newUrl || newItemInner?.url || newItemInner.name
                        })
                    }
                })

                const newItem = {
                    ...item,
                    dateStart: item.dateStart ? item.dateStart.toLocaleDateString('ru-RU') : '',
                    dateEnd: item.dateEnd ? item.dateEnd.toLocaleDateString('ru-RU') : '',
                    items: newItems
                }

                return data.push(newItem)
            })

            return {
                ...mainItem,
                data: data
            }
        })

        popupContextData.setPreloader(true)
        const data = await apiProjectAddPlan(props.projectId, JSON.stringify(preResult))

        if (data && data.status === 'fail') {
            popupContextData.setPreloader(false)

            if (data?.errorText) {
                alert(data.errorText)
            }
        } else {
            // props.getProjects()
            // props.close()
            popupContextData.setPreloader(false)
            getPlan()
        }
    }

    useEffect(() => {
        getLinks()
        getPlan()
        // eslint-disable-next-line
    }, [])

    return (
        <div className='admin-company__project-item-content'>
            <div className='admin-company__users'>
                <div className='admin-company__users-title'>ПОЛЬЗОВАТЕЛИ</div>

                <div className='admin-company__users-button-add' onClick={() => setUserAdd(true)} />

                {loading ? <Loading /> : null}

                {userAdd ? (
                    <UserAdd
                        companyId={props.companyId}
                        projectId={props.projectId}
                        close={() => setUserAdd(false)}
                        getLinks={getLinks}
                    />
                ) : null}

                {!loading && links !== undefined ? (
                    <>
                        <div className='admin-company__users-top-side'>
                            <div className='admin-company__users-top-side-cell admin-company__users-top-side-cell_280px'>
                                Пользователь:
                            </div>
                            <div className='admin-company__users-top-side-cell admin-company__users-top-side-cell_925px'>
                                Роль:
                            </div>
                            <div className='admin-company__users-top-side-cell'>Статус:</div>
                        </div>

                        <div className='admin-company__users-content'>
                            {Object.keys(links).map((linkId, index) => {
                                const link = links[linkId]

                                return (
                                    <UserItem
                                        key={index}
                                        id={link._id}
                                        active={link.active}
                                        userName={link.userId.name}
                                        userLastName={link.userId.lastName}
                                        userRole={link.userId.role.name}
                                        projectId={props.projectId}
                                        getLinks={getLinks}
                                    />
                                )
                            })}
                        </div>
                    </>
                ) : null}
            </div>

            <DesktopPlan title='План' plan={plan} isControl={true} onSavePlan={onSavePlan} />
        </div>
    )
}

export default UserList
