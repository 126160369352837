import React from "react"
import "./Counter.scss"

const Counter = props => {
    let refused = 0

    if (props.all) {
        refused = Math.round(props.refused * 100 / props.all)
    }

    return (
        <div className="replic__counts">
            <div className="replic__count-total"
                 data-width={props.replicaType && window.innerWidth > 767 && window.innerWidth < 1024  ? "Y" : null}
                 data-mobile = {props.replicaType && window.innerWidth < 767  ? "Y" : null}
            >
                <span className="replic__counts__text">
                    {props.replicaType === 1 ? (
                        <>Суммарное количество реплик в инициациях:</>
                    ) : (props.replicaType === 2 ? (
                        <>Суммарное количество реплик в реакциях:</>
                    ) : (
                        <>Всего:</>
                    ))}
                </span>
                <span className="replic__counts__number">{props.all ? props.all : 0}</span>
            </div>

            {props.replicaType !== 1 && props.replicaType !== 2 ? (
                <div className="replic__count-refused">
                    <span className="replic__counts__text">Отклоненных:</span>

                    <span className="replic__counts__number">{refused}%</span>
                </div>
            ) : null}
        </div>
    )
}

export default Counter
