import {getErrorData} from "./errors";
import axios from "axios";
import config from "../config/config";


export const updateAllowByUsers = async ({ projectId, allow, users }) => {
    const token = localStorage.getItem('token')


    const response = await axios.post(`${config.SERVER_BASE_URL}/users-in-project/allow`, {token, projectId, allow, users })
    return response.data.data;
}

export const apiUsersInProjects = async (projectId) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(`${config.SERVER_BASE_URL}/users-in-project?token=${token}&projectId=${projectId}`)
    return response.data.data;
}

export const apiUsersInProjectList = async (projectId) => {
    const links = []
    const token = localStorage.getItem('token')
    const response = await axios.get(`${config.SERVER_BASE_URL}/users-in-project?token=${token}&projectId=${projectId}`)

    if (response && response.data.status === 'success') {
        response.data.data.map(link => {
            return links[link._id] = {
                ...link
            }
        })
    }

    if (Object.keys(links).length > 0) {
        return {
            status: 'success',
            data: links,
        }
    } else {
        return getErrorData()
    }
}

export const apiUsersInProjectAdd = async (projectId, userId) => {
    const token = localStorage.getItem('token')
    const errors = []

    if (!projectId) {
        errors.push('Проект не выбран')
    }

    if (!userId) {
        errors.push('Пользователь не выбран')
    }

    if (errors.length) {
        return getErrorData(errors)
    } else {
        const response = await axios.post(`${config.SERVER_BASE_URL}/users-in-project`, {
            token,
            projectId,
            userId
        })

        if (response && response.data.status === 'success') {
            return {
                status: 'success',
            }
        } else {
            return getErrorData(response.data.errorText)
        }
    }
}

export const apiUsersInProjectEdit = async (linkId, active) => {
    const token = localStorage.getItem('token')
    const errors = []

    if (!linkId) {
        errors.push('Связь не найдена')
    }

    if (errors.length) {
        return getErrorData(errors)
    } else {
        const response = await axios.put(`${config.SERVER_BASE_URL}/users-in-project/${linkId}`, {
            token,
            active: (active ? "Y" : "N")
        })

        if (response && response.data.status === 'success') {
            return {
                status: 'success',
            }
        } else {
            return getErrorData(response.data.errorText)
        }
    }
}

export const apiUsersInProjectDelete = async (linkId, msg = '') => {
    const token = localStorage.getItem('token')
    const errors = []

    if (!linkId) {
        errors.push('Связь не найдена')
    }

    if (!msg || msg.length < 5) {
        errors.push('Укажите причину удаления')
    }

    if (errors.length) {
        return getErrorData(errors)
    } else {
        const response = await axios.delete(`${config.SERVER_BASE_URL}/users-in-project/${linkId}?token=${token}&msg=${msg}`)

        if (response && response.data.status === 'success') {
            return {
                status: 'success',
            }
        } else {
            return getErrorData(response.data.errorText)
        }
    }
}

export const apiUsersInProjectListAdd = async (roleId, companyId, projectId) => {
    const users = []
    const token = localStorage.getItem('token')
    const response = await axios.get(`${config.SERVER_BASE_URL}/users-in-project/users-for-add?token=${token}&roleId=${roleId}&companyId=${companyId}&projectId=${projectId}`)

    if (response && response.data.status === 'success') {
        response.data.data.map(user => {
            return users[user._id] = {
                ...user
            }
        })
    }

    if (Object.keys(users).length > 0) {
        return {
            status: 'success',
            data: users,
        }
    } else {
        return getErrorData()
    }
}
