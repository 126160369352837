import React from 'react'
import classes from '../DesktopPlan.module.scss'
import SelectCustom from '../SelectCustom/SelectCustom'

function ControlQuantity({ activeValue, onSave, typePlan }) {
    return (
        <div className={classes.ControlQuantity}>
            <p className={classes.ControlQuantityTitle}>Количество последних периодов для показа:</p>

            <SelectCustom placeholder={''} activeValue={activeValue} onChange={(obj) => onSave(typePlan, obj)} />
        </div>
    )
}

export default ControlQuantity
