import { useState } from 'react'
import anime from 'animejs'

const getControl = (id) => {
    const item = document.querySelector(`.js-desktopPlan-rowInner[data-id="${id}"]`)
    const control = item.querySelector('.js-desktopPlan-rowInner-mobailControls')

    return control
}

export const useMobailControl = () => {
    const [activeInnerRow, setActiveInnerRow] = useState(null)

    const onShowMobailControl = (id, position) => {
        const control = getControl(id)

        if (position && position === 'left') {
            anime({
                targets: control,
                duration: 250,
                easing: 'easeInOutQuad',
                translateX: ['-100%', '0%']
            })

            return
        }

        anime({
            targets: control,
            duration: 250,
            easing: 'easeInOutQuad',
            translateX: ['100%', '0%']
        })
    }

    const onHiddenMobailControl = (id, position) => {
        const control = getControl(id)

        if (position) {
            anime({
                targets: control,
                duration: 250,
                easing: 'easeInOutQuad',
                translateX: ['0%', '-100%']
            })

            return
        }

        anime({
            targets: control,
            duration: 250,
            easing: 'easeInOutQuad',
            translateX: ['0%', '100%']
        })
    }

    const onChangeActiveInnerRow = (obj) => {
        setActiveInnerRow(obj)
    }

    return { onShowMobailControl, onHiddenMobailControl, activeInnerRow, onChangeActiveInnerRow }
}
