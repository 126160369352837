import React from 'react'
import classes from './TextTile.module.scss'
import InputEditText from '../../InputEditText/InputEditText'
import ListMedia from '../../ListMedia/ListMedia'

export const TextTile = ({
                             activeEdit,
                             onChangeStatusEdit,
                             description,
                             onChange,
                             documents,
                             onBlur
                         }) => {

    return (
        <>


            <p className={classes.TileText}>
                {![...description].every((item) =>  ["", " ", "\n"].includes(item)) || activeEdit === 'description' ?  null : <p onClick={() => onChangeStatusEdit('description')} style={{
                fontWeight: 400,
                fontSize: 16,
                color: '#94A2B6',
            }}>Редактировать описание</p> }

                {activeEdit === 'description' ?
                    <InputEditText
                        id={'description'}
                        onChange={onChange}
                        min={0}
                        max={255}
                        style={{
                            fontWeight: 400,
                            fontSize: 16,
                            color: '#94A2B6',
                            textDecoration: 'none',
                            border: 'none',
                            overflow: 'none',
                            outline: 'none',

                            boxShadow: 'none',
                            resize: 'none'
                        }}
                        value={description}
                        onBlur={onBlur}
                    />
                    : <a
                        onClick={() =>{
                            onChangeStatusEdit('description')
                        }}
                        className={classes.Description}>{description}</a>}
                  <ListMedia data={documents} />
            </p>
        </>
    )
}
