import React, { useState } from 'react'
import classes from './Table.module.scss'
import cn from 'classnames'
import IconYandex from '../../../../icons/IconYandex'
import IconGoogle from '../../../../icons/IconGoogle'
import IconCheckedBig from '../../../../icons/IconCheckedBig'
import IconArrowDownMini from '../../../../icons/IconArrowDownMini'
import IconDelete from '../../../../icons/IconDelete'

function Table({ list, setList }) {
    const [selectedRowIndex, setSelectedRowIndex] = useState(null)

    const onChangeList = (rowIndex, value) => {
        const newArr = list.map((item, index) => {
            if (index === rowIndex) {
                return { ...item, frequency: value }
            }

            return item
        })

        setList(newArr)
    }

    const onOpenControlRow = (index) => {
        setSelectedRowIndex(index)
    }

    const onCloseControlRow = () => {
        setSelectedRowIndex(null)
    }

    const onDeleteRow = (rowIndex) => {
        const newArr = list.filter((item, index) => index !== rowIndex)
        setSelectedRowIndex(null)
        setList(newArr)
    }

    return (
        <div className={classes.Table}>
            <div className={classes.TableHeader}>
                <div className={classes.TableCol} data-type='request'>
                    Запрос:
                </div>

                <div className={classes.TableCol} data-type='system'>
                    ПС:
                </div>

                <div className={classes.TableCol} data-type='size'>
                    ТОП:
                </div>

                <div className={classes.TableCol} data-type='region'>
                    Регион:
                </div>

                <div className={classes.TableCol} data-type='frequency'>
                    Частот:
                </div>
            </div>

            {list?.length > 0 && (
                <div className={classes.TableList}>
                    {list.map((item, index) => (
                        <div className={cn(classes.TableRow, { [classes.Active]: selectedRowIndex === index })} key={index}>
                            <div className={classes.TableCol} data-type='request'>
                                <button className={classes.BtnOpenRow} onClick={() => onOpenControlRow(index)}>
                                    <span />
                                    <span />
                                    <span />
                                </button>

                                <p>{item?.searchQuery}</p>

                                {selectedRowIndex === index && (
                                    <input
                                        type='text'
                                        value={item?.frequency}
                                        onChange={(e) => onChangeList(index, e.currentTarget.value)}
                                    />
                                )}
                            </div>

                            <div className={classes.TableCol} data-type='system'>
                                {item?.searchSystem === 'Яндекс' && <IconYandex />}
                                {item?.searchSystem === 'Google' && <IconGoogle />}
                            </div>

                            <div className={classes.TableCol} data-type='size'>
                                {item?.type.split(' ')[1]}
                            </div>

                            <div className={classes.TableCol} data-type='region'>
                                {item?.region}
                            </div>

                            <div className={classes.TableCol} data-type='frequency'>
                                {item?.frequency}
                            </div>

                            <div className={classes.TableRowControl}>
                                <button data-type='save' onClick={onCloseControlRow}>
                                    <IconCheckedBig />
                                </button>

                                <button data-type='close' onClick={onCloseControlRow}>
                                    <IconArrowDownMini />
                                </button>

                                <button data-type='delete' onClick={() => onDeleteRow(index)}>
                                    <IconDelete />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Table
