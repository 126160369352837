import React from 'react'

function IconCancel() {
    return (
        <svg width={27} height={26} viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <mask id='path-1-inside-1_1539_6753' fill='white'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M25.6787 0.200439H1.31617C0.867275 0.200439 0.5 0.55345 0.5 0.984907V24.416C0.5 24.8474 0.867275 25.2004 1.31617 25.2004H25.6838C26.1327 25.2004 26.5 24.8474 26.5 24.416V0.984907C26.4949 0.55345 26.1327 0.200439 25.6787 0.200439ZM24.8626 23.6364H2.13233V7.5254H24.8677V23.6364H24.8626ZM2.13233 5.95647H24.8626H24.8677V1.76937H2.13233V5.95647Z'
                />
            </mask>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M25.6787 0.200439H1.31617C0.867275 0.200439 0.5 0.55345 0.5 0.984907V24.416C0.5 24.8474 0.867275 25.2004 1.31617 25.2004H25.6838C26.1327 25.2004 26.5 24.8474 26.5 24.416V0.984907C26.4949 0.55345 26.1327 0.200439 25.6787 0.200439ZM24.8626 23.6364H2.13233V7.5254H24.8677V23.6364H24.8626ZM2.13233 5.95647H24.8626H24.8677V1.76937H2.13233V5.95647Z'
                fill='white'
            />
            <path
                d='M26.5 0.984907H26.8L26.8 0.981361L26.5 0.984907ZM2.13233 23.6364H1.83233V23.9364H2.13233V23.6364ZM2.13233 7.5254V7.2254H1.83233V7.5254H2.13233ZM24.8677 7.5254H25.1677V7.2254H24.8677V7.5254ZM24.8677 23.6364V23.9364H25.1677V23.6364H24.8677ZM2.13233 5.95647H1.83233V6.25647H2.13233V5.95647ZM24.8677 5.95647V6.25647H25.1677V5.95647H24.8677ZM24.8677 1.76937H25.1677V1.46937H24.8677V1.76937ZM2.13233 1.76937V1.46937H1.83233V1.76937H2.13233ZM1.31617 0.500439H25.6787V-0.0995606H1.31617V0.500439ZM0.8 0.984907C0.8 0.730225 1.02165 0.500439 1.31617 0.500439V-0.0995606C0.712901 -0.0995606 0.2 0.376675 0.2 0.984907H0.8ZM0.8 24.416V0.984907H0.2V24.416H0.8ZM1.31617 24.9004C1.02165 24.9004 0.8 24.6707 0.8 24.416H0.2C0.2 25.0242 0.712901 25.5004 1.31617 25.5004V24.9004ZM25.6838 24.9004H1.31617V25.5004H25.6838V24.9004ZM26.2 24.416C26.2 24.6707 25.9784 24.9004 25.6838 24.9004V25.5004C26.2871 25.5004 26.8 25.0242 26.8 24.416H26.2ZM26.2 0.984907V24.416H26.8V0.984907H26.2ZM25.6787 0.500439C25.9763 0.500439 26.197 0.729043 26.2 0.988453L26.8 0.981361C26.7928 0.377856 26.2892 -0.0995606 25.6787 -0.0995606V0.500439ZM2.13233 23.9364H24.8626V23.3364H2.13233V23.9364ZM1.83233 7.5254V23.6364H2.43233V7.5254H1.83233ZM24.8677 7.2254H2.13233V7.82541H24.8677V7.2254ZM25.1677 23.6364V7.5254H24.5677V23.6364H25.1677ZM24.8626 23.9364H24.8677V23.3364H24.8626V23.9364ZM24.8626 5.65647H2.13233V6.25647H24.8626V5.65647ZM24.8677 5.65647H24.8626V6.25647H24.8677V5.65647ZM24.5677 1.76937V5.95647H25.1677V1.76937H24.5677ZM2.13233 2.06937H24.8677V1.46937H2.13233V2.06937ZM2.43233 5.95647V1.76937H1.83233V5.95647H2.43233Z'
                fill='white'
                mask='url(#path-1-inside-1_1539_6753)'
            />
            <path fillRule='evenodd' clipRule='evenodd' d='M9.27051 15.8192V14.3317H17.2893V15.8192H9.27051Z' fill='white' />
            <path
                d='M13.2625 4.70626H22.0975C22.5463 4.70626 22.9136 4.35325 22.9136 3.9218C22.9136 3.49034 22.5463 3.13733 22.0975 3.13733H13.2625C12.8136 3.13733 12.4463 3.49034 12.4463 3.9218C12.4463 4.35325 12.8085 4.70626 13.2625 4.70626Z'
                fill='white'
            />
            <ellipse cx='5.05032' cy='3.92178' rx='0.811065' ry='0.779565' fill='white' />
            <ellipse cx='7.72806' cy='3.92178' rx='0.811065' ry='0.779565' fill='white' />
            <ellipse cx='10.4058' cy='3.92178' rx='0.811065' ry='0.779565' fill='white' />
        </svg>
    )
}

export default IconCancel
