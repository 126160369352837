import React from 'react'

function IconCloseMini() {
    return (
        <svg width={10} height={10} viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M2.22947 0.744166L0.744542 2.22909L3.59065 5.0752L0.744542 7.9213L2.22947 9.40622L5.07557 6.56012L7.92168 9.40622L9.4066 7.9213L6.5605 5.0752L9.4066 2.22909L7.92168 0.744167L5.07557 3.59027L2.22947 0.744166Z'
                fill='#E00C3C'
            />
        </svg>
    )
}

export default IconCloseMini
