import React from 'react'

function IconUpload() {
    return (
        <svg width={27} height={26} viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <mask id='path-1-inside-1_1508_4352' fill='white'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M25.2031 0.5H0.84058C0.391689 0.5 0.0244141 0.85301 0.0244141 1.28447V24.7155C0.0244141 25.147 0.391689 25.5 0.84058 25.5H25.2082C25.6571 25.5 26.0244 25.147 26.0244 24.7155V1.28447C26.0193 0.85301 25.6571 0.5 25.2031 0.5ZM24.3875 23.936H1.65723V7.82495H24.3926V23.936H24.3875ZM1.65723 6.256H24.3875H24.3926V2.06891H1.65723V6.256Z'
                />
            </mask>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M25.2031 0.5H0.84058C0.391689 0.5 0.0244141 0.85301 0.0244141 1.28447V24.7155C0.0244141 25.147 0.391689 25.5 0.84058 25.5H25.2082C25.6571 25.5 26.0244 25.147 26.0244 24.7155V1.28447C26.0193 0.85301 25.6571 0.5 25.2031 0.5ZM24.3875 23.936H1.65723V7.82495H24.3926V23.936H24.3875ZM1.65723 6.256H24.3875H24.3926V2.06891H1.65723V6.256Z'
                fill='white'
            />
            <path
                d='M26.0244 1.28447H26.3244L26.3244 1.28092L26.0244 1.28447ZM1.65723 23.936H1.35723V24.236H1.65723V23.936ZM1.65723 7.82495V7.52495H1.35723V7.82495H1.65723ZM24.3926 7.82495H24.6926V7.52495H24.3926V7.82495ZM24.3926 23.936V24.236H24.6926V23.936H24.3926ZM1.65723 6.256H1.35723V6.556H1.65723V6.256ZM24.3926 6.256V6.556H24.6926V6.256H24.3926ZM24.3926 2.06891H24.6926V1.76891H24.3926V2.06891ZM1.65723 2.06891V1.76891H1.35723V2.06891H1.65723ZM0.84058 0.8H25.2031V0.2H0.84058V0.8ZM0.324414 1.28447C0.324414 1.02979 0.546063 0.8 0.84058 0.8V0.2C0.237315 0.2 -0.275586 0.676235 -0.275586 1.28447H0.324414ZM0.324414 24.7155V1.28447H-0.275586V24.7155H0.324414ZM0.84058 25.2C0.546063 25.2 0.324414 24.9702 0.324414 24.7155H-0.275586C-0.275586 25.3238 0.237315 25.8 0.84058 25.8V25.2ZM25.2082 25.2H0.84058V25.8H25.2082V25.2ZM25.7244 24.7155C25.7244 24.9702 25.5028 25.2 25.2082 25.2V25.8C25.8115 25.8 26.3244 25.3238 26.3244 24.7155H25.7244ZM25.7244 1.28447V24.7155H26.3244V1.28447H25.7244ZM25.2031 0.8C25.5007 0.8 25.7214 1.0286 25.7244 1.28801L26.3244 1.28092C26.3173 0.677417 25.8136 0.2 25.2031 0.2V0.8ZM1.65723 24.236H24.3875V23.636H1.65723V24.236ZM1.35723 7.82495V23.936H1.95723V7.82495H1.35723ZM24.3926 7.52495H1.65723V8.12495H24.3926V7.52495ZM24.6926 23.936V7.82495H24.0926V23.936H24.6926ZM24.3875 24.236H24.3926V23.636H24.3875V24.236ZM24.3875 5.956H1.65723V6.556H24.3875V5.956ZM24.3926 5.956H24.3875V6.556H24.3926V5.956ZM24.0926 2.06891V6.256H24.6926V2.06891H24.0926ZM1.65723 2.36891H24.3926V1.76891H1.65723V2.36891ZM1.95723 6.256V2.06891H1.35723V6.256H1.95723Z'
                fill='white'
                mask='url(#path-1-inside-1_1508_4352)'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.94492 14.2541L9.20117 12.9659L12.7145 10.9375L12.7413 10.9839L12.7681 10.9375L16.2814 12.9659L15.5377 14.2541L13.5563 13.1102V19.3415H12.0527V13.0372L9.94492 14.2541Z'
                fill='white'
            />
            <path
                d='M12.7869 5.00595H21.6219C22.0708 5.00595 22.438 4.65294 22.438 4.22148C22.438 3.79002 22.0708 3.43701 21.6219 3.43701H12.7869C12.338 3.43701 11.9707 3.79002 11.9707 4.22148C11.9707 4.65294 12.3329 5.00595 12.7869 5.00595Z'
                fill='white'
            />
            <ellipse cx='4.57474' cy='4.22146' rx='0.811065' ry='0.779565' fill='white' />
            <ellipse cx='7.25247' cy='4.22146' rx='0.811065' ry='0.779565' fill='white' />
            <ellipse cx='9.93021' cy='4.22146' rx='0.811065' ry='0.779565' fill='white' />
        </svg>
    )
}

export default IconUpload
