import React from "react"
import "./ProjectSettingsFieldText.scss"
import Input from "../../../../UI/Input/Input";

const ProjectSettingsFieldText = props => {
    return (
        <div className="search-and-settings__fields-unit">
            <div className="search-and-settings__field-checkbox">
                <input
                    type="checkbox"
                    id={`in-replica-${props.id}`}
                    value="Y"
                    onChange={props.changeCheckbox}
                    checked={props.viewReplica}
                />

                <label htmlFor={`in-replica-${props.id}`} />
            </div>

            <div className="search-and-settings__field-type">{props.fieldTypeName}</div>

            <div className="search-and-settings__field-value">
                <div className="search-and-settings__field-value-type-mobile">{props.fieldTypeName}</div>

                <div className="search-and-settings__field-input-text">
                    <Input
                        type="text"
                        name={props.name}
                        value={props.value}
                        placeholder={props.placeholder}
                        onChange={props.change}
                        disabled={props.disabled}
                    />

                    <div
                        className="search-and-settings__field-button-remove search-and-settings__field-button-remove_alone"
                        onClick={props.delete}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProjectSettingsFieldText
