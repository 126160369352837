import classes from './TextArea.module.scss'
import cn from 'classnames'
import React from 'react'

export const TextArea = ({ disable, value, onChange, min = 0, max = 255 }) => {
    return (
        <div className={cn(classes.Field)}>
            <span className={classes.FieldTitle}>
                Описание раздела:
            </span>
            <textarea
                minLength={min}
                maxLength={max}
                value={value}
                onChange={onChange}
                className={cn(classes.FieldInput, classes.FieldInputBorderAll)}
                disabled={disable}
            />
        </div>
    )
}
