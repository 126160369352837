export const getTypeHeader = (width) => {
    if (width < 768) {
        return 'mobail'
    }

    if (width >= 1770) {
        return 'desctop'
    }

    if (width <= 1280 && width >= 1024) {
        return 'desctop'
    }

    if (width <= 1023 && width >= 768) {
        return 'tablet'
    }

    return 'desctop'
}
