import React from "react"
import classes from "./InsideConfirmationAction.module.scss"
import TextArea from "../../../../../Desktop/UI/TextArea/TextArea"
import ButtonSelector from "../../../../../UI/ButtonSelector/ButtonSelector"

export default function InsideConfirmationAction(props) {
    return (
        <div className={classes.InsideConfirmationAction}>
            <div className={classes.Text}
                 data-color={props.dataColorTextArea}
            >{props.text}</div>

            <div className={classes.Subtext}>{props.subtext}</div>

            <div className={classes.BottomWrapper}>
                <div className={classes.InputWrapper}>
                    <TextArea
                        dataSmall={props.dataSmallTextArea}
                        color={props.dataColorTextArea}
                        onChangeField={props.onChangeMsg}
                        textValue={props.msg}
                    />
                </div>

                <div className={classes.BtnWrapper}>
                    <ButtonSelector
                        text={props.buttonText}
                        color={props.buttonColor}
                        click={props.clickFinish}
                    />
                </div>
            </div>
        </div>
    )
}