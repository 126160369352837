export const headerDataDesctop = [
    'Период:',
    'Остаток пред.:',
    'Требуется:',
    'Остаток:',
    'Написано:',
    'Согласовано:',
    'Размещено:'
]
export const headerDataTablet = ['Период:', 'Пред. пер:', 'Требуется:', 'Остаток:', 'Написано:', 'Согл-но:', 'Размещено:']
export const headerDataInner = ['Площадка:', 'Пред. пер:', 'Требуется:', 'Остаток:', 'Написано:', 'Согл-но:', 'Размещено:']
