import React, { useState } from 'react'
import classes from './RowInner.module.scss'
import cn from 'classnames'
import DeleteRow from '../../UI/DeleteRow/DeleteRow'
import AddRow from '../../UI/AddRow/AddRow'
import IconPencil from '../../../../icons/IconPencil'
import TextField from '../../UI/TextField/TextField'
import { useMobailControl } from './useMobailControl'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import IconCheck from '../../../../icons/IconCheck'
import IconFolder from '../IconFolder'
import IconCopyLink from '../IconCopyLink'
import UrlDropDown from '../UrlDropDown/UrlDropDown'

function RowInner({
    item,
    isControl,
    isLast,
    activeModalId,
    onAddInnerRow,
    rowId,
    rowInnerId,
    onDeleteInnerRow,
    onChangeFieldsInnerRow,
    onSavePlan,
    isDisabled,
    isInnerLvlTwo,
    typePlan,
    onActiveModalId
}) {
    const { width } = useWindowSize()
    const { onShowMobailControl, onHiddenMobailControl, activeInnerRow, onChangeActiveInnerRow } = useMobailControl()

    const [action, setAction] = useState(null) // 'delete' | 'edit'

    const POSITION_MOBAIL_CONTROL = width < 768 ? 'left' : null

    const handlerDeleteInnerRow = (obj) => {
        onChangeActiveInnerRow(obj)
        setAction('delete')
    }

    const handlerConfirmDeleteInnerRow = (answer) => {
        if (answer && answer === 'no') {
            onHiddenMobailControl(activeInnerRow.id, POSITION_MOBAIL_CONTROL)
            onChangeActiveInnerRow(null)
            setAction(null)
            return
        }

        onHiddenMobailControl(activeInnerRow.id, POSITION_MOBAIL_CONTROL)

        onDeleteInnerRow(rowId, rowInnerId, typePlan, isInnerLvlTwo, activeInnerRow.id)
        onChangeActiveInnerRow(null)
        setAction(null)
    }

    const handlerEditInnerRow = () => {
        setAction('edit')
    }

    const handlerSaveInnerRow = () => {
        onHiddenMobailControl(item.id, POSITION_MOBAIL_CONTROL)
        onChangeActiveInnerRow(null)
        setAction(null)
        onSavePlan()
    }

    return (
        <div
            data-id={item.id}
            className={cn(classes.Item, 'js-desktopPlan-rowInner', {
                [classes.Control]: isControl,
                [classes.Active]: activeInnerRow && activeInnerRow.id === item.id,
                [classes.DeleteType]: action && action === 'delete',
                [classes.EditType]: action && action === 'edit',
                [classes.IsDisabled]: isDisabled,
                [classes.IsInnerLvlTwo]: isInnerLvlTwo
            })}
        >
            {!isDisabled && item?.url?.value && (
                <button className={classes.BtnCopyLink} onClick={() => onActiveModalId(item.id)}>
                    <IconCopyLink className={classes.IconCopyLink} />
                </button>
            )}

            {item?.url?.value && activeModalId === item.id ? (
                <UrlDropDown href={item.url.value} isOpen={activeModalId === item.id} onClose={() => onActiveModalId(null)} />
            ) : null}

            <div className={classes.Columns}>
                <div className={cn(classes.ItemCol, classes.Name)}>
                    {!isControl && item.name.value}

                    {isControl && (
                        <div className={classes.ItemColInner}>
                            <div className={classes.ItemValue}>
                                {action && action === 'delete' ? `Удалить площадку ${item.name.value}?` : item.name.value}
                            </div>

                            <TextField
                                className={classes.TextField}
                                value={item.name.value}
                                onChange={(e) =>
                                    onChangeFieldsInnerRow(
                                        rowId,
                                        rowInnerId,
                                        'name',
                                        e.currentTarget.value,
                                        isInnerLvlTwo,
                                        item.id
                                    )
                                }
                                isEdit={item.name.isEdit}
                                onSavePlan={onSavePlan}
                            />

                            <IconPencil className={classes.IconPencil} />

                            {isDisabled && <IconFolder className={classes.IconFolder} />}
                        </div>
                    )}
                </div>

                <div className={`${classes.ItemCol} ${classes.RemainsOld}`}>
                    {!isControl && item.remainsOld.value}

                    {isControl && (
                        <div className={classes.ItemColInner}>
                            <div className={classes.ItemValue}>
                                {item.remainsOld.value}
                                <IconPencil />
                            </div>

                            <TextField
                                className={classes.TextField}
                                value={item.remainsOld.value}
                                onChange={(e) =>
                                    onChangeFieldsInnerRow(
                                        rowId,
                                        rowInnerId,
                                        'remainsOld',
                                        e.currentTarget.value,
                                        isInnerLvlTwo,
                                        item.id
                                    )
                                }
                                isEdit={item.remainsOld.isEdit}
                                type='number'
                                onSavePlan={onSavePlan}
                            />
                        </div>
                    )}
                </div>

                <div className={`${classes.ItemCol} ${classes.Needed}`}>
                    {!isControl && item.needed.value}

                    {isControl && (
                        <div className={classes.ItemColInner}>
                            <div className={classes.ItemValue}>
                                {item.needed.value}
                                <IconPencil />
                            </div>

                            <TextField
                                className={classes.TextField}
                                value={item.needed.value}
                                onChange={(e) =>
                                    onChangeFieldsInnerRow(
                                        rowId,
                                        rowInnerId,
                                        'needed',
                                        e.currentTarget.value,
                                        isInnerLvlTwo,
                                        item.id
                                    )
                                }
                                isEdit={item.needed.isEdit}
                                type='number'
                                onSavePlan={onSavePlan}
                            />
                        </div>
                    )}
                </div>

                <div className={classes.ItemCol}>
                    {!isControl && item.remains.value}

                    {isControl && (
                        <div className={classes.ItemColInner}>
                            <div className={classes.ItemValue}>
                                {item.remains.value}
                                <IconPencil />
                            </div>

                            <TextField
                                className={classes.TextField}
                                value={item.remains.value}
                                onChange={(e) =>
                                    onChangeFieldsInnerRow(
                                        rowId,
                                        rowInnerId,
                                        'remains',
                                        e.currentTarget.value,
                                        isInnerLvlTwo,
                                        item.id
                                    )
                                }
                                isEdit={item.remains.isEdit}
                                type='number'
                                onSavePlan={onSavePlan}
                            />
                        </div>
                    )}
                </div>

                <div className={classes.ItemCol}>{item.used.value}</div>
                <div className={classes.ItemCol}>{item.agreed.value}</div>
                <div className={`${classes.ItemCol}`}>{item.posted.value}</div>
            </div>

            {isControl && isLast && !isInnerLvlTwo && (
                <AddRow className={classes.AddRow} onClick={() => onAddInnerRow(rowId)} isMini />
            )}

            {isControl && (
                <DeleteRow
                    className={classes.DeleteRow}
                    onClick={() => {
                        onDeleteInnerRow(rowId, rowInnerId, typePlan, isInnerLvlTwo, item.id)
                    }}
                    isMini
                />
            )}

            {isControl && !isDisabled && (
                <button
                    className={classes.ShowBtns}
                    onClick={() => {
                        onShowMobailControl(item.id, POSITION_MOBAIL_CONTROL)
                        onChangeActiveInnerRow(item)
                    }}
                >
                    <span />
                    <span />
                    <span />
                </button>
            )}

            {isDisabled && <IconFolder className={`${classes.IconFolder} ${classes.Mobail}`} />}

            {isControl && (
                <div
                    className={cn(classes.MobailControls, ' js-desktopPlan-rowInner-mobailControls ', {
                        [classes.EditType]: action && action === 'edit'
                    })}
                >
                    <div
                        className={cn(classes.BtnWrap, {
                            [classes.DeleteType]: action && action === 'delete',
                            [classes.EditType]: action && action === 'edit'
                        })}
                    >
                        <button
                            className={classes.ShowBtns}
                            onClick={() => {
                                onHiddenMobailControl(item.id, POSITION_MOBAIL_CONTROL)
                                onChangeActiveInnerRow(null)
                                setAction(null)
                            }}
                        >
                            <span />
                            <span />
                            <span />
                        </button>

                        {!action && <button onClick={handlerEditInnerRow}>Редактировать</button>}

                        {action && action === 'delete' && <button onClick={() => handlerConfirmDeleteInnerRow('no')}>Нет</button>}

                        {action && action === 'edit' && (
                            <button onClick={handlerSaveInnerRow}>
                                <IconCheck />
                            </button>
                        )}
                    </div>

                    <div className={cn(classes.BtnWrap, { [classes.DeleteType]: action && action === 'delete' })}>
                        {!action && <button onClick={() => handlerDeleteInnerRow(item)}>Удалить</button>}
                        {action && action === 'delete' && <button onClick={() => handlerConfirmDeleteInnerRow('yes')}>Да</button>}
                    </div>
                </div>
            )}
        </div>
    )
}

export default RowInner
