import React, { useState } from 'react'

function generatorId(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
}

const getDefaultRow = () => {
    return {
        id: generatorId(100, 999999),
        dateStart: '',
        dateEnd: '',
        remainsOld: 0,
        needed: 0,
        items: [
            {
                id: generatorId(100, 999999),
                name: '',
                remainsOld: 0,
                needed: 0
            }
        ]
    }
}

const getDefaultInnerRow = () => {
    return {
        id: generatorId(100, 999999),
        name: '',
        remainsOld: 0,
        needed: 0
    }
}

export const useDesktopPlanStart = () => {
    const [items, setItems] = useState([getDefaultRow()])

    const prevDateEnd = items.length > 1 ? items[items.length - 2].dateEnd : null
    const isDisabledAddRow = !!(!items[items.length - 1]?.dateStart && !items[items.length - 1]?.dateEnd)

    const onAddRow = () => {
        let newArr = [...items]
        newArr.push(getDefaultRow())
        setItems(newArr)
    }

    const onAddInnerRow = (rowId) => {
        const newArr = items.map((item) => {
            if (item.id === rowId) {
                return { ...item, items: [...item.items, getDefaultInnerRow()] }
            }

            return item
        })

        setItems(newArr)
    }

    const onDeleteRow = (id) => {
        let newArr = items.filter((item) => item.id !== id)

        if (!newArr.length) {
            newArr.push(getDefaultRow())
        }

        setItems(newArr)
    }

    const onDeleteInnerRow = (rowId, rowInnerId) => {
        const newArr = items.map((item) => {
            if (item.id === rowId) {
                let newSites = item.items.filter((site) => site.id !== rowInnerId)

                if (!newSites.length) {
                    newSites.push(getDefaultInnerRow())
                }

                return { ...item, items: newSites }
            }

            return item
        })

        setItems(newArr)
    }

    const onChangeFieldsInnerRow = (rowId, rowInnerId, nameField, valueField) => {
        const IS_NUMBER_TYPE = nameField === 'remainsOld' || nameField === 'needed'

        const newArr = items.map((item) => {
            if (item.id === rowId) {
                const newSitesArr = item.items.map((site) => {
                    if (site.id === rowInnerId) {
                        return { ...site, [nameField]: IS_NUMBER_TYPE ? +valueField : valueField }
                    }

                    return site
                })

                return { ...item, items: newSitesArr }
            }

            return item
        })

        setItems(newArr)
    }

    const onChangeFieldsRow = (rowId, nameField, valueField) => {
        const newArr = items.map((item) => {
            if (item.id === rowId) {
                return { ...item, [nameField]: valueField }
            }

            return item
        })

        setItems(newArr)
    }

    return {
        items,
        onAddRow,
        onDeleteRow,
        onAddInnerRow,
        onDeleteInnerRow,
        onChangeFieldsInnerRow,
        onChangeFieldsRow,
        prevDateEnd,
        isDisabledAddRow
    }
}
