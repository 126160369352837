import React, {useContext} from "react"
import "./UserDelete.scss"
import {apiUserDelete} from "../../../../../api/users"
import {PopupContext} from "../../../../../context/popup/popupContext"

const UserDelete = props => {
    const popupContextData = useContext(PopupContext)

    const userDelete = async (event, userId) => {
        event.preventDefault()

        popupContextData.setPreloader(true)
        const data = await apiUserDelete(userId)

        if (data.status === 'fail') {
            popupContextData.setPreloader(false)
            alert(data.errorText)
        } else {
            props.close()
            props.getUsers()
        }
    }

    return (
        <div className="admin-company__user-manipulation admin-company__user-manipulation_deactivating">
            <div className="admin-company__user-manipulation-top-side">
                <div className="admin-company__user-manipulation-top-side-name">Удаление учетной записи</div>

                <div
                    className="admin-company__user-manipulation-top-side-button"
                    onClick={props.close}
                />
            </div>

            <div className="admin-company__user-manipulation-content">
                <div className="admin-company__user-manipulation-text">
                    Вы действительно хотите удалить пользователя <span>{props.lastName} {props.name} — {props.role.name}</span>?
                </div>

                <div className="admin-company__user-manipulation-buttons">
                    <div className="admin-company__user-manipulation-button admin-company__user-manipulation-button_mobile-width-88px admin-company__user-manipulation-button_blue">
                        <button onClick={props.close}>Нет</button>
                    </div>

                    <div className="admin-company__user-manipulation-button admin-company__user-manipulation-button_mobile-width-88px admin-company__user-manipulation-button_red">
                        <button onClick={(event) => userDelete(event, props.id)}>Да</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDelete