import React from 'react'

function IconComment({ className }) {
    return (
        <svg className={className} width={25} height={26} viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                id='Shape'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 12.8075C0 6.05253 5.495 0.592529 12.215 0.592529C18.935 0.592529 24.43 6.05253 24.395 12.7725C24.395 19.5275 18.9 24.9875 12.18 24.9875C10.85 24.9875 9.52 24.7775 8.225 24.3225L2.03 25.4075H1.855C1.54 25.4075 1.26 25.2675 1.05 25.0225C0.805 24.7425 0.77 24.3225 0.91 23.9725L2.52 20.1925C1.82 19.2825 1.26 18.3025 0.84 17.2525C0.28 15.8525 0 14.3475 0 12.8075ZM8.715 22.2925C9.835 22.7125 11.025 22.9225 12.215 22.9225C17.78 22.9225 22.295 18.4075 22.295 12.8425C22.295 7.27753 17.745 2.72753 12.18 2.72753C6.615 2.72753 2.065 7.27753 2.065 12.8425C2.065 14.1025 2.31 15.3625 2.765 16.5175C3.15 17.5675 3.745 18.5125 4.48 19.3875C4.76 19.6675 4.795 20.1225 4.655 20.4725L3.57 23.0625L8.155 22.2575C8.365 22.2225 8.54 22.2225 8.715 22.2925Z'
                fill='#D0DDEF'
            />
        </svg>
    )
}

export default IconComment
