import axios from "axios";
import config from "../config/config";
import {getErrorData} from "./errors";

export const apiRoleList = async (settings = {}, filter = {}) => {
    const roles = []
    const token = localStorage.getItem('token')
    const filterData = []

    Object.keys(filter).map(filterId => {
        const filterItem = filter[filterId]

        if (filterItem.name && filterItem.value) {
            return filterData.push(`f-${filterItem.name}=${filterItem.value}`)
        } else {
            return true
        }
    })

    const response = await axios.get(`${config.SERVER_BASE_URL}/roles?token=${token}${settings.onlyManagers === "Y" ? "&onlyManagers=Y" : ""}${filterData.length ? '&' + filterData.join('&') : ''}`)

    if (response && response.data.status === 'success') {
        response.data.data.map(role => {
            return roles[role._id] = {
                ...role,
                openRole: false,
            }
        })
    }

    if (Object.keys(roles).length > 0) {
        return {
            status: 'success',
            data: roles,
        }
    } else {
        return getErrorData()
    }
}