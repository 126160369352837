import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter} from "react-router-dom"
import {AuthState} from "./context/auth/authState"
import {PopupState} from "./context/popup/popupState"

const app = (
    <BrowserRouter>
        <AuthState>
            <PopupState>
                <App />
            </PopupState>
        </AuthState>
    </BrowserRouter>
)

ReactDOM.render(
    app,
  document.getElementById('root')
);

serviceWorker.unregister();
