import React from 'react'

function IconDelete({ className, onClick }) {
    return (
        <svg
            className={className}
            onClick={onClick}
            width={18}
            height={20}
            viewBox='0 0 18 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6 3V2H11V3H6ZM4 3V1.68906C4 0.675992 4.89592 0 5.72692 0H11.383C12.4252 0 13 0.894935 13 1.68906V3H18V5H12.5658H5.47529H0V3H4ZM4 6V17.7997C4 17.9251 4.10085 18 4.17739 18H13.8226C13.8992 18 14 17.9251 14 17.7997V6H16V17.7997C16 18.9932 15.0399 20 13.8226 20H4.17739C2.96015 20 2 18.9932 2 17.7997V6H4ZM6 16V6H8V16H6ZM10 6V16H12V6H10Z'
                fill='#E00C3C'
            />
        </svg>
    )
}

export default IconDelete
