import classes from './DefaultTilesBlock.module.scss'
import React, { useEffect, useState } from 'react'
import { Tile } from '../Tile/Tile'

export const DefaultTilesBlock = ({onTapUploadFiles, onChange, tiles, size, setSize}) => {
    const [tileData, setTileData] = useState([])

    useEffect(() => {
        const newTilesData = []

        tiles.length > 0 && tiles.forEach((el) => {

            newTilesData.push({
                id: el._id,
                name: el.name,
                documents: el.documents,
                description: el.description,
                type: el.type === 1 ? 'documents' : 'text'
            })
        })

        setTileData(newTilesData)
    }, [tiles])

    return (
        <div className={classes.DefaultTiles}>
            {(tileData && tileData.length > 0) &&
                tileData.map((el, i) => (
                    <Tile
                        key={el.id}
                        onTapUploadFiles={() => onTapUploadFiles(el.id)}
                        onChange={(tileType, _, documents, description) => onChange(tileType, tiles[i]._id, documents, description)}
                        type={el.type}
                        data={el}
                        size={size}
                        setSize={setSize}
                    />
                ))
            }
        </div>
    )
}
