import React, { useContext, useEffect, useState } from 'react'
import './ProjectList.scss'
import ProjectAdd from '../ProjectAdd/ProjectAdd'
import { apiProjectList } from '../../../../../api/projects'
import ProjectItem from '../ProjectItem/ProjectItem'
import Loading from '../../../../UI/Loading/Loading'
import { PopupContext } from '../../../../../context/popup/popupContext'

const ProjectList = (props) => {
    const popupContextData = useContext(PopupContext)
    const [loading, setLoading] = useState(true)
    const [projects, setProjects] = useState()
    const [projectAdd, setProjectAdd] = useState(false)
    const accessLevel = localStorage.getItem('accessLevel')

    const getProjects = async () => {
        popupContextData.setPreloader(true)
        const projects = await apiProjectList(props.companyId, props.filter)

        if (projects.status === 'success') {
            setProjects(projects.data)
        } else {
            setProjects()
        }

        popupContextData.setPreloader(false)
        setLoading(false)
    }

    useEffect(() => {
        getProjects()
        // eslint-disable-next-line
    }, [])

    return (
        <div className='admin-company__company-item-content'>
            <div className='admin-company__projects'>
                <div className='admin-company__projects-title'>ПРОЕКТЫ</div>

                {accessLevel === '1' || accessLevel === '5' ? (
                    <div className='admin-company__projects-button-add' onClick={() => setProjectAdd(true)} />
                ) : null}

                {loading ? <Loading /> : null}

                {projectAdd ? (
                    <ProjectAdd
                        companyId={props.companyId}
                        close={() => {
                            setProjectAdd(false)
                        }}
                        getProjects={getProjects}
                    />
                ) : null}

                {!loading && projects !== undefined ? (
                    <>
                        <div className='admin-company__projects-top-side'>
                            <div className='admin-company__projects-top-side-cell'>Название проекта:</div>
                            <div className='admin-company__projects-top-side-cell'>Статус:</div>
                        </div>

                        <div className='admin-company__projects-content'>
                            {Object.keys(projects).map((projectId, index) => {
                                const project = projects[projectId]

                                return (
                                    <ProjectItem
                                        key={index}
                                        id={project._id}
                                        name={project.name}
                                        active={project.active}
                                        companyId={props.companyId}
                                        getProjects={getProjects}
                                    />
                                )
                            })}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    )
}

export default ProjectList
