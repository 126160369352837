import React, {useContext, useState} from "react"
import "./CompanyAdd.scss"
import {apiCompanyAdd} from "../../../../../api/companies"
import {PopupContext} from "../../../../../context/popup/popupContext"

const CompanyAdd = props => {
    const popupContextData = useContext(PopupContext)
    const [companyName, setCompanyName] = useState('')

    const companyAdd = async (event) => {
        event.preventDefault()

        popupContextData.setPreloader(true)
        const data = await apiCompanyAdd(companyName)

        if (data.status === 'fail') {
            popupContextData.setPreloader(false)
            alert(data.errorText)
        } else {
            props.close()
            props.getCompanies()
        }
    }

    return (
        <div className="admin-company__container">
            <div className="admin-company__company-manipulation admin-company__company-manipulation_creating">
                <div className="admin-company__company-manipulation-top-side">
                    <div className="admin-company__company-manipulation-top-side-name">Создание новой компании</div>

                    <div
                        className="admin-company__company-manipulation-top-side-button"
                        onClick={props.close}
                    />
                </div>

                <div className="admin-company__company-manipulation-content">
                    <div className="admin-company__company-manipulation-input-container">
                        <label htmlFor="company-name">Название компании:</label>
                        <input type="text" id="company-name" value={companyName} onChange={(event) => setCompanyName(event.target.value)} />
                    </div>

                    <div className="admin-company__company-manipulation-buttons">
                        <div className="admin-company__company-manipulation-button admin-company__company-manipulation-button_width-100 admin-company__company-manipulation-button_blue">
                            <button onClick={companyAdd}>Создать</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyAdd