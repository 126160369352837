import React from 'react'
import classes from './SelectBlock.module.scss'
import { Select } from '../UI/Select/Select'

export const SelectBlock = ({items, onChange}) => {
    return (
        <div className={classes.SelectBlock}>
            <Select
                items={items.companies}
                value={items.company}
                className={classes.CompanySelect}
                optionDefault={'Компания'}
                onChange={(el) => onChange('company', el)}
            />
            <Select
                items={items.projects}
                value={items.project}
                className={classes.ProjectSelect}
                optionDefault={'Проект'}
                onChange={(el) => onChange('project', el)}
            />
        </div>
    )
}