import React from 'react'
import './Checkbox.scss'

const Checkbox = (props) => {
    return (
        <>
            <input
                type='checkbox'
                id={`checkbox-${props.id}`}
                name={props.name}
                value={props.value}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled}
            />

            <label htmlFor={`checkbox-${props.id}`}>{props.label ? props.label : null}</label>
        </>
    )
}

export default Checkbox
