import React from "react"
import "./Textarea.scss"

const Textarea = props => {
    return (
        <textarea
            name={props.name}
            value={props.value}
            onChange={props.onChange}
        />
    )
}

export default Textarea