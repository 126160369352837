import React, {useState} from "react"
import cn from 'classnames'
import "./Search.scss"
import {NavLink} from "react-router-dom"
import Input from "../../../../UI/Input/Input"

const Search = props => {
    const [search, setSearch] = useState(props.search.value)
    const [searchOpen, setSearchOpen] = useState(false)

    const searchOpenMobile = () => {
        setSearchOpen(!searchOpen)
    }

    const searchCLasses = cn({
        'search-and-settings__search':true,
        'search-and-settings__search_open-mobile': searchOpen,
    });

     const triggerSearchMobile = cn({
        'search-and-settings__search-mobile':true,
        'search-and-settings__search-mobile_active': searchOpen
     });

    return (
        <div className="search-and-settings search-and-settings_users-mode search-and-settings_margin-bottom-0px">
            <div className="search-and-settings__top-side">
                <div className={searchCLasses}>
                    <input type="submit" value="" />

                    <Input
                        type={props.search.type}
                        name={props.search.name}
                        value={search}
                        placeholder={props.search.placeholder}
                        onChange={(event) => setSearch(event.target.value)}
                        onKeyPress={(event) => (event.key === 'Enter' ? props.filterInputChange(search, props.search.name) : null)}
                    />
                </div>

                <div className="search-and-settings__settings search-and-settings__settings_company-mode">
                    <div onClick={searchOpenMobile} className={triggerSearchMobile}/>
                    <div className="search-and-settings__tune-mobile"/>

                    <div className="search-and-settings__download">
                        <NavLink to="/admin/client" className="search-and-settings__download-text active"><span>Пользователи</span></NavLink>
                    </div>

                    <div className="search-and-settings__add-edit-replic padding-right-none">
                        <NavLink exact to="/admin" className="search-and-settings__add-edit-replic-text"><span>Компании и проекты</span></NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search
