import React from 'react'
import IconCloseMini from '../../../../icons/IconCloseMini'
import IconComment from '../../../../icons/IconComment'
import IconPending from '../../../../icons/IconPending'
import IconNegative from '../../../../icons/IconNegative'
import IconPositive from '../../../../icons/IconPositive'
import IconNeutral from '../../../../icons/IconNeutral'
import IconSend from '../../../../icons/IconSend'
import classes from './RowInner.module.scss'

function RowInner({ item, onAddComment, onDeleteComment, newComment, onChangeNewComment }) {
    return (
        <div className={classes.Wrap} data-tonality={item.typeChange}>
            <div className={classes.Row}>
                <div className={classes.Col}>
                    <div className={classes.ColTitle}>Тональность</div>

                    <div className={classes.ColValue}>
                        {item.info.tonalityCode === 'positive' && <IconPositive />}
                        {item.info.tonalityCode === 'negative' && <IconNegative />}
                        {item.info.tonalityCode === 'neutral' && <IconNeutral />}
                        {item.info.tonalityCode === 'pending' && <IconPending />}
                        {item.info.tonality}
                    </div>
                </div>

                <div className={classes.Col}>
                    <div className={classes.ColTitle}>Количество размещенных реплик в категории официальный представитель</div>
                    <div className={classes.ColValue}>{item?.info.representative}</div>
                </div>

                <div className={classes.Col}>
                    <div className={classes.ColTitle}>Количество размещенных реплик в категории агент влияния</div>
                    <div className={classes.ColValue}>{item?.info.agent}</div>
                </div>
            </div>

            {item?.comments?.length > 0 && (
                <div className={classes.Comments}>
                    {item?.comments.map((comment, commentIndex) => (
                        <div className={classes.Comment} key={commentIndex}>
                            <div className={classes.CommentHeader}>
                                <span>{comment.date}</span>
                                <span>{comment.name}</span>
                            </div>

                            <div className={classes.CommentText}>
                                {comment.text}

                                <button className={classes.CommentDelete} onClick={() => onDeleteComment(comment.id)}>
                                    <IconCloseMini />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <div className={classes.TextField}>
                <textarea
                    id={`screenRating-add-comment${item?.id}`}
                    value={newComment}
                    onChange={onChangeNewComment}
                    type='text'
                    placeholder=' '
                />

                <label htmlFor={`screenRating-add-comment${item?.id}`}>
                    <IconComment className={classes.IconComment} />
                    Введите комментарий
                </label>

                <button className={classes.BtnSend} onClick={() => onAddComment(item.id)}>
                    <IconSend className={classes.IconSend} />
                </button>
            </div>
        </div>
    )
}

export default RowInner
