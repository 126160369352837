import React from "react"
import "./ButtonEscape.scss"
import {Link} from "react-router-dom";

const ButtonEscape = () => {
    return (
        <Link to="/logout" className="button-escape"></Link>
    )
}

export default ButtonEscape