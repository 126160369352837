import React, { useState } from 'react'
import './UserRow.scss'
import Switch from 'react-switch'

const UserRow = ({ name, lastName, active, onChange }) => {

    return (
        <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between'
        }}>
            <p style={{ fontSize: 14, fontWeight: '400', color: '#000000' }}>{name} {lastName}</p>
            <Switch offHandleColor={'#E9EFF5'}
                    onHandleColor={'#119E00'}
                    activeBoxShadow={'none'}
                    offColor={'#aaa'}
                    height={21}
                    width={48}
                    onChange={onChange} checked={active} />

        </div>
    )
}

export default UserRow
