import React from 'react'
import classes from './Buttons.module.scss'
import cn from 'classnames'

export const Buttons = ({column, onClickSave, onClickCancel, onClickDelete}) => {
    return (
        <div className={cn(classes.Buttons, {[classes.Column]: column})}>
            <button onClick={() => onClickSave(column ? 'blocksCustom' : 'blocksSystem')} className={classes.Save}>Сохранить</button>
            <button onClick={onClickCancel} className={classes.Cancel}>Отменить</button>
            {column && (
                <button onClick={onClickDelete} className={classes.Delete}>Удалить</button>
            )}
        </div>
    )
}