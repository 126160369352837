import React, { useState } from 'react'
import './ProjectItem.scss'
import ProjectEdit from '../ProjectEdit/ProjectEdit'
import ProjectDelete from '../ProjectDelete/ProjectDelete'
import { apiProjectChangeActive } from '../../../../../api/projects'
import UserList from '../UserList/UserList'

const ProjectItem = (props) => {
    const [projectOpen, setProjectOpen] = useState(false)
    const [projectEdit, setProjectEdit] = useState(false)
    const [projectDelete, setProjectDelete] = useState(false)
    const accessLevel = localStorage.getItem('accessLevel')

    const changeActive = async (projectId, projectActive) => {
        const data = await apiProjectChangeActive(projectId, !projectActive)

        if (data.status === 'fail') {
            alert(data.errorText)
        } else {
            props.getProjects()
        }
    }

    const clsItem = ['admin-company__project-item']

    if (props.active) {
        clsItem.push('admin-company__project-item_active')
    } else {
        clsItem.push('admin-company__project-item_not-active')
    }

    return (
        <div className={clsItem.join(' ')}>
            <div className='admin-company__project-item-bar'>
                <div className='admin-company__project-item-name' onClick={() => setProjectOpen(!projectOpen)}>
                    {props.name}
                </div>

                {accessLevel === '1' || accessLevel === '5' ? (
                    <div className='admin-company__project-item-edit' onClick={() => setProjectEdit(true)} />
                ) : null}
                <div className='admin-company__project-item-status'>{props.active ? 'Активен' : 'Не активен'}</div>

                {accessLevel === '1' || accessLevel === '5' ? (
                    <div className='admin-company__project-item-switcher' onClick={() => changeActive(props.id, props.active)} />
                ) : null}
            </div>

            {projectEdit ? (
                <ProjectEdit
                    companyId={props.companyId}
                    id={props.id}
                    name={props.name}
                    close={() => setProjectEdit(false)}
                    deleteShow={() => {
                        setProjectDelete(true)
                        setProjectEdit(false)
                    }}
                    getProjects={props.getProjects}
                />
            ) : null}

            {projectDelete ? (
                <ProjectDelete
                    id={props.id}
                    name={props.name}
                    close={() => setProjectDelete(false)}
                    getProjects={props.getProjects}
                />
            ) : null}

            {projectOpen ? <UserList companyId={props.companyId} projectId={props.id} /> : null}
        </div>
    )
}

export default ProjectItem
