import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import config from '../config/config'

export const AnaliseContext = createContext()

function AnaliseProvider({ children }) {
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState({
        filter: {
            company: JSON.parse(localStorage.getItem('analise-filter-company')) || false,
            project: JSON.parse(localStorage.getItem('analise-filter-project')) || false,
            deepLevel: localStorage.getItem('analise-filter-deepLevel') || 'top10',
            searchSystem: localStorage.getItem('analise-filter-searchSystem') || false,
            query: localStorage.getItem('analise-filter-query') || false,
            region: localStorage.getItem('analise-filter-region') || false
        },

        activeTabScreen: localStorage.getItem('analise-activeTabScreen') || 'search', // search - tonality - rating

        companies: [],
        projects: [],
        queries: [],
        regions: [],

        dateFrom: '',
        dateTo: '',

        categories: [],
        contentScreenSearch: [],

        activeCharts: {
            negative: true,
            neutral: true,
            pending: true,
            positive: true
        },

        accessLevel: parseInt(localStorage.getItem('accessLevel'))
    })

    const onGetData = async () => {
        const { data } = await axios.get(`${config.SERVER_BASE_URL}/analysis/`, {
            params: {
                token: localStorage.getItem('token'),
                company: state?.filter?.company?.value || '',
                project: state?.filter?.project?.value || '',
                deepLevel: state.filter.deepLevel || '',
                searchSystem: state.filter.searchSystem || '',
                query: state.filter.query || '',
                region: state.filter.region || ''
            }
        })

        if (data?.status === 'fail') {
            alert(data?.text?.join('\n'))
            return
        }

        if (data?.status === 'success') {
            setState((prev) => ({
                ...prev,
                companies: data?.companies,
                projects: data?.projects,
                queries: data?.queries,
                regions: data?.regions
            }))
        }
    }

    const onGetContentForScreenSearch = async (query, dateFrom, dateTo) => {
        const { data } = await axios.get(`${config.SERVER_BASE_URL}/analysis/search`, {
            params: {
                token: localStorage.getItem('token'),
                query,
                dateFrom: dateFrom || '',
                dateTo: dateTo || ''
            }
        })

        if (data?.status === 'fail') {
            alert(data?.errorText?.split(',')?.join('\n'))
            return
        }

        if (data?.status === 'success') {
            setState((prev) => ({
                ...prev,
                categories: data?.categories,
                contentScreenSearch: data?.content
            }))
        }
    }

    const onChangeFilter = (nameFilter, valueFilter) => {
        let copyFilter = { ...state.filter, [nameFilter]: valueFilter }

        if (nameFilter === 'company' || nameFilter === 'project') {
            localStorage.setItem(`analise-filter-${nameFilter}`, JSON.stringify(valueFilter))
        } else {
            localStorage.setItem(`analise-filter-${nameFilter}`, valueFilter)
        }

        if (nameFilter === 'company') {
            localStorage.removeItem('analise-filter-project')
            localStorage.removeItem('analise-filter-deepLevel')
            localStorage.removeItem('analise-filter-searchSystem')
            localStorage.removeItem('analise-filter-query')
            localStorage.removeItem('analise-filter-region')
            copyFilter = { ...copyFilter, project: false, deepLevel: 'top10', searchSystem: false, query: false, region: false }
        }

        if (nameFilter === 'project') {
            localStorage.removeItem('analise-filter-deepLevel')
            localStorage.removeItem('analise-filter-searchSystem')
            localStorage.removeItem('analise-filter-query')
            localStorage.removeItem('analise-filter-region')
            copyFilter = { ...copyFilter, deepLevel: 'top10', searchSystem: false, query: false, region: false }
        }

        if (nameFilter === 'deepLevel') {
            localStorage.removeItem('analise-filter-searchSystem')
            localStorage.removeItem('analise-filter-region')
            localStorage.removeItem('analise-filter-query')
            copyFilter = { ...copyFilter, query: false, region: false, searchSystem: false }
        }

        if (nameFilter === 'searchSystem') {
            localStorage.removeItem('analise-filter-region')
            localStorage.removeItem('analise-filter-query')
            copyFilter = { ...copyFilter, query: false, region: false }
        }

        if (nameFilter === 'region') {
            localStorage.removeItem('analise-filter-query')
            copyFilter = { ...copyFilter, query: false }
        }

        setState((prev) => ({ ...prev, filter: copyFilter }))
    }

    const onChangeState = (nameField, valueField) => {
        let copyFilter = { ...state, [nameField]: valueField }

        if (nameField === 'dateFrom' && state.filter.query && state.activeTabScreen === 'search') {
            onGetContentForScreenSearch(state.filter.query, valueField, copyFilter.dateTo)
        }

        if (nameField === 'dateTo' && state.filter.query && state.activeTabScreen === 'search') {
            onGetContentForScreenSearch(state.filter.query, copyFilter.dateFrom, valueField)
        }

        if (nameField === 'activeTabScreen') {
            if (valueField !== 'tonality' && state.filter?.searchSystem === 'all') {
                localStorage.removeItem('analise-filter-searchSystem')
                localStorage.removeItem('analise-filter-region')
                localStorage.removeItem('analise-filter-query')
                copyFilter = { ...copyFilter, filter: { ...copyFilter.filter, query: false, region: false, searchSystem: false } }
            }

            localStorage.setItem('analise-activeTabScreen', valueField)
        }

        setState(copyFilter)
    }

    const onChangeTonality = async (rowId, value) => {
        try {
            setIsLoading(true)

            const token = localStorage.getItem('token')

            const { data } = await axios.post(`${config.SERVER_BASE_URL}/analysis/search/tonality/${rowId}`, {
                token: token,
                tonality: value
            })

            setIsLoading(false)

            if (data?.status === 'fail') {
                alert(data?.errorText?.split(',')?.join('\n'))
                return
            }

            if (data?.status === 'success') {
                onGetContentForScreenSearch(state.filter.query, state.dateFrom, state.dateTo)
            }
        } catch (err) {
            console.log('onChangeTonality', err)
            setIsLoading(false)
        }
    }

    const onChangeCanegory = async (rowId, value) => {
        try {
            setIsLoading(true)

            const token = localStorage.getItem('token')

            const { data } = await axios.post(`${config.SERVER_BASE_URL}/analysis/search/category/${rowId}`, {
                token: token,
                category: value
            })

            setIsLoading(false)

            if (data?.status === 'fail') {
                alert(data?.errorText?.split(',')?.join('\n'))
                return
            }

            if (data?.status === 'success') {
                onGetContentForScreenSearch(state.filter.query, state.dateFrom, state.dateTo)
            }
        } catch (err) {
            console.log('onChangeCanegory', err)
            setIsLoading(false)
        }
    }

    const onCancelFilter = () => {
        let copyFilter = { ...state.filter }

        localStorage.removeItem('analise-filter-company')
        localStorage.removeItem('analise-filter-project')
        localStorage.removeItem('analise-filter-deepLevel')
        localStorage.removeItem('analise-filter-searchSystem')
        localStorage.removeItem('analise-filter-query')
        localStorage.removeItem('analise-filter-region')

        copyFilter = { ...copyFilter, company: false, project: false, deepLevel: 'top10', searchSystem: false, query: false, region: false }
        setState((prev) => ({ ...prev, filter: copyFilter }))
    }

    const actions = {
        onGetData,
        onChangeFilter,
        onChangeState,
        onChangeTonality,
        onChangeCanegory,
        onCancelFilter
    }

    const value = {
        state,
        actions
    }

    useEffect(() => {
        onGetData()
    }, [state.filter])

    useEffect(() => {
        if (state.activeTabScreen === 'search') {
            if (state.filter.query) {
                onGetContentForScreenSearch(state.filter.query, state.dateFrom, state.dateTo)
            } else {
                onChangeState('contentScreenSearch', [])
            }
        }
    }, [state.filter.query, state.activeTabScreen])

    return <AnaliseContext.Provider value={value}>{children}</AnaliseContext.Provider>
}

export { AnaliseProvider }
