import { useState } from 'react'

export const useModal = () => {
    // ALL TYPES FORM: 'filter' | 'tabs' | 'company' | 'project' | 'size' | 'system' | 'region' | 'query' | 'queryAdd'
    const [showModal, setShowModal] = useState(null)
    const [modalaDataId, setModalaDataId] = useState(null)

    const onSetModalaDataId = (data) => {
        setModalaDataId(data)
    }

    const onOpenModal = (nameForm) => {
        const body = document.querySelector('body')
        body.classList.add('no-scroll')

        setShowModal(nameForm)
    }

    const onCloseModal = () => {
        const body = document.querySelector('body')
        body.classList.remove('no-scroll')

        setShowModal(null)
        setModalaDataId(null)
    }

    const controlModal = { showModal, modalaDataId, onOpenModal, onCloseModal, onSetModalaDataId }

    return controlModal
}
